import { useLocation, useNavigate } from 'react-router-dom';

function useGoToCart() {
  const location = useLocation();
  const navigate = useNavigate();

  const goToCart = () => {
    const { pathname } = location;
    localStorage.setItem('lastPathCart', pathname);
    navigate('/cart');
  };
  return goToCart;
}

export default useGoToCart;
