import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import SiteFooter from 'components/footer/Footer';
import SiteHeader from 'components/header/Header';
import privacyDoc from './privacy';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  /* h1.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 1.25rem;
    font-weight: 700;
  } */
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
  min-height: 70vh;
`;

const PrivacyDocument = styled.div`
  [data-custom-class='body'],
  [data-custom-class='body'] * {
    font-family: ${props => props.theme.font.primary};
    background: transparent !important;
  }
  [data-custom-class='title'],
  [data-custom-class='title'] * {
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'],
  [data-custom-class='subtitle'] * {
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'],
  [data-custom-class='heading_1'] * {
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'],
  [data-custom-class='heading_2'] * {
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'],
  [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='link'],
  [data-custom-class='link'] * {
    color: #3030f1 !important;
    font-size: 14px !important;

    word-break: break-word !important;
  }
`;
function PrivacyPolicy() {
  return (
    <>
      <HeaderWrapper>
        <SiteHeader />
        <Wrapper>
          <div className="mt-4">
            <PrivacyDocument
              dangerouslySetInnerHTML={{
                __html: privacyDoc,
              }}
            />
          </div>
        </Wrapper>
      </HeaderWrapper>
      <SiteFooter />
    </>
  );
}

export default PrivacyPolicy;
