import { StatusBadge } from 'components/styled/Badges';
import styled from 'styled-components';
const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 1.5rem;
  margin: 1rem;
  &.active {
    border-color: ${(props) => props.theme.colors.black};
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.gray3};
  }
`;
function AddressCard({ address, active, onSelect }: any) {
  return (
    <Wrapper
      onClick={onSelect}
      className={active ? 'active pointer' : 'pointer'}
      area-hidden="true"
    >
      <p>
        {address.address_1}, {address.address_2}, {address.city}
      </p>
      <div className="d-flex align-items-center g-1">
        {address.postcode}
        {address?.is_default === 1 && (
          <StatusBadge color="violet">Primary</StatusBadge>
        )}
      </div>
    </Wrapper>
  );
}

export default AddressCard;
