import { StyledButton } from 'components/forms/Buttons';
import { Brands } from 'helpers/hooks/useBrands';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import { DEFAULT_FILTER } from './filter.type';
import { type, conditions } from './filterOptions';

const BadgeView = styled(Badge)`
  padding: 0.75rem 0.5rem 0.75rem 1.25rem;
  max-height: 44px;
  background-color: ${(props) => props.theme.colors.gray5} !important;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 0.5rem;
  margin-right: 0.625rem;
  .pill-title {
    font-weight: 300;
    text-transform: capitalize;
    margin-right: 0.25rem;
  }
  .clear-icon-wrapper {
    margin: 0 0.75rem;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  .clear {
    color: ${(props) => props.theme.colors.black} !important;
    .clear-text {
      padding-bottom: 0.25rem;
      border-bottom: 1px dashed ${(props) => props.theme.colors.black};
    }
  }
`;

const hasFilterValues = (filters: string[] = []) => {
  return filters.length > 0;
};

const titleMapper: { [key: string]: string } = {
  categoryId: 'Categories',
  brandId: 'Brands',
  sizes: 'Sizes',
  gender: 'Type',
};

type Props = {
  filters: typeof DEFAULT_FILTER;
  onClear: () => void;
  onFilterChange: (filters: typeof DEFAULT_FILTER) => void;
  brands?: Brands;
  categories: { category_id: string | number; category_name: string }[];
  className?: string;
};

type Filter = 'gender' | 'brandId' | 'categoryId' | 'sizes';

function AppliedFilterView({
  filters = DEFAULT_FILTER,
  onClear,
  onFilterChange,
  brands,
  categories,
  className
}: Props) {
  const onRemove = (key: Filter) => {
    const newFilters = {
      ...filters,
      [key]: [],
    };
    onFilterChange(newFilters);
  };

  const getFormattedValue = (values: string[], filterKey: string) => {
    switch (filterKey) {
      case 'brandId': {
        const brandNames = brands
          ?.filter((brand) => values.includes(`${brand.brand_id}`))
          .map((item) => item.brand_name);
        return brandNames?.join(', ');
      }
      case 'categoryId': {
        const category = categories
          .filter((category) => values.includes(`${category.category_id}`))
          .map((item: typeof categories[0]) => item.category_name);
        return category?.join(', ');
      }
      case 'conditions': {
        const conditionNames = conditions
          .filter((condition) => values.includes(`${condition.key}`))
          .map((item: typeof conditions[0]) => item.label);
        return conditionNames.join(', ');
      }
      case 'gender': {
        const genderNames = type
          .filter((item) => values.includes(`${item.key}`))
          .map((item: typeof type[0]) => item.label);
        return genderNames.join(', ');
      }
      default: {
        return values.join(', ');
      }
    }
  };

  const keys = Object.keys(filters) as Filter[];
  const hasValues =
    Object.values(filters).filter((filter) => filter.length).length > 0;
  if (!hasValues) {
    return null;
  }
  return (
    <Wrapper className={`d-flex flex-wrap ${className}`}>
      {keys.map((filterKey: Filter) =>
        hasFilterValues(filters[filterKey] as string[]) ? (
          <BadgeView pill key={filterKey} className="d-flex align-items-center">
            <div className="d-inline-flex fs-14">
              <span className="pill-title">
                {titleMapper[filterKey] || filterKey}:
              </span>
              <span className="fw-500">
                {getFormattedValue(filters[filterKey] as string[], filterKey)}
              </span>
            </div>
            <div
              className="clear-icon-wrapper fw-400 fs-20 d-inline-flex align-items-center justify-content-center"
              onClick={() => onRemove(filterKey)}
            >
              &times;
            </div>
          </BadgeView>
        ) : null
      )}
      <StyledButton
        variant="link"
        onClick={onClear}
        className="clear fs-14 fw-400"
      >
        <span className="clear-text">Clear all filters</span>
      </StyledButton>
    </Wrapper>
  );
}

export default AppliedFilterView;
