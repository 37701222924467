import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  .cart-main {
    margin: 3rem 0px 0px 0rem;
    @media (max-width: 768px) {
      margin: 3rem 0px 0px 0rem;
    }
    .cart-section-wrapper {
      width: 100%;
    }
    .cart-item-price {
      font-size: 1.375rem;
      font-weight: 800;
    }
`;

export const Content = styled(Container)`
  max-width: 1280px;
  min-height: 60vh;
`;

export const CartSummaryWrapper = styled(Container)`
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  min-width: 15.625rem;
  margin: 0rem 0px 0px 1rem;
  border-radius: 0.75rem;
  @media (max-width: 768px) {
    margin: 0;
  }
  .summary-row-devider {
    opacity: 0.1;
    border: 1px solid #000000;
    margin: 1rem 0px 1rem;
  }
`;

export const CartItemWrapper = styled(Container)`
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  margin: 0rem 0px 1.5rem 0rem;
  border-radius: 0.75rem;
  .summary-row-devider {
    opacity: 0.1;
    border: 1px solid #000000;
    margin: 1rem 0px 1rem;
  }
  .product-image {
    width: 137px;
    height: 95px;
    object-fit: contain;
  }
  .capitalize-first:first-letter {
    text-transform: uppercase;
  }
`;
