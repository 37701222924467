import { mediaQuery } from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 3.25rem 0rem 6.25rem 0rem;
  flex-wrap: wrap;
  @media ${mediaQuery.mobile} {
    margin: 0.5rem;
    flex-direction: column;
  }
`;

export const ProductPictureView = styled.div`
  flex: 1;
`;

export const ProductDetailsView = styled.div`
  flex: 1;
  margin-left: 4rem;
  @media (max-width: 767px) {
    margin: 0.5rem;
  }
  .brand-name {
    color: ${(props) => props.theme.colors.gray3};
  }
`;

export const ProductName = styled.h1`
  color: #232f3e;
  margin: 12px 0px;
  line-height: 1.5;
`;

export const SKUName = styled.div`
  color: ${(props) => props.theme.colors.gray3};
  margin-bottom: 40px;
`;

export const BuyButton = styled.div<{ isBlack?: boolean }>`
  background-color: ${(props) =>
    props.isBlack ? props.theme.colors.black : props.theme.colors.gray2};
  border-radius: 12px;
  padding: 1rem 1.25rem 1rem 1.25rem;
  width: 50%;
  margin-right: 24px;
  cursor: pointer;
  height: 101px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.025);
  }
  @media (max-width: 767px) {
    min-width: 250px;
    margin: 0px auto;
    margin-bottom: 1rem;
  }
`;

export const PriceText = styled.div<{
  color?: string;
  size?: string;
  lineHeight?: string;
}>`
  font-size: ${(props) => props.size || '1.75rem'};
  color: ${(props) => props.color || props.theme.colors.body};
  line-height: ${(props) => props.lineHeight || '2.5rem'};
  margin-top: 0.25rem;
`;

export const BuyButtonText = styled.div<{ color?: string }>`
  color: ${(props) => props.color || props.theme.colors.body};
`;

export const Divider = styled.div`
  border: 1px solid #dddddd;
`;

export const AccordionView = styled.div`
  .accordion-item {
    background-color: transparent;
    border: none;
  }
`;

export const MoreProductTitle = styled.div`
  font-size: 2rem;
`;

export const MoreProductWrapper = styled.div`
  margin: 0rem 8.438rem;
`;

export const Container = styled.div`

.buy-button {
  margin-right: 1.5rem;
  text-align: left;
}
.divider {
  border: 1px solid #dddddd; 
}
.home-banner{
    min-height: 550px;
    background: #F5F5F5;
    border-radius: 20px;
    display: flex;
    align-items: center;

    .banner-slide{
        position: relative;
    }

    .display-center{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .text-sub-title{
        color: #232F3E;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        opacity: 0.6;
        margin-bottom: 20px;
    }

    .text-title{
        font-weight: bold;
        font-size: 52px;
        line-height: 62px;
        color: #000000;
        margin-bottom: 40px;
    }

    .banner-image{
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        position: relative;
    }

    .overlay{
        background: linear-gradient(153.66deg, rgba(255, 255, 255, 0.078) 27.36%, rgba(255, 255, 255, 0.78) 88.54%);
        width: 500px;
        height: 500px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    @media (max-width: 767px) {
        .home-banner {
            .text-title{
                font-size: 25px;
                line-height: 32px;
                margin-bottom: 25px;
            }

            .overlay{
                width: 300px;
                height: 300px;
            }
        }
    }
`;

export const BreadCrumbWrapper = styled.div`
  margin: 1.875rem 8.438rem 1.875rem 8.438rem;
  @media (max-width: 767px) {
    margin: 0.5rem;
  }
`;

export const BuyButtonPanel = styled.div`
  @media ${mediaQuery.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`;
