import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1rem;
`;

export const ProductImageView = styled.div`
  height: 78px;
  width: 78px;
`;

export const ProductImage = styled.img`
  width: 4.65rem;
  height: 3.546rem;
  object-fit: contain;
`;

export const ProductDescription = styled.div`
  color: #8e8e8e;
  margin-left: 1rem;
`;

export const ProductInfo = styled.div`
  margin: 1rem 0;
  div {
    &:not(:last-of-type) {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      /* margin: 0 10px; */
    }
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    div {
      /* margin-right: 0.5rem; */
    }
  }
`;

export const ProductInfoText = styled.div`
  color: #222222;
`;

export const Separator = styled.div`
  border-left: 1px solid #000;
  opacity: 0.3;
`;

export const ViewButton = styled.div`
  background: #e7e7e7;
  border-radius: 0.5rem;
  padding: 0.875rem 1.75rem;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-bottom: 0.5rem;
    width: 75%;
    text-align: center;
  }
`;

export const AddToCartButton = styled.div<{ width?: string }>`
  background: #000000;
  border-radius: 0.5rem;
  padding: 0.875rem;
  margin-left: 0.75rem;
  padding: 0.875rem 1.75rem;
  color: ${(props) => props.theme.colors.body};
  cursor: pointer;
  .added-to-cart {
    background: ${(props) => props.theme.statusColors.green.color};
    color: ${(props) => props.theme.colors.body};
  }

  @media (max-width: 767px) {
    margin-bottom: 0.5rem;
    margin-left: 0rem;
    width: ${(props) => props.width || '75%'};
    text-align: center;
  }
`;

export const AddedToCartButton = styled.div<{ width?: string }>`
  background: ${(props) => props.theme.statusColors.green.color};
  color: ${(props) => props.theme.colors.body};
  border-radius: 0.5rem;
  padding: 0.875rem;
  margin-left: 0.75rem;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-bottom: 0.5rem;
    margin-left: 0rem;
    width: ${(props) => props.width || '75%'};
    text-align: center;
  }
`;

export const PriceText = styled.div`
  color: #232f3e;
  font-size: 1.75rem;
  font-weight: 900;
`;

export const PricePanel = styled.div`
  @media (max-width: 767px) {
    justify-content: center !important;
  }
`;

export const PriceButtonPanel = styled.div`
  @media (max-width: 767px) {
    justify-content: center !important;
  }
`;
