import { ReactComponent as Info } from 'assets/icons/info-circle.svg';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';

const StyledPopover = styled(Popover)`
  border: none;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  .popover-arrow {
    display: none;
  }
  .popover-body {
    padding: 1.125rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;

function InfoPopover({
  id,
  body,
  children,
  wrapperClassName,
}: {
  id: string;
  body: string;
  children: React.ReactNode;
  wrapperClassName?: string;
}) {
  const popover = (
    <StyledPopover id={id}>
      <Popover.Body>{body}</Popover.Body>
    </StyledPopover>
  );
  return (
    <div className={wrapperClassName || ''}>
      <OverlayTrigger trigger={['focus']} placement="top" overlay={popover}>
        <Button variant="transparent" className="m-0 p-0 d-flex g-1">
          {children} <Info stroke="#283EFF" />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default InfoPopover;
