import React from 'react';

import Cart from 'pages/cart';
import Search from 'pages/search';
import BuyerCheckout from 'pages/buyer-checkout';
import OrderPlaced from 'pages/order-placed';
import OrderDetails from 'pages/order-details';
import ProductDetails from 'pages/product-details/ProductDetailsView';

const MyAccount = React.lazy(() => import('pages/buyer/my-account'));

const BuyerLogin = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/buyer/buyer-login')
);
const BuyerRegister = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/buyer/buyer-login/Register')
);

export const buyerRoutes = {
  public: [
    {
      path: 'login',
      component: <BuyerLogin />,
      title: 'Breackout LA | Buyer Login',
      metaDescription:
        'BreakoutLA Buyer Login | Login with breakout and buy new or old products',
    },
    {
      path: 'register',
      component: <BuyerRegister />,
      title: 'Breackout LA | Buyer Register',
      metaDescription:
        'BreakoutLA Buyer Registration | Shop Brand New or Used Sneakers and Urban Streetwear',
    },
    {
      path: '/search',
      component: <Search />,
      title: 'Breackout LA | Search Products',
      metaDescription:
        'BreakoutLA | Search By Advanced Search Filters And Shop Sneakers and Urban Streetwear.',
    },
    {
      path: '/product/:id',
      component: <ProductDetails />,
      title: 'Breackout LA | Product Details',
    },
    {
      path: '/cart',
      component: <Cart />,
      title: 'Cart',
    },
  ],
  private: [
    {
      path: '/my-account',
      component: <MyAccount />,
      title: 'My Account',
    },
    {
      path: '/checkout',
      component: <BuyerCheckout />,
      title: 'Checkout',
    },
    {
      path: '/order-placed',
      component: <OrderPlaced />,
      title: 'Order Placed',
    },
    {
      path: '/order-details/:id',
      component: <OrderDetails />,
      title: 'Order Details',
    },
  ],
};
