export function getProductConditionLabel(condition: string) {
  switch (condition?.toLowerCase()) {
    case 'good':
      return 'Good';
    case 'missing':
      return 'Missing Lid';
    case 'damaged':
      return 'Damaged';
    case 'originalbox':
      return 'No Original Box';
    case 'pre':
      return 'Pre-owned';
    default:
      return condition;
  }
}
