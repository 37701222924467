import React from 'react';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { Wrapper } from './orderPlaced.styled';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useCart } from 'helpers/contexts/cart-context';
import { useBuyerAuth } from 'helpers/contexts/buyer-auth-context';
import { checkPaymentStatusApi } from 'helpers/http/cartApi';
import Loader from 'components/Loader';

const StyledButtonWrapper = styled(StyledButton)`
  margin: 0rem 0.375rem 1rem 0.375rem;
`;

function OrderPlaced() {
  const { user, signout } = useBuyerAuth();
  const { removeAll } = useCart();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [orderDetails, setOrderDetails] = React.useState<any>(null);

  const checkPaymentStatus = async (paymentId: any) => {
    try {
      setLoading(true);
      const result = await checkPaymentStatusApi({
        payment_id: paymentId,
      });
      const { status, data } = result;
      if (status && data) {
        setOrderDetails(data);
      }
    } catch (err) {
      console.log('Payment error:> ', err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentId = params.get('payment_intent');
    if (paymentId) {
      checkPaymentStatus(paymentId);
    } else {
      setLoading(false);
    }
  }, [location.search]);

  React.useEffect(() => {
    removeAll();
  }, []);

  React.useEffect(() => {
    if (user.isGuest) {
      localStorage.removeItem('buyer-user');
      localStorage.removeItem('buyer-token');
    }
  }, [signout, user.isGuest]);

  if (loading) {
    return <Loader />;
  }

  const isOrderSuccess = orderDetails?.order_status === 'success';
  const orderStatusMessage = isOrderSuccess ? 'Order placed!' : 'Order failed!';
  const orderContent = isOrderSuccess
    ? orderDetails?.is_guest_user
      ? 'Your order placed successfully. We’ve sent you an email with all the details.'
      : 'Your order placed successfully. For more details check My orders under ‘Account’'
    : orderDetails?.order_failure_reason ||
      'Something went wrong, Please try again later!';

  const orderIdContent =
    isOrderSuccess && orderDetails?.is_guest_user ? (
      <h5>Order No: {orderDetails?.order_number}</h5>
    ) : null;

  const footerButtons = orderDetails?.is_guest_user ? (
    <StyledButtonWrapper
      variant="secondary"
      onClick={() => {
        setTimeout(() => {
          window.location.replace('/home');
        }, 1000);
      }}
    >
      Continue buying
    </StyledButtonWrapper>
  ) : (
    <>
      <Link to="/my-account">
        <StyledButtonWrapper variant="dark" type="submit">
          Go to My Account
        </StyledButtonWrapper>
      </Link>
      <Link to="/home">
        <StyledButtonWrapper variant="secondary" type="submit">
          Continue buying
        </StyledButtonWrapper>
      </Link>
    </>
  );

  return (
    <>
      <Wrapper>
        <SiteHeader />
        <div className="d-flex justify-content-center align-items-center main-container">
          {isOrderSuccess ? <Success /> : <Failed />}
          <h4 className="title mt-1.5 mb-0 fw-700 fs-32">
            {orderStatusMessage}
          </h4>
          <p className="content">{orderContent}</p>
          {orderIdContent}
          <div className="d-flex flex-wrap justify-content-center buttons">
            {footerButtons}
          </div>
        </div>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default OrderPlaced;
