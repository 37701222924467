import moment from 'moment';
import { Container, Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getTracking } from 'helpers/http/common';
import TrackCard from './TrackCard';

export const Wrapper = styled(Form)`
  padding: 3.75rem;
  min-height: 70vh;
  .content {
    max-width: 37.5rem;
    margin: 0px auto;
  }
  .tracking-details {
    padding: 28px 36px;
    background: rgba(231, 231, 231, 0.5);
    border-radius: 12px;
    margin-bottom: 2.5rem;
    margin-top: 2.25rem;
    .row {
      gap: 1rem 0;
    }
  }
`;

function TrackingOrder() {
  const { trackingid: trackingId, orderid: orderId } = useParams<{
    trackingid: string;
    orderid: string;
  }>();
  const { data } = useQuery(
    ['track-order', trackingId, orderId],
    () => getTracking({ orderId, trackingId }),
    {
      enabled: !!trackingId && !!orderId,
    }
  );
  return (
    <>
      <SiteHeader />
      <Wrapper>
        <div className="content">
          <h1 className="text-center mb-3">Track and trace you items</h1>
          <h2 className="fs-1rem fw-400 text-center">
            Check progress of you deliveries
          </h2>
          <Container>
            <div className="tracking-details">
              <Row>
                <Col md={12}>
                  <strong>Tracking number </strong>
                  <div>{data?.trackingNumber}</div>
                </Col>
                <Col md={12}>
                  <strong>Carrier status description</strong>
                  <div>{data?.carrierStatusDescription}</div>
                </Col>
                <Col md={6}>
                  <strong>Shipped date</strong>
                  <div>
                    {data?.shipDate
                      ? moment(data?.shipDate).format('LLLL')
                      : '-'}
                  </div>
                </Col>
                <Col md={6}>
                  <strong>Estimated delivery date</strong>
                  <div>
                    {data?.estimatedDeliveryDate
                      ? moment(data?.estimatedDeliveryDate).format('LLLL')
                      : '-'}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="my-2">
              {data?.events.map((ev: any, idx: any) => (
                <TrackCard key={idx} data={ev} />
              ))}
            </div>
          </Container>
        </div>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default TrackingOrder;
