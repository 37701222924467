import styled from "styled-components";

export const Wrapper = styled.div`
    .accordionHeader {
        margin-right: 1.5rem;
        margin-bottom: 0.75rem;
    }
    .headerText {
        color: ${(props) => props.theme.colors.black};
    }
    .detailsView {
        color: ${(props) => props.theme.colors.gray3};
        font-style: normal;
        font-weight: 400;
    }
`;
