export const type = [
  { label: `Men's sizes`, key: 'men' },
  { label: `Women's size`, key: 'women' },
  { label: 'Youth sizes', key: 'kid' },
];

export const conditions = [
  { label: 'Brand new', key: 'new' },
  { label: 'Pre-owned', key: 'pre' },
];
