import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BannerWrapper, BulletPoints, LoginWrapper } from './login.styled';
import Check from 'assets/icons/checkCircle.svg';
import ForgetForm from './ForgetPassForm';
import useResponsive from 'helpers/hooks/useResponsive';

function ForgetPassword() {
  const { isMobile } = useResponsive();
  return (
    <LoginWrapper>
      <Row className="g-0">
        <Col md={{ span: 6, order: 1 }}>
          <BannerWrapper>
            <div className="content">
              <div className="logo">
                <img src="/logo-white.png" alt="Breakout LA" />
              </div>
              {!isMobile && (
                <>
                  <h4 className="mt-3 lh-base fw-700">
                    Sneakers & Urban Streetwear Consignment Shop
                  </h4>
                  <BulletPoints>
                    <ul>
                      {['Buy', 'Sell'].map((item) => (
                        <li key={item}>
                          <img src={Check} alt={item} />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                    <ul>
                      {['Trade', 'Consign'].map((item) => (
                        <li key={item}>
                          <img src={Check} alt={item} />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </BulletPoints>
                </>
              )}
            </div>
          </BannerWrapper>
        </Col>
        <Col md={{ span: 6, order: 2 }}>
          <ForgetForm />
        </Col>
      </Row>
    </LoginWrapper>
  );
}

export default ForgetPassword;
