import React, { useState, useEffect } from 'react';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { showErr } from 'helpers/utils/misc';
import { useNavigate } from 'react-router-dom';

export const WalletPayButton = ({ total, clientSecret, onClick }: any) => {
  const stripe = useStripe();

  const navigate = useNavigate();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const roundedTotal = Math.round(total * 100) / 100;
  const payAmount = Math.round(roundedTotal * 100);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Breakout Order',
          amount: payAmount,
        },
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
      pr.on('paymentmethod', async (ev) => {
        console.log('Received Stripe PaymentMethod: ', ev.paymentMethod);
        const { paymentIntent, error: confirmError }: PaymentIntentResult =
          await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
        const redirectSuccessUrl = `/order-placed?payment_intent=${paymentIntent?.id}`;
        const redirectFailedUrl = `/order-placed?failed=true`;
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          const errorMsg = confirmError.message || 'Payment failed';
          showErr(errorMsg);
          ev.complete('fail');
          navigate(redirectFailedUrl);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          console.log('Payment Success>>');
          navigate(redirectSuccessUrl);
          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent?.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              const errorMsg = error.message || 'Payment failed';
              showErr(errorMsg);
              navigate(redirectFailedUrl);
            } else {
              // The payment has succeeded -- show a success message to your customer.
              navigate(redirectSuccessUrl);
            }
          } else {
            // The payment has succeeded -- show a success message to your customer.
            navigate(redirectSuccessUrl);
          }
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              theme: 'dark',
              height: '40px',
              buttonSpacing: '10px',
            },
          },
        }}
        onClick={onClick}
        className="wallet-pay-button"
      />
    );
  }

  // Use a traditional checkout form.
  return null;
};
