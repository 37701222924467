import StripePay from './StripePay';

type Props = {
  clientSecret: string;
  total: any;
};

function PaymentDetails({ clientSecret, total }: Props) {
  return (
    <>
      <StripePay total={total} clientSecret={clientSecret} />
    </>
  );
}
export default PaymentDetails;
