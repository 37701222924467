import React from 'react';
import { useCart } from 'helpers/contexts/cart-context';
import {
  ProductImageView,
  ProductImage,
  ProductDescription,
  ProductInfo,
  AddToCartButton,
  ViewButton,
  PriceText,
  ProductInfoText,
  Separator,
  Container,
  PriceButtonPanel,
  PricePanel,
  AddedToCartButton,
} from './productListItemStyled';
import { ReactComponent as Tick } from 'assets/icons/tick.svg';
import { ProductSize } from './styled';
import { UserProductType } from 'pages/default-products/product.type';
import cns from 'classnames';
import useResponsive from 'helpers/hooks/useResponsive';
import { getProductConditionLabel } from 'helpers/utils/product-condition';
interface ProductListItemProps {
  onProductSelect: (product: UserProductType) => void;
  onViewDetails: (product: UserProductType) => void;
  product?: any;
  cart?: any;
}

function ProductListItem({
  onProductSelect,
  onViewDetails,
  product,
  cart,
}: ProductListItemProps) {
  const { remove } = useCart();
  const { isMobile } = useResponsive();
  const onView = React.useCallback(() => {
    onViewDetails(product);
  }, [onViewDetails, product]);

  const onAdd = (productItem: UserProductType) => () => {
    onProductSelect(productItem);
  };
  const isActive = (id: string) => {
    return cart?.find((item: UserProductType) => item.user_product_id === id)
      ? 'active'
      : '';
  };
  return (
    <Container>
      <div className="d-flex">
        <ProductImageView>
          <ProductImage src={product?.image} />
        </ProductImageView>
        <div className="mx-4 d-flex flex-column justify-content-center">
          {product?.description ? (
            <ProductDescription className="fs-1rem">
              {product?.description}
            </ProductDescription>
          ) : null}
          <ProductSize>
            Size: {product?.size_value} ({product?.size_unit})
          </ProductSize>
        </div>
      </div>
      {Object.keys(product?.product_option_config).length ? (
        <ProductInfo className="d-flex">
          <ProductInfoText className="fs-1rem fw-500 text-capitalize">
            <span className="fw-400 fs-14">Product Conditions: </span>
            {getProductConditionLabel(
              product?.product_option_config?.product_conditions
            )}
          </ProductInfoText>
          {product?.product_option_config?.box_condition && (
            <ProductInfoText className="fs-1rem fw-500  text-capitalize">
              <span className="fw-400 fs-14">Box Condition: </span>
              {getProductConditionLabel(
                product?.product_option_config?.box_condition
              )}
            </ProductInfoText>
          )}
          {product?.color && (
            <ProductInfoText className="fs-1rem fw-500  text-capitalize">
              <span className="fw-400 fs-14">Product Color:</span>
              {product?.color}
            </ProductInfoText>
          )}
        </ProductInfo>
      ) : null}
      <PricePanel
        className={cns({
          'd-flex justify-content-between align-items-center flex-wrap':
            !isMobile,
        })}
      >
        <PriceText>${product?.sell_price}</PriceText>
        <PriceButtonPanel className="d-flex justify-content-between flex-wrap mt-2">
          <ViewButton
            className={cns('fs-1rem fw-500', { 'w-100': isMobile })}
            onClick={onView}
          >
            View details
          </ViewButton>
          {!isActive(product?.user_product_id) ? (
            <AddToCartButton
              className={cns('fs-1rem fw-500', { 'w-100': isMobile })}
              onClick={onAdd(product)}
            >
              Add to cart
            </AddToCartButton>
          ) : (
            <AddedToCartButton
              className={cns('fs-1rem fw-500', { 'w-100': isMobile })}
              onClick={() => remove(product?.user_product_id)}
            >
              <Tick />
              &nbsp;Added to cart
            </AddedToCartButton>
          )}
        </PriceButtonPanel>
      </PricePanel>
    </Container>
  );
}

export default ProductListItem;
