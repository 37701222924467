import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MyGlobalStyle } from './styles/GlobalStyle';
import { myTheme } from './styles/theme';
import { AuthProvider } from 'helpers/contexts/auth-context';
import { CssUtils } from 'styles/CssUtils';
import AppRoutes from 'app-router/AppRoutes';
import { CartProvider } from 'helpers/contexts/cart-context';
import { BuyerAuthProvider } from 'helpers/contexts/buyer-auth-context';
import { DraftProvider } from 'helpers/contexts/draft-context';
import ScrollToTop from 'helpers/utils/ScrollToTop';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={myTheme}>
        <div className="App">
          <BuyerAuthProvider>
            <CartProvider>
              <DraftProvider>
                <ScrollToTop />
                <AppRoutes />
              </DraftProvider>
            </CartProvider>
          </BuyerAuthProvider>
        </div>
        <MyGlobalStyle />
        <CssUtils />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
