import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

export const Wrapper = styled.div`
  padding: 1rem;
  p.para {
    font-size: ${22 / 16}rem;
    line-height: 1.5;
  }
  .para-container {
    max-width: 740px;
    margin: 4rem auto;
  }
`;
export const BannerContainer = styled.div`
  background: rgba(22, 22, 22, 0.79) url('/images/seller-banner.jpg') no-repeat
    center center;
  background-size: cover;
  height: 475px;
  width: 100%;
  border-radius: 28px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    font-size: 3.25rem;
    font-weight: 700;
  }
`;

export const Content = styled(Container)`
  max-width: 970px;
`;

export const CallToActionContainer = styled.div`
  margin-bottom: 4rem;
  background-color: #e7e7e7;
  height: 224px;
  border-radius: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: ${28 / 16}rem;
    margin-bottom: 1.7rem;
  }
`;
export const SellerTosContainer = styled.div`
  text-align: center;
  max-width: 770px;
  margin: 4rem auto;
  p {
    line-height: 1.5;
  }
`;

export const ActionSection = styled.div`
  border: 1px solid #dddddd;
  border-radius: 16px;
  display: flex;
  max-width: 770px;
  height: 128px;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  position: relative;
  .action-link {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  a {
    color: #283eff;
    font-weight: 500;
    font-size: 1.25rem;
  }
  .separator {
    border: 1px solid #dddddd;
    height: 2rem;
  }
`;

export const SellerProcessWrapper = styled.div`
  display: flex;
  margin: 4rem auto;
  max-width: 590px;
  text-align: left;
  .select-bullet-main {
    display: flex;
    flex-direction: column;
    .select-line {
      height: ${75 / 16}rem;
      border: 1px dashed #2B2B2B;
    }
  }
  .process-step-text {
    max-width: 496px;
    word-wrap: break-word;
    height: ${110 / 16}rem;
    margin-left: 37px;
    font-size: 1.05rem;
    @media (max-width: 680px) {
      font-size: 0.8rem;
    }
  }
  a {
    color: #283eff;
    font-size: 1rem;
  }
`;


export const TermsView = styled.div<{full?: boolean}>`
  max-height: ${(props) => props.full ? 'none' : '300px'};
  overflow: hidden;  
  position: relative;
  .action-container {
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #F5F5F5 43.38%);
    left: 0;
    right: 0;
    padding-bottom: 8px;
    padding-top: 36px;
  }
  p, ul {
    text-align: left;
    .underlined-list-item {
      text-decoration: underline;
    }
  }

`;

export const UpArrow = styled(Arrow)<{full?: boolean}>`
  transform: rotate(${(props) => props.full ? '180deg' : '0deg'});
`;