import { STATUS } from 'helpers/const/async-status';
import { apiClient } from 'helpers/http';
import { showErr } from 'helpers/utils/misc';
import React from 'react';
import { MyListingType } from './listing.types';
import { useAuth } from 'helpers/contexts/auth-context';
import { useQuery } from 'react-query';

export const getMyListing = ({
  page = 1,
  limit = 10,
  search = '',
  status = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
} = {}) => {
  const url = `/seller/my/${
    status === 'requested' ? 'listings_req' : 'listings'
  }`;
  return apiClient
    .post(url, {
      page,
      limit,
      keyword: search,
      status,
    })
    .then((res) => res.data);
};

export const approveListing = (id: string) =>
  apiClient
    .post(`/seller/product/save`, {
      user_product_id: id,
      action: 'submit',
    })
    .then((res) => res.data);

const INITIAL_STATE = {
  page: 1,
  total: 0,
  data: null,
  status: STATUS.IDLE,
};

export const getDefaultListing = ({
  page = 1,
  limit = 10,
  search = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
} = {}) =>
  apiClient
    .post(`/products/default/listings`, {
      page,
      limit,
      keyword: search,
    })
    .then((res) => res.data);

const updateOption = (options: any, option: any) => {
  const data = options.map((opt: any) =>
    opt.status === option.status ? { ...opt, count: Number(option.count) } : opt
  );
  return data;
};

function useMyListing(initialStatus?: string) {
  const { user } = useAuth();
  const [listingState, setListingState] = React.useState<{
    page: number;
    total: number;
    data: MyListingType[] | null;
    status: string;
    total_by_status?: {
      count: string;
      status: string;
    }[];
  }>(INITIAL_STATE);

  const [selectedActiveListing, setSelectedActiveListing] = React.useState<
    MyListingType[]
  >([]);

  const [editListingModal, setEditListingModal] = React.useState<{
    isOpen: boolean;
    data: any;
    isEditMode?: boolean;
    isRequested?: boolean;
  }>({
    isOpen: false,
    data: null,
    isEditMode: false,
    isRequested: false,
  });
  const [tabOptions, setTabOptions] = React.useState(() => {
    return [
      'all',
      'active',
      'pending',
      'requested',
      'sold',
      'removed',
      'draft',
    ].map((item) => ({
      status: item,
      count: 0,
    }));
  });

  const { data, isLoading, refetch } = useQuery<any>(['my-request'], () =>
    getMyListing({ status: 'requested' }),
    {
      enabled: !!user,
    }
  );

  const openEditListingModal = React.useCallback(
    (data: any, isRequested: boolean, isEditMode?: boolean) => {
      setEditListingModal((prev) => ({
        ...prev,
        isOpen: true,
        data,
        isEditMode,
        isRequested,
      }));
    },
    []
  );

  const closeEditListingModal = React.useCallback(() => {
    setEditListingModal({ data: null, isOpen: false });
  }, []);

  const fetchListing = React.useCallback(
    (page = 1, search?: string, status?: string) => {
      if (user) {
        setListingState((prev) => ({ ...prev, status: STATUS.FETCHING }));
        getMyListing({ page, search, status: status || initialStatus })
          .then((res) => {
            if (!res.status) {
              showErr(res.message);
            }
            setListingState((prev) => ({
              ...prev,
              data: res.data,
              total: res.total,
              page,
              total_by_status: res.total_by_status,
              status: STATUS.SUCCESS,
            }));
            // TODO: Refactor this later
            if (!search && !status) {
              setTabOptions((prev) => {
                let result: any = [];
                result = updateOption(prev, {
                  status: 'all',
                  count: res.total,
                });
                res.total_by_status?.forEach((item: any) => {
                  result = updateOption(result, {
                    status: item.status,
                    count: Number(item.count),
                  });
                });
                return result;
              });
            } else {
              if (res.total_by_status?.length > 0) {
                if(status === 'requested') {
                  setTabOptions((prev) => {
                    let updates: any = [];
                    res.total_by_status.forEach((item: any) => {
                      updates = updateOption(prev, {
                        status: "requested",
                        count: Number(item.count),
                      });
                    });
                    return updates;
                  });
                } else {
                  setTabOptions((prev) => {
                    let updates: any = [];
                    res.total_by_status.forEach((item: any) => {
                      updates = updateOption(prev, item);
                    });
                    return updates;
                  });
                }
              }
            }
          })
          .catch((err) => {
            setListingState((prev) => ({ ...prev, status: STATUS.ERROR }));
            showErr(err?.response?.data.message || 'Error! Try again');
          });
      }
    },
    [initialStatus, user]
  );

  React.useEffect(() => {
    setListingState((prev) => ({ ...prev, status: STATUS.PENDING }));
    fetchListing();
  }, [fetchListing]);

  React.useEffect(() => {
    if (data) {
      setTabOptions((prev) => {
        let result: any = [];
        result = updateOption(prev, {
          status: 'requested',
          count: data.total,
        });
        return result;
      });
    }
  }, [data]);

  return {
    listingState,
    fetchListing,
    editListingModal,
    openEditListingModal,
    closeEditListingModal,
    tabOptions,
    selectedActiveListing,
    setSelectedActiveListing,
  };
}
export default useMyListing;
