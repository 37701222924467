import React, { useState } from 'react';
import {
  Container,
  RightSideView,
  CloseIcon,
  CloseView,
  ProductInfo,
  AddToCartView,
  ProductListContainer,
  ProductImageView as ProductImageContainer,
  ProductImage,
  ProductSize,
  ProductDetailView,
  ProductName,
  ProductListingTitle,
  PriceTitle,
  IssueText,
  InfoTitle,
  InfoValue,
  ProductInformationView,
  ProductList,
  ProductInfoWrapper,
  BackView,
  BackButtonText,
  CartSummary,
} from './styled';
import ProductImageView from '../product-image/ProductImageView';
import ProductListItem from './ProductListItem';
import {
  PriceText,
  AddToCartButton,
  AddedToCartButton,
} from './productListItemStyled';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as Tick } from 'assets/icons/tick.svg';
import { useCart } from 'helpers/contexts/cart-context';
import { StyledButton } from 'components/forms/Buttons';
import { Link } from 'react-router-dom';
import { UserProductType } from 'pages/default-products/product.type';
import { getImageUrl } from 'helpers/utils/misc';
import cns from 'classnames';
import useResponsive from 'helpers/hooks/useResponsive';
import { getProductConditionLabel } from 'helpers/utils/product-condition';
import useGoToCart from 'helpers/hooks/useGoToCart';
interface Props {
  open?: boolean;
  onClose: () => void;
  productCatalog: any;
  selectedProduct: any;
  selectedCondition?: string;
}

function SidebarView({
  open,
  onClose,
  productCatalog = {},
  selectedProduct,
  selectedCondition,
}: Props) {
  const { add, remove, cart } = useCart();
  const goToCart = useGoToCart();
  const { isMobile } = useResponsive();
  const [viewDetails, setViewDetails] = useState<UserProductType>();
  const onProductSelect = (productItem?: UserProductType) => {
    const { brand_name, catalog_images, catalog_name } = productCatalog;
    add({ ...productItem, brand_name, catalog_images, catalog_name });
  };

  const isActive = (id?: string) => {
    return cart?.find((item: UserProductType) => item.user_product_id === id)
      ? 'active'
      : '';
  };

  const onViewDetails = React.useCallback((item) => {
    setViewDetails(item);
  }, []);

  const handleClose = React.useCallback(() => {
    onClose();
    setViewDetails(undefined);
  }, [onClose]);

  const onBack = React.useCallback(() => {
    setViewDetails(undefined);
  }, []);

  const catalogImages = getImageUrl(
    productCatalog?.catalog_images || '[]',
    'multi'
  ) as string[];

  const filteredProducts = selectedProduct.size
    ? productCatalog.user_products
        ?.filter(
          (product: typeof productCatalog.user_products[0]) =>
            product.size_value === selectedProduct.size
        )
        ?.sort((a: any, b: any) => Number(a.sell_price) - Number(b.sell_price))
    : productCatalog.user_products?.sort(
        (a: any, b: any) => Number(a.sell_price) - Number(b.sell_price)
      );
  // const filteredProducts = productCatalog.user_products?.sort(
  //   (a: any, b: any) => Number(a.sell_price) - Number(b.sell_price)
  // );

  const renderProductListing = () => {
    return (
      <div>
        <ProductDetailView className="d-flex align-items-center">
          <ProductImageContainer>
            <ProductImage src={catalogImages[0]} />
          </ProductImageContainer>
          <div>
            <div className="brand-name fs-1rem fw-400">
              {productCatalog?.brand_name}
            </div>
            <ProductName className="fs-20 fw-700">
              {productCatalog.catalog_name}
            </ProductName>
            {selectedProduct.size && (
              <ProductSize>Size: {selectedProduct.size}</ProductSize>
            )}
          </div>
        </ProductDetailView>
        <div className="divider" />
        <ProductListContainer>
          <ProductListingTitle className="fs-20 fw-700 mb-20">
            Listings
          </ProductListingTitle>
          <ProductList className="overflow-auto">
            {filteredProducts.map(
              (product: any, i: number) =>
                product?.product_option_config?.product_conditions?.toLowerCase() ===
                  selectedCondition && (
                  <ProductListItem
                    key={i}
                    product={{ ...product, image: catalogImages[0] }}
                    onProductSelect={onProductSelect}
                    onViewDetails={onViewDetails}
                    cart={cart}
                  />
                )
            )}
          </ProductList>
        </ProductListContainer>
      </div>
    );
  };

  const renderProductInfo = () => {
    const ProductInformation = ({
      title,
      value,
    }: {
      title: string;
      value: any;
    }) => (
      <ProductInfoWrapper>
        <InfoTitle className="fs-1rem">{title}</InfoTitle>
        <InfoValue className="text-center text-capitalize">{value}</InfoValue>
      </ProductInfoWrapper>
    );

    return (
      <ProductInfo>
        <ProductImageView
          imageList={[...catalogImages]}
          productImageWidth={'310px'}
          productImageHeight={'192px'}
          hasArrowNavigation={true}
          altName={productCatalog?.catalog_name}
        />
        <div className="brand-name fs-1rem">{productCatalog?.brand_name}</div>
        <ProductName className="fs-24 fw-700">
          {productCatalog?.catalog_name}
        </ProductName>
        <ProductInformationView className="d-flex justify-content-between flex-wrap">
          <ProductInformation title="Size" value={viewDetails?.size_value} />
          <ProductInformation
            title="Product Condition"
            value={
              getProductConditionLabel(
                viewDetails?.product_option_config?.product_conditions as string
              ) || 'Not available'
            }
          />
          {viewDetails?.product_option_config?.box_condition && (
            <ProductInformation
              title="Box Condition"
              value={
                getProductConditionLabel(
                  viewDetails?.product_option_config?.box_condition
                ) || 'Not available'
              }
            />
          )}
        </ProductInformationView>
        {selectedCondition !== 'new' &&
          viewDetails?.product_option_config?.product_other_issue && (
            <>
              <div>Any other issue?</div>
              <IssueText>
                {viewDetails?.product_option_config?.product_other_issue}
              </IssueText>
            </>
          )}
        <AddToCartView
          className={cns({
            'd-flex align-items-center justify-content-between': !isMobile,
          })}
        >
          <div>
            <PriceTitle>Price</PriceTitle>
            <PriceText className="fw-600">${viewDetails?.sell_price}</PriceText>
          </div>
          {!isActive(viewDetails?.user_product_id) ? (
            <AddToCartButton
              className={cns('fs-1rem fw-500', {
                'w-100 mt-2': isMobile,
              })}
              onClick={() => onProductSelect(viewDetails)}
              width="50%"
            >
              Add to cart
            </AddToCartButton>
          ) : (
            <AddedToCartButton
              className={cns('fs-1rem fw-500', {
                'w-100 mt-2': isMobile,
              })}
              onClick={() => remove(viewDetails?.user_product_id)}
            >
              <Tick />
              &nbsp;Added to cart
            </AddedToCartButton>
          )}
        </AddToCartView>
      </ProductInfo>
    );
  };

  if (!open) {
    return null;
  }
  const closeView = (
    <CloseView>
      <CloseIcon onClick={handleClose} />
    </CloseView>
  );
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.35,
        ease: [0.6, 0.05, -0.01, 0.9],
      }}
    >
      {' '}
      <RightSideView
        initial={{ x: '100%' }}
        animate={{
          x: open ? '0%' : '100%',
        }}
        exit={{
          x: '100%',
        }}
        transition={{
          duration: 0.5,
          ease: [0.6, 0.05, -0.01, 0.9],
          delay: 0.3,
        }}
      >
        {viewDetails ? (
          <div className="d-flex justify-content-between align-items-center">
            <BackView onClick={onBack} className="d-flex align-items-center">
              <ArrowLeft role="button" />
              <BackButtonText className="fs-1rem fw-400">Back</BackButtonText>
            </BackView>
            {closeView}
          </div>
        ) : (
          closeView
        )}

        {!viewDetails ? renderProductListing() : renderProductInfo()}
        {cart.length > 0 && (
          <CartSummary>
            <div>{cart.length} items added to the cart</div>

            <StyledButton variant="secondary" onClick={goToCart}>Go to cart</StyledButton>
          </CartSummary>
        )}
      </RightSideView>
    </Container>
  );
}

export default SidebarView;
