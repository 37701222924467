import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';

import {
  Wrapper,
  Content,
  CartItemWrapper,
  ViewOrderForm,
} from './view-order.styled';
import { ReactComponent as Info } from 'assets/icons/info-circle.svg';
import { viewGuestOrder } from 'helpers/http/common';
import Loader from 'components/Loader';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import {
  getImageUrl,
  getParamsAsObject,
  getStatusTitle,
} from 'helpers/utils/misc';
import useResponsive from 'helpers/hooks/useResponsive';
const reducer = (state: any, action: any) => ({ ...state, ...action });

const CartItem = ({ item = {} }: any) => (
  <CartItemWrapper className="d-lg-flex align-items-center">
    <div className="left-section d-lg-flex align-items-center">
      <div className="mb-2">
        <img
          className="product-image"
          src={getImageUrl(item.catalog_images || '[]', 'single') as string}
          alt={item.catalog_name}
        />
      </div>
      <div className="main-content">
        <div className="fs-sm fw-400">{item.brand_name}</div>
        <div className="fs-20 fw-500 mt-1">{item.catalog_name}</div>
      </div>
    </div>
  </CartItemWrapper>
);

function ViewOrder() {
  const [orderDetails, setOrderDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [state, setState] = useReducer(reducer, {
    orderNo: '',
    emailId: '',
  });
  const { isMobile } = useResponsive();

  const fetchGuestOrder = React.useCallback(({ orderNo, emailId }) => {
    return viewGuestOrder({
      order_no: orderNo,
      email_id: emailId,
    })
      .then((res) => {
        setLoading(false);
        setOrderDetails(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const params = getParamsAsObject(location.search);
    const payload = {
      orderNo: params.order_no,
      emailId: params.email_id,
    };
    setState(payload);
    fetchGuestOrder(payload);
  }, [fetchGuestOrder, location.search]);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    fetchGuestOrder(state);
  };
  return (
    <>
      <Wrapper>
        <SiteHeader />
        <Container>
          <Content>
            <ViewOrderForm className="mt-4" onSubmit={onSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Order no.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter order no"
                      maxLength={255}
                      value={state.orderNo}
                      onChange={(e) => setState({ orderNo: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email"
                      maxLength={255}
                      value={state.emailId}
                      onChange={(e) => setState({ emailId: e.target.value })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className={isMobile ? 'text-center mt-2' : 'mt-2'}>
                <StyledButton
                  height="44"
                  padding="0 2rem"
                  variant="dark"
                  type="submit"
                >
                  Check order
                </StyledButton>
              </div>
              <hr />
            </ViewOrderForm>
            {loading && (
              <div className="loading-wrapper">
                <Loader />
              </div>
            )}
            {orderDetails && (
              <div className="cart-main">
                <Row className="d-flex">
                  <Col md={8}>
                    <div className="products-view">
                      {orderDetails.products.map((product: any) => (
                        <CartItem
                          key={product.user_product_id}
                          item={{
                            ...product,
                            other_cost: orderDetails.other_cost,
                            total_amount: orderDetails.total_amount,
                            order_number: orderDetails.order_number,
                          }}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col md={4}>
                    <CartItemWrapper className="align-items-center order-summary">
                      <div className="fw-500 summary-text fs-14">SUMMARY</div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Order number:</div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          {orderDetails.order_number}
                        </div>
                      </div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Item total:</div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          ${orderDetails.sub_total_amount}
                        </div>
                      </div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">
                          Delivery charges:
                        </div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          ${orderDetails.other_cost}
                        </div>
                      </div>
                      {orderDetails?.tax_amount && (
                        <div className="mb-1 d-flex align-items-center justify-content-evenly">
                          <div className="flex-1 fs-12 fw-400">Tax amount:</div>
                          <div className="flex-1 fs-12 fw-400 text-end">
                            ${orderDetails.tax_amount}
                          </div>
                        </div>
                      )}
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Order total:</div>
                        <div className="flex-1 fs-12 fw-700 text-end">
                          ${orderDetails.total_amount}
                        </div>
                      </div>
                    </CartItemWrapper>
                  </Col>
                </Row>
                <DeliveryStatus orderDetails={orderDetails} />
              </div>
            )}
          </Content>
        </Container>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

const DeliveryStatus = ({ orderDetails }: any) => {
  return (
    <div className="order-details d-flex flex-column justify-content-center align-items-center">
      <div>
        <div className="fs-24 fw-600 text-center">Delivery Status</div>
        {orderDetails?.tracking?.tracking_number ? (
          <>
            <div className="mt-1 fs-1rem fw-400 text-center">
              Tracking ID: {orderDetails?.tracking?.tracking_number}
            </div>
            <div className="text-center track-order">
              <StyledButton
                target="_blank"
                padding="16px 32px 16px 26px"
                variant="dark"
                shadow={1}
                href={orderDetails?.tracking?.tracking_url_provider}
              >
                <LocationIcon /> Track order
              </StyledButton>
            </div>
          </>
        ) : (
          <div className="fs-20">
            {getStatusTitle(orderDetails?.order_status || 'Shipping Pending')}{' '}
            <Info stroke="currentColor" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewOrder;
