import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ChevronUp } from 'assets/icons/chevronUp.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg';

import { Wrapper } from './styled';

interface Props {
  title: string;
  description: string | JSX.Element;
  defaultExpand?: boolean;
}

function AccordionView({ title, description, defaultExpand }: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const onToggle = useCallback(() => {
    setShowDetails((details) => !details);
  }, []);

  useEffect(() => {
    setShowDetails(Boolean(defaultExpand));
  }, [defaultExpand]);

  return (
    <Wrapper className="mb-2r">
      <div
        role="button"
        className="accordionHeader d-flex align-items-center justify-content-between"
        onClick={onToggle}
      >
        <div className="fw-600 fs-20 headerText">{title}</div>
        {showDetails ? <ChevronDown /> : <ChevronUp />}
      </div>
      {showDetails && <div className="fs-1rem detailsView">{description}</div>}
    </Wrapper>
  );
}

export default AccordionView;
