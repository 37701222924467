import { buyerApiClient, apiClient } from './index';

export const createAddress = (payload: any) => {
  return buyerApiClient
    .post(`/buyer/address`, {
      action: 'new_address',
      ...payload,
    })
    .then((res) => res.data);
};

export const editAddress = (payload: any) => {
  return buyerApiClient
    .post(`/buyer/address`, {
      action: 'edit_address',
      ...payload,
    })
    .then((res) => res.data);
};

export const deleteAddress = (payload: any) => {
  return buyerApiClient
    .post(`/buyer/address`, {
      action: 'delete_address',
      ...payload,
    })
    .then((res) => res.data);
};

export const getAddress = () => {
  return buyerApiClient
    .post(`/buyer/address`, {
      action: 'list_address',
    })
    .then((res) => res.data);
};

export const getCards = () => {
  return buyerApiClient
    .post(`/buyer/cards`, {
      action: 'list_cards',
    })
    .then((res) => res.data);
};

export const deleteCard = (payload: any) => {
  return buyerApiClient
    .post(`/buyer/cards`, {
      action: 'delete_card',
      ...payload,
    })
    .then((res) => res.data);
};

export const getMyOrders = (page?: number, limit?: number, type?: string) => {
  let params = '';
  if (page) {
    params = `?page=${page}&limit=${limit}&status=${type}`;
  }
  return buyerApiClient.get(`/buyer/orders${params}`).then((res) => res.data);
};

export const getMyOrderDetails = (userOrderId: any) => {
  return buyerApiClient
    .get(`/buyer/order/${userOrderId}`)
    .then((res) => res.data);
};

export const getOrdersTotals = () => {
  return buyerApiClient.get('/buyer/orders?type=total').then((res) => res.data);
};

export const checkUserExists = (data: any) => {
  return buyerApiClient.post(`/auth/check-email`, data).then((res) => res.data);
};

export const updateCheckName = (checkName: string) => {
  const payload = { action: 'update_check_name', check_name: checkName };
  return apiClient.post(`/seller/profile`, payload).then((res) => res.data);
};
