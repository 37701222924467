import styled from "styled-components";

export const AccordionHeader = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.125rem;
`;

export const HeaderText = styled.div`
    color: ${(props) => props.theme.colors.gray3};
`;

export const DetailsView= styled.div`
    color: ${(props) => props.theme.colors.gray3};
    font-style: normal;
    font-weight: normal;
`;