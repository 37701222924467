import styled from 'styled-components';
import { Image } from 'react-bootstrap';

export const NotFoundView = styled.div<{containerMargin?: string}>`
  margin: ${({containerMargin}) => containerMargin || '8rem 0 4rem 0'};
`;

export const NoOrderFoundText = styled.div`
  margin: 2rem 0;
`;

type Props = {
  title?: string;
  containerMargin?: string;
};

function NotFound({ title, containerMargin }: Props) {
  return (
    <NotFoundView className="d-flex flex-column align-items-center" containerMargin={containerMargin}>
      <Image
        src="/images/orderNotFound.png"
        width="275px"
        height="200px"
        fluid
      />
      <NoOrderFoundText className="fs-20">{title}</NoOrderFoundText>
    </NotFoundView>
  );
}

export default NotFound;
