import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

import { Wrapper } from './styled';

interface Props {
  title: string;
  description: string | JSX.Element;
  defaultExpand?: boolean;
}

function AccordionView({ title, description, defaultExpand }: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const onToggle = useCallback(() => {
    setShowDetails((details) => !details);
  }, []);

  useEffect(() => {
    setShowDetails(Boolean(defaultExpand));
  }, [defaultExpand]);

  return (
    <Wrapper>
      <div role="button" className="accordionHeader" onClick={onToggle}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="fw-500 fs-1rem title">{title}</div>
          {showDetails ? <MinusIcon /> : <PlusIcon />}
        </div>
        {showDetails && (
          <div className="fs-1rem detailsView">{description}</div>
        )}
      </div>
    </Wrapper>
  );
}

export default AccordionView;
