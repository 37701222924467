import { ReactComponent as Edit } from 'assets/icons/edit.svg';

const AddressSummary = ({ address, onBack }: any) => {
  return (
    <div className="form content-wrap">
      <div className="d-flex justify-content-between">
        <div>
          {address.full_name +
            ', ' +
            address.address_1 +
            ', ' +
            address.address_2}
          {address.address_2 === '' ? '' : ', '}
          {address.postcode + ', ' + address.city + ', ' + address.state}
        </div>
        <div className="pointer" onClick={onBack}>
          <Edit />
        </div>
      </div>
    </div>
  );
};

export default AddressSummary;
