import styled from "styled-components";

export const Container = styled.div``;

export const ImageView = styled.img<{width?: string; height?: string}>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '301px'};
    object-fit: contain;
`;

export const ImageContainer = styled.div`
    flex: 1;
    margin: 3rem 4rem 2rem 1rem;
    @media (max-width: 767px) {
        margin: 2rem 1rem 2rem 1rem;
    }
    .image-view-container {
        flex: 1;
    }
`;
