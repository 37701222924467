import React from 'react';

import { Container, ImageContainer, ImageView } from './productImageListStyled';

interface ProductImageListProps {
  onImageSelect: (image: number) => void;
  imageList: string[];
  selectedImage?: number;
  altName?: string;
}

function ProductImageList({
  onImageSelect,
  imageList,
  selectedImage,
  altName,
}: ProductImageListProps) {
  return (
    <Container>
      {imageList.map((image: string, i: number) => (
        <ImageContainer key={i} selected={selectedImage === i}>
          <ImageView src={image} onClick={() => onImageSelect(i)} alt={altName} />
        </ImageContainer>
      ))}
    </Container>
  );
}

export default ProductImageList;
