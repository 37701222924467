import styled from 'styled-components';

export const BreadCrumbItem = styled.div<{ active?: boolean }>`
  font-weight: ${(props) => (props.active ? 400 : 600)};
  margin-right: 0.25rem;
  cursor: ${(props) => (props.active ? 'initial' : 'pointer')};
`;

export const BreadCrumbWrapper = styled.div`
  margin: 1.875rem 0rem 1.875rem 0rem;
  @media (max-width: 767px) {
    margin: 1rem 0;
  }
`;
