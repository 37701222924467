import React from 'react';

import ContractForm from 'pages/login/ContractForm';
import CompleteProfile from 'pages/settings/CompleteProfile';
import Faq from 'pages/faq/Faq';

const Login = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/login')
);
const Register = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/login/Register')
);

// lazy load components
const Dashboard = React.lazy(
  /* webpackPrefetch: true */
  /* webpackChuckName: 'Dashboard' */
  () => import('pages/dashboard')
);
const DefaultProducts = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/default-products')
);
const Listing = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing')
);
const DraftListing = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing/DraftListing')
);
const ListingDetails = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing-details')
);
const AddListing = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/listing/add-listing')
);
const Transactions = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/transactions')
);

export const sellerRoutes = {
  public: [
    {
      path: 'login',
      component: <Login />,
      title: 'Breackout LA | Seller Login',
      metaDescription: 'BreakoutLA Seller Login | Login with breakout and sell your new or old products. Manage your products with breakout easily.',
    },
    {
      path: 'register',
      component: <Register />,
      title: 'Breackout LA | Seller Register',
      metaDescription: 'BreakoutLA Seller Registration | Sell Your New or Old Sneakers and Urban Streetwear. Get attractive and best price of your product with breakout.',
    },
    {
      path: 'contract',
      component: <ContractForm />,
      title: 'Breackout LA | Seller Contract',
      metaDescription: 'BreakoutLA | Seller Contract Details.',
    },
  ],
  private: [
    {
      path: 'dashboard',
      component: <Dashboard />,
      title: 'Seller Dashboard',
    },

    {
      path: 'products',
      component: <DefaultProducts />,
      title: 'Default Products',
    },
    {
      path: 'listing',
      component: <Listing />,
      title: 'Product Listing',
    },
    {
      path: 'add-listing',
      component: <AddListing />,
      title: 'Add Listing',
    },
    {
      path: 'listing/:id',
      component: <ListingDetails />,
      title: 'Listing Details',
    },
    {
      path: 'draft-listing',
      component: <DraftListing />,
      title: 'Draft Listing',
    },
    {
      path: 'transactions',
      component: <Transactions />,
      title: 'Transactions',
    },
    {
      path: 'complete-profile',
      component: <CompleteProfile />,
      title: 'Complete Profile',
    },
    {
      path: '/seller/faq',
      component: <Faq />,
      title: 'Breackout LA | Help',
    },
  ],
};
