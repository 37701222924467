import moment from 'moment';
import { Card } from 'react-bootstrap';

function TrackCard({ data }: any) {
  return (
    <div>
      <Card>
        <Card.Header>
          Carrier occurred at : {moment(data.occurredAt).format('LLLL')}
        </Card.Header>
        <Card.Body>
          <Card.Title>{data.description}</Card.Title>
          <Card.Text>
            {data?.cityLocality ? data.cityLocality + ', ' : ''}
            {data?.stateProvince ? data.stateProvince + ', ' : ''}
            {data?.countryCode ? data.countryCode + ', ' : ''}
            {data.postalCode}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TrackCard;
