import { mediaQuery } from 'helpers/hooks/useResponsive';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const LoginWrapper = styled.div`
  /* display: flex; */
  background-color: #f3f3f3;
  min-height: 100vh;
  .dotted-link {
    border-bottom: 1px dashed #000;
    padding-bottom: 3px;
  }
`;
export const BannerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: auto;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content {
    max-width: 480px;
    margin: 0 auto;
    color: ${(props) => props.theme.colors.white};
  }
  .logo {
    img {
      @media ${mediaQuery.mobile} {
        max-width: 100px;
      }
    }
  }
`;

export const LoginContainer = styled.div`
  max-width: 460px;
  margin: 7rem auto;
`;

export const LogoDiv = styled.div`
  padding: 2rem;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid #dddddd;

  img {
    width: 140px;
    height: 39px;
  }
`;

export const LoginDiv = styled.div`
  padding: 2rem;
`;

export const LoginHeader = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

export const Input = styled(Form.Control)`
  background: white;
  height: 3.1rem;
  border-radius: 4px;
  border: none;
  padding-left: 0.75rem;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  ::-webkit-input-placeholder {
    /* Edge */
    color: #b7b7b7;
  }
  ::placeholder {
    color: #b7b7b7;
  }
`;

export const LoginButton = styled(Button)`
  background: ${(props) => props.theme.colors.black};
  width: 100%;
  height: 3.5rem;
  border-radius: 8px;
  border: none;
`;
export const LoginFormContainer = styled(Form)`
  max-width: 380px;
  width: 100%;
  h1 {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .form-label {
    font-size: 0.875rem;
  }
`;

export const FormContainer = styled.div`
  background: white;
  margin-bottom: 1.75rem;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.05));
`;

export const BulletPoints = styled.div`
  margin-top: 20px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 15px;
    li {
      width: 100px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
`;
