import { Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';
import AccordionView from './accordion/AccordionView';

export const Wrapper = styled(Form)`
  padding: 3.75rem 0rem 0rem 0rem;
  min-height: 70vh;
  max-width: 48.125rem;
  margin: auto;
  .content {
    max-width: 37.5rem;
    margin: 0px auto;
  }
  .contact-us {
    padding: 2.25rem 0rem;
    background: rgba(231, 231, 231, 0.5);
    border-radius: 12px;
    margin: 5rem 1.5rem;
    .contact-us-text {
      font-size: 1.125rem;
      margin-bottom: 1.125rem;
    }
  }
`;

const pathName = window?.location?.pathname;

type FAQType = {
  question: string;
  answer: string | JSX.Element;
};

const faqItems: FAQType[] = [
  {
    question: 'Where are you located?',
    answer: (
      <>
        We are located in Long Beach , CA ! <br /> 690 W. Willow St. <br /> Long
        Beach , CA 90806
      </>
    ),
  },
  {
    question: 'Do you ship outside of Long Beach , CA?',
    answer: 'Yes - we process and ship orders globally to over 240 countries.',
  },
  {
    question: 'How long will it take for my order to arrive?',
    answer:
      'Most orders are prepared for shipping within two (2) business day after your place it. When it has been boxed, and a label has been created for your tracking, you will receive an email confirmation with that information. Domestic U.S. Customers typically receive their purchases within 1-3 business days after dispatch. International customers usually receive their purchases within 7-10 business days after dispatch.',
  },
  {
    question: 'Why was my order canceled?',
    answer:
      'There are several reasons for an order being canceled. We will contact you if your order cannot be fulfilled and provide you an explanation. Please note that we reserve the right to refuse or cancel any order, for any reason, at our sole discretion.',
  },
  {
    question: 'What happens if my package is returned to Breakout?',
    answer:
      "If your order is returned to us, we will contact you in regards of it's return reasons. We will try our best options to get your order to you.",
  },
  {
    question: 'What is your return policy?',
    answer: (
      <>
        <div className="fw-600 fs-1rem mb-1">NO RETURNS OR EXCHANGES.</div>
        <div className="fw-600 fs-1rem mb-1">
          All sales with Breakout LA are final. No returns or exchanges.{' '}
        </div>{' '}
        <div className="fs-1rem mb-1">
          Breakout LA is a marketplace which means, items we carry and offer
          belong to independent owners. Once an item sells in-store or online,
          the owner receives payment for their merchandise.
        </div>
        <div className="fs-1rem mb-1">
          Refunds are <b>ONLY</b> processed based on fulfillment errors on our
          end, such as items being out of stock or incorrectly shipped.
        </div>
      </>
    ),
  },
  {
    question: 'Are all the items you sell authentic?',
    answer:
      'Breakout sells only 100% authentic product. We do not accept fakes or counterfeits. We guarantee the authenticity of every item we sell, as we have in-house authentication specialists who inspect all product that is taken in and put up for sale.',
  },
  {
    question: 'If an item says “Pre-owned” is it the same as in the picture?',
    answer: (
      <>
        The picture on the website for worn items are not of the actual shoe,
        but a stock image, taken here at Urban Necessities, of a deadstock pair.
        Worn items are priced according to their condition. If you would like to
        request pictures of the actual worn pair for sale, you may send a
        request to{' '}
        <a href="mailto:orders.breakout@gmail.com">orders.breakout@gmail.com</a>{' '}
        Include the name, size, and price of the item or a screen shot.
      </>
    ),
  },
  {
    question: 'Does Breakout buy items?',
    answer:
      'Yes we do ! We have been moving our selection to buybacks to 99% Brand new with box etc. We will buy pre-owned items but only at our will if we feel like we need the item. It all varies when the item(s) are pre-owned.',
  },
  {
    question: 'Who sets the prices?',
    answer:
      'Prices are negotiated based on what the shoes sell for at market value. We agree on a price with our consignors. We price items to move quickly.',
  },
  {
    question: 'When will I get paid for my consignment?',
    answer: (
      <>
        <div className="fs-1rem mb-1">
          We issue checks in your name as your items sell. (So you don’t have to
          wait for an entire lot or parcel of shoes to sell to get paid if you
          consign multiple units).
        </div>
        <div className="fs-1rem mb-2">
          Checks are prepared bi-weekly. These checks will be for the prior two
          weeks' sales Sunday thru Saturday. Your check will be mailed to you.
        </div>
        <div className="fs-1rem mb-2">
          If you have any other questions please feel free to contact us ;
        </div>
        <div className="fs-1rem mb-2">
          <a href="mailto:inquiries.Breakout@gmail.com">
            Inquiries.Breakout@gmail.com
          </a>
        </div>
        <div className="fs-1rem mb-2">(562)676-4100</div>
      </>
    ),
  },
];

function Faq() {
  return (
    <>
      {!pathName?.includes('seller') && <SiteHeader />}
      <Wrapper>
        <div className="content">
          <h1 className="text-center fs-32 fw-800 mb-5">FAQs</h1>
          <Container>
            {faqItems.map((item: FAQType, index: number) => (
              <AccordionView
                key={item.question}
                defaultExpand={index == 0}
                title={item.question}
                description={item.answer}
              />
            ))}
          </Container>
        </div>
        <div className="contact-us text-center">
          <div className="contact-us-text fw-400">
            Did not find what you are looking for?
          </div>
          <Link to="/contactus" target="_blank">
            <StyledButton variant="dark">Contact us</StyledButton>
          </Link>
        </div>
      </Wrapper>
      {!pathName?.includes('seller') && <SiteFooter />}
    </>
  );
}

export default Faq;
