/* eslint-disable react/prop-types */
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StyledButton } from 'components/forms/Buttons';
import { WalletPayButton } from './WalletPayButton';

import { ReactComponent as CreditCardIcon } from 'assets/icons/credit-card.svg';
import {
  CreditCardPaymentWrapper,
  PaymentTabButton,
} from './buyerCheckout.styled';
import { useState } from 'react';
import { showErr } from 'helpers/utils/misc';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const PaymentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (max-width: 768px) {
    gap: 12px;
    flex-direction: column-reverse;
    align-items: stretch;
  }
`;

const PaymentWalletButton = styled.div`
  width: 250px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default function PaymentForm({ clientSecret, total }: any) {
  const [loading, setLoading] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState('card');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    try {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      setLoading(true);

      if (!stripe || !elements) {
        setLoading(false);
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const redirectUrl = `${window.location.origin}/order-placed`;
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: redirectUrl,
        },
      });
      console.log('result:>', result);
      setLoading(false);

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        // console.log(result.error.message);
        const errorMessage = result?.error?.message || 'Payment failed';
        showErr(errorMessage);
      } else {
        console.log('Credit Card Payment completed');
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (err) {
      console.log('Payment error:> ', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PaymentContainer>
        <PaymentTabButton
          className={activePaymentMethod === 'card' ? 'active' : ''}
          onClick={() => setActivePaymentMethod('card')}
        >
          <CreditCardIcon />
          <span>Debit/Credit Card</span>
        </PaymentTabButton>
        <PaymentWalletButton>
          <WalletPayButton
            total={total}
            clientSecret={clientSecret}
            onClick={() => setActivePaymentMethod('wallet')}
          />
        </PaymentWalletButton>
      </PaymentContainer>
      {activePaymentMethod === 'card' && (
        <form onSubmit={handleSubmit}>
          <CreditCardPaymentWrapper>
            <PaymentElement
              options={{
                layout: {
                  type: 'tabs',
                  defaultCollapsed: false,
                },
                wallets: {
                  applePay: 'never',
                  googlePay: 'never',
                },
              }}
              onLoadError={(err) => {
                const errorMessage =
                  err?.error?.message || 'Unable to load payment form';
                showErr(errorMessage);
                setTimeout(() => {
                  window.location.reload();
                }, 4000);
              }}
            />
          </CreditCardPaymentWrapper>

          <div className="text-end mt-4">
            <StyledButton
              type="submit"
              variant="dark"
              disabled={!stripe || loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>Pay now (${total})</>
              )}
            </StyledButton>
          </div>
        </form>
      )}
    </>
  );
}
