import React, { useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';

type Props = {
  inputClassName?: string;
  placeholder?: string;
  name: string;
  onChange: (value: string) => void;
  value?: string;
};

const APIKey = 'AIzaSyDCb172KWTPuDL4ghRL59Cymeal96qHsQo';

/**
 * It uses react google autocomplete library
 * to integrate google place API.
 * This autocomplete is specific to checkout address
 * which helps customer to easily find appropriate address.
 */
function AutoComplete({
  inputClassName,
  placeholder,
  onChange,
  name,
  value,
}: Props) {
  const autocompleteRef = React.useRef<any>(null);
  useEffect(() => {
    if (value) {
      autocompleteRef.current.value = value;
    }
  }, [value]);

  return (
    <Autocomplete
      apiKey={APIKey}
      // onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
      //   onChange(e.target.value)
      // }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
      options={{
        types: ['establishment', 'geocode',],
        language: 'en',
      }}
      onPlaceSelected={(place) => {
        onChange(place);
      }}
      className={inputClassName}
      placeholder={placeholder}
      ref={autocompleteRef}
    />
  );
}

export default AutoComplete;
