import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';
import BreadCrumbView from 'components/breadcrumb-view/BreadCrumbView';
import { BreadCrumbItem } from 'components/breadcrumb-view/breadcrumb.type';
import {
  Wrapper,
  Content,
  CartSummaryWrapper,
  CartItemWrapper,
} from './cart.styled';
import Checkbox from 'components/forms/Checkbox';
import PromptModal from 'components/PromptModal';
import NotFound from 'components/ui/NotFoundView';
import { useCart } from 'helpers/contexts/cart-context';
import { getCart } from '../../helpers/http/cartApi';
import Loader from 'components/Loader';
import { getImageUrl } from 'helpers/utils/misc';
import { ReactComponent as Delete } from 'assets/icons/trash.svg';
import { getToken } from 'helpers/services/auth';

function Cart() {
  const navigate = useNavigate();
  const { cart, removeAll, removeMultiple } = useCart();
  const [cartItems, setCartItems] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [promptState, setPromptState] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const token = React.useMemo(getToken, []);

  useEffect(() => {
    // if (!token) {
    //   setCartItems(cart);
    //   return;
    // }
    const productIds = cart.map((item: any) => item.user_product_id);
    if (productIds.length > 0) {
      setLoading(true);
      getCart(productIds)
        .then((res) => {
          if (!res.status) {
            setCartItems([]);
            removeAll();
          }
          setCartItems(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setCartItems([]);
    }
  }, [cart, removeAll, token]);

  const cartTotal = useMemo(() => {
    if (!cartItems?.length) return null;
    const sumall = cartItems
      .map((item: any) => item.sell_price)
      .reduce((prev: any, curr: any) => parseFloat(prev) + parseFloat(curr), 0);
    return sumall?.toFixed(2);
  }, [cartItems]);

  const onProceed = () => {
    navigate('/checkout');
  };

  const CartSummary = () => {
    return (
      <CartSummaryWrapper>
        <div className="fs-14 fw-500" style={{ opacity: 0.6 }}>
          Subtotal ({cartItems?.length} items)
        </div>
        <div className="d-flex mt-1 justify-content-between align-items-center">
          <div className="fs-20 fw-700">${cartTotal}</div>
        </div>
        <StyledButton
          style={{
            marginTop: 18,
            width: '100%',
          }}
          variant="dark"
          type="submit"
          onClick={onProceed}
        >
          Proceed to buy
        </StyledButton>
      </CartSummaryWrapper>
    );
  };

  const onSelectProduct = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredItems = selectedItems.filter((item: any) => item !== id);
      setSelectedItems(filteredItems);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const CartItem = ({ item }: any) => {
    return (
      <CartItemWrapper className="d-lg-flex align-items-center">
        <Checkbox
          checked={selectedItems.includes(item.user_product_id)}
          toggle={() => onSelectProduct(item.user_product_id)}
        />
        <div className="mb-2">
          <img
            className="product-image"
            src={getImageUrl(item.catalog_images, 'single') as string}
            alt={item.catalog_name}
          />
        </div>
        <div className="mx-2" style={{ width: '100%' }}>
          <div className="fs-sm fw-400">{item.brand_name}</div>
          <div className="fs-20 fw-500">{item.catalog_name}</div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
              {item?.product_option_config?.box_condition && (
                <>
                  <div className="fs-1rem fw-500 capitalize-first">
                    Box condition:{' '}
                    <span className="text-capitalize">
                      {item?.product_option_config?.box_condition}
                    </span>
                  </div>
                  <span className="mx-1 mx-lg-3" style={{ opacity: 0.3 }}>
                    |
                  </span>
                </>
              )}
              <div className="fs-1rem fw-500 capitalize-first">
                Product condition:{' '}
                <span className="text-capitalize">
                  {item?.product_option_config?.product_conditions
                    ? item?.product_option_config?.product_conditions.includes(
                        'pre'
                      )
                      ? 'Pre-owned'
                      : item?.product_option_config?.product_conditions
                    : 'New'}
                </span>
              </div>

              {item.color ? (
                <>
                  <span className="mx-1 mx-lg-3" style={{ opacity: 0.3 }}>
                    |
                  </span>
                  <div className="fs-1rem fw-500">{item.color} color</div>
                </>
              ) : null}
            </div>
            <div className="d-none d-lg-block cart-item-price">
              ${item.sell_price}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-1rem fw-500">
              Size: {item.size_value} {item.size_unit}
            </div>
          </div>
          {/* Price block for mobile view */}
          <div className="d-block d-lg-none cart-item-price">
            ${item.sell_price}
          </div>
        </div>
      </CartItemWrapper>
    );
  };

  const breadcrumbList: BreadCrumbItem[] = [
    { title: 'Home' },
    { title: 'Cart' },
  ];

  const togglePrompt = () => {
    setPromptState({
      ...promptState,
      show: !promptState.show,
      title: 'Are you sure you want to remove these items from your cart?',
    });
  };
  const onSubmitPrompt = () => {
    removeMultiple(selectedItems);
    setSelectedItems([]);
    setPromptState({
      ...promptState,
      show: !promptState.show,
    });
  };

  return (
    <>
      <Wrapper>
        <SiteHeader />

        <Content>
          <Container>
            <BreadCrumbView list={breadcrumbList} />
            <div className="d-flex justify-content-between align-items-end">
              <h4 className="mb-0 fw-700">
                Cart{' '}
                <sub className="mx-1 fs-1rem fw-500">
                  {cartItems?.length} items
                </sub>
              </h4>
              {selectedItems.length !== 0 && (
                <div className="d-flex pointer" onClick={togglePrompt}>
                  <Delete />
                  <div className="mx-1">
                    Remove {selectedItems.length} item(s)
                  </div>
                </div>
              )}
            </div>
            {cartItems === null || loading ? (
              <Loader />
            ) : cartItems?.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center w-100 no-data-found">
                <NotFound
                  title={'No items in the cart'}
                  containerMargin="5.25rem 0 12rem 0"
                />
              </div>
            ) : (
              <>
                <div className="cart-main">
                  <div className="d-flex">
                    <div className="cart-section-wrapper">
                      {cartItems?.map((item: any, index: number) => (
                        <CartItem item={item} key={index} />
                      ))}
                    </div>
                    <div className="d-none d-lg-block">
                      <CartSummary />
                    </div>
                  </div>
                </div>
                {/* Cart details at bottom for mobile view */}
                <div className="d-block d-lg-none">
                  <CartSummary />
                </div>
              </>
            )}
            <PromptModal
              show={promptState.show}
              onSubmit={onSubmitPrompt}
              title={promptState.title}
              toggle={togglePrompt}
              message={''}
              hasReason={false}
              submitButtonText="Remove"
            />
          </Container>
        </Content>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default Cart;
