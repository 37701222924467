import { Form } from 'react-bootstrap';

function Checkbox({ label = '', className, toggle, checked }: any) {
  return (
    <Form.Check
      type="checkbox"
      className={`fs-1rem ${className}`}
      label={label}
      checked={checked}
      onChange={toggle}
    />
  );
}

export default Checkbox;
