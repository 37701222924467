import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div<{ selected?: boolean }>`
  width: 84px;
  height: 84px;
  margin: 0rem 0.35rem 1rem 0.35rem;
  background-color: #f1f1f1;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  ${(props) => props.selected && 'border: 1px solid #292D32;'}
  transition: all .5s ease-out;
  &:hover {
    transform: scale(1.035);
    img {
      border: solid white;
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    border: 0;
  }
`;

export const ImageView = styled.img`
  height: 51px;
  width: 68px;
`;
