export const DEFAULT_FILTER: {
  brandId?: string[];
  categoryId: string[];
  gender: string[];
  sizes: string[];
  conditions: string[];
} = {
  brandId: [],
  categoryId: [],
  gender: [],
  sizes: [],
  conditions: [],
};
