import React, { useState } from 'react';
import { StyledButton } from 'components/forms/Buttons';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { ReactComponent as BulletIcon1 } from 'assets/icons/bulletIcon1.svg';
import { ReactComponent as BulletIcon2 } from 'assets/icons/bulletIcon2.svg';
import { ReactComponent as BulletIcon3 } from 'assets/icons/bulletIcon3.svg';
import { ReactComponent as SelectLine } from 'assets/icons/selectLine.svg';
import {
  BannerContainer,
  Wrapper,
  Content,
  CallToActionContainer,
  SellerTosContainer,
  ActionSection,
  SellerProcessWrapper,
  TermsView,
  UpArrow,
} from './styled';
import { Link } from 'react-router-dom';

function SellWithBreakout() {
  return (
    <>
      <Wrapper>
        <SiteHeader />
        <Banner />
        <Content>
          <div className="para-container">
            <p className="para text-center">
              Selling with Breakout is the easiest way to move your brand new
              and lightly worn Sneakers , Streetwear & Accessories. With our
              premier online experience & retail store in Long Beach , CA,
              Breakout offers the best sell-through in the game.
            </p>
          </div>
          <CallToAction />
          <SellingProcess />
          <SellerTos />
          <ActionSection>
            <div className="action-link">
              <Link to="/faq">FAQ</Link>
            </div>
            <span className="separator" />
            <div className="action-link">
              <Link to="/contactus">Contact Us</Link>
            </div>
          </ActionSection>
        </Content>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default SellWithBreakout;

const Banner = () => {
  return (
    <BannerContainer>
      <h3>Sell With Breakout</h3>
    </BannerContainer>
  );
};

const SellingProcess = () => {
  return (
    <div className="text-center">
      <h1 className="fs-32 mb-4">Selling Process</h1>
      <SellerProcessWrapper>
        <div className="select-bullet-main align-items-center">
          <BulletIcon1 />
          <SelectLine className="select-line" />
          <BulletIcon2 />
          <SelectLine className="select-line" />
          <BulletIcon3 />
        </div>
        <div>
          <p className="process-step-text">
            Create a submission on{' '}
            <Link to="/seller"> breakout.la/seller </Link> for the item(s) you
            want to sell
          </p>
          <p className="process-step-text">
            Once received in store, we process and verify your item(s)
            authenticity. We then list them on
            <Link to="/"> Breakout.la</Link>
          </p>
          <p className="process-step-text">
            When your item(s) sell, you will receive payment via check
            <Link to="/seller"> breakout.la/seller </Link>
          </p>
        </div>
      </SellerProcessWrapper>
    </div>
  );
};

const CallToAction = () => {
  return (
    <CallToActionContainer>
      <h2>Ready to sell with Breakout?</h2>
      <StyledButton height="51" variant="dark" href="/seller/search">
        Start Selling
      </StyledButton>
    </CallToActionContainer>
  );
};

const SellerTos = () => {
  const [showFull, setShowFull] = useState<boolean>(false);
  return (
    <SellerTosContainer>
      <h3 className="fs-32 mb-4">Seller Terms & conditions</h3>
      <TermsView full={showFull}>
        <p>
          You bring us , or ship us your item(s). Consignor is responsible for
          all shipping costs to get the items to & from Breakout.
        </p>
        <ul className="text-left">
          <li>
            We help you determine your sell prices in accordance with reasonable
            market values using our experience and extensive data.
          </li>
          <li>
            The consignment rate will be 85/15. 85% of the sale goes to the
            consignor, 15% goes to BreakoutLA or $30, whichever is greater.
          </li>
          <li>
            Consignor will pay a $20 fee for each item being removed / pulled
            out of our stock/inventory before 15 calendar days after dropping
            off.
          </li>
          <li>
            Items must be supplied clean and ready to be displayed to avoid a
            cleaning fee.
          </li>
          <li>
            Once Breakout has possession of the Consignors item(s) , there will
            be a authentication verification process before item becomes
            available for sell.
          </li>
          <li>
            <div className="fw-600 fs-1rem mb-1 underlined-list-item">
              BREAKOUT HAS THE RIGHT TO REFUSE ANY ITEMS OR DECLINE ANY SERVICE.
            </div>
          </li>
          <li>
            If “Agreed Asking Price” isn't conforming with current market value
            , we will contact the consignor to allow Breakout Los Angeles to
            adjust the price.
          </li>
        </ul>
        {!showFull && (
          <div className="action-container">
            <StyledButton
              height="51"
              variant="transparent"
              shadow={1}
              onClick={() => setShowFull((full) => !full)}
            >
              {!showFull ? 'View all terms' : 'Show less'}{' '}
              <UpArrow stroke="#292D32" full={showFull} />
            </StyledButton>
          </div>
        )}
      </TermsView>
    </SellerTosContainer>
  );
};
