/* eslint-disable @typescript-eslint/no-non-null-assertion */
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import React, { useCallback } from 'react';
interface AuthContextType {
  cart: any;
  add: (item: any) => void;
  remove: (item: any) => void;
  removeAll: () => void;
  removeMultiple: (ids: any) => void;
  setCart: (cart: any) => void;
}
const CartContext = React.createContext<AuthContextType>(null!);

function CartProvider({ children }: { children: React.ReactNode }) {
  const [cart, setCart] = useLocalStorage<any>('cart', []);
  const add = useCallback(
    (item: any) => {
      setCart((prevCart: any) => [...prevCart, item]);
    },
    [setCart]
  );
  const remove = useCallback(
    (id: any) => {
      setCart((prevCart: any) =>
        prevCart.filter((item: any) => item.user_product_id !== id)
      );
    },
    [setCart]
  );
  const removeMultiple = useCallback(
    (ids: any) => {
      setCart((prevCart: any) =>
        prevCart.filter((item: any) => !ids.includes(item.user_product_id))
      );
    },
    [setCart]
  );
  const removeAll = useCallback(() => {
    setCart([]);
  }, [setCart]);
  const value = React.useMemo(
    () => ({ cart, add, remove, removeAll, removeMultiple, setCart }),
    [cart, add, remove, removeAll, removeMultiple, setCart]
  );
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

function useCart() {
  return React.useContext(CartContext);
}

export { CartProvider, useCart };
