import useDebounce from 'helpers/hooks/useDebounce';
import { searchProducts } from 'helpers/http/product';
import { ProductType } from 'pages/default-products/product.type';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
const PRODUCTS_PER_PAGE = 12;

const DEFAULT_STATE = {
  filters: {
    brandId: [] as string[],
    categoryId: [] as string[],
    gender: [] as string[],
    sizes: [] as string[],
    conditions: [] as string[],
  },
  search: '',
  pageno: 1,
};
const filterReducer = (state = DEFAULT_STATE, action: any) => {
  switch (action.type) {
    case 'SET_FILTER':
      return { ...state, filters: action.payload, pageno: 1 };
    case 'SET_SEARCH':
      return { ...state, search: action.payload, pageno: 1 };
    case 'SET_PAGE':
      return { ...state, pageno: action.payload };
    case 'CLEAR_FILTER': {
      return { ...state, filters: DEFAULT_STATE.filters };
    }
    default:
      return state;
  }
};
type ProductDataType = {
  data: ProductType[];
  availableSizes: any[];
  total: number;
  isLoading: boolean;
  error: null | any;
  totalPages: number;
};
function useSearch() {
  const [filters, dispatch] = useReducer(filterReducer, DEFAULT_STATE);
  const debouncedFilters: any = useDebounce(filters, 500);
  const [product, setProducts] = useState<ProductDataType>({
    data: [],
    availableSizes: [],
    total: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
  });
  const location = useLocation();
  useEffect(() => {
    if (window.history) {
      const filtersQuery = createFilterQuery(debouncedFilters);
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?q=${debouncedFilters.search}&pg=${debouncedFilters.pageno}${filtersQuery}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }

    setProducts((prev: any) => ({ ...prev, isLoading: true }));
    searchProducts(
      debouncedFilters.search,
      debouncedFilters.filters,
      debouncedFilters.pageno,
      PRODUCTS_PER_PAGE
    )
      .then((res) => {
        window.scrollTo(0, 0);
        if (res.status) {
          setProducts((prev: any) => ({
            ...prev,
            data: res.data,
            availableSizes: res.available_sizes,
            total: res.total,
            totalPages: Math.ceil(res.total / PRODUCTS_PER_PAGE),
            isLoading: false,
          }));
        } else {
          setProducts((prev: any) => ({ ...prev, isLoading: false }));
        }
      })
      .catch((err) => {
        setProducts((prev: any) => ({
          ...prev,
          isLoading: false,
          error: err?.response?.data?.message || err?.toString(),
        }));
      });
  }, [debouncedFilters]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    dispatch({ type: 'SET_FILTER', payload: queryToFilter(location.search) });
    dispatch({ type: 'SET_SEARCH', payload: params.get('q') || '' });
    dispatch({
      type: 'SET_PAGE',
      payload: params.get('pg') ? Number(params.get('pg')) : 1,
    });
  }, [location.search]);

  const productData = useMemo<ProductDataType>(() => product, [product]);
  return {
    dispatch,
    productFilters: filters,
    productData,
    debouncedSearch: debouncedFilters.search,
  };
}

export default useSearch;
// utilities
export const setFilters = (dispatch: any, f: any) => {
  dispatch({ type: 'SET_FILTER', payload: f });
};
export const setSearchText = (dispatch: any, searchInput: any) => {
  dispatch({ type: 'SET_SEARCH', payload: searchInput });
};
export const setPageNo = (dispatch: any, page: any) => {
  dispatch({ type: 'SET_PAGE', payload: page });
};
export const clearFilter = (dispatch: any) => {
  dispatch({ type: 'CLEAR_FILTER' });
};

const createQuery = (key: string, filterItem: any) => {
  if (filterItem?.length > 0) {
    return `&${key}=${filterItem.join(',')}`;
  }
  return '';
};
const createFilterQuery = (filters: any) => {
  return `${createQuery('bi', filters?.filters?.brandId)}${createQuery(
    'ci',
    filters.filters.categoryId
  )}${createQuery('gf', filters.filters.gender)}${createQuery(
    'sf',
    filters.filters.sizes
  )}${createQuery('cf', filters.filters.conditions)}
 `;
};

const queryToFilter = (query: string) => {
  const params = new URLSearchParams(query);
  const getSingleFilter = (key: string) => {
    const dta = params.get(key);
    if (dta) return dta.split(',');
    return [];
  };
  return {
    brandId: getSingleFilter('bi'),
    categoryId: getSingleFilter('ci'),
    gender: getSingleFilter('gf'),
    sizes: getSingleFilter('sf'),
    conditions: getSingleFilter('cf'),
  };
};
