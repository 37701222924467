const ErrorMessage = ({
  message,
  children,
  className,
}: {
  children?: React.ReactNode;
  message?: string;
  className?: string;
}) => <small className={`text-danger mt-2 d-block ${className}`}>{children || message}</small>;
export default ErrorMessage;
