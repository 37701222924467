// /* eslint-disable no-debugger */
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';

type Props = {
  show: boolean;
  toggle: () => void;
  onSubmit: (msg?: string) => void;
  title: string;
  message: string;
  hasReason: boolean;
  actionVariant?: string;
  className?: string;
  submitButtonText?: string;
};

const StyledPromptModal = styled(Modal)`
  .promptTitle {
    font-size: 1.5rem;
  }
  .promptMsg {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .modal-dialog {
    position: absolute;
    max-width: 350px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
  .modal-content {
    border-radius: 12px;
    ${(props) =>
      props.actionVariant === 'variant1' &&
      css`
        padding: 1rem;
      `}
  }
  .v2btn {
    width: 114px;
    height: 51px;
    border-radius: 8px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: white;
    transform: translate(30px, -10px);
    font-size: 1.75rem;
    font-weight: 200;
  }
`;

function PromptModal({
  show,
  toggle,
  onSubmit,
  message,
  title,
  hasReason = false,
  className,
  actionVariant,
  submitButtonText,
}: Props) {
  const [note, setNote] = React.useState('');
  const handleSubmit = () => {
    onSubmit(note);
  };
  return (
    <StyledPromptModal
      show={show}
      onHide={toggle}
      className={className}
      variant={actionVariant}
    >
      <Modal.Body>
        {actionVariant === 'variant2' ? (
          <Button variant="transparent" className="close" onClick={toggle}>
            &times;
          </Button>
        ) : null}

        {!!title && <h3 className="promptTitle px-2 pt-2">{title}</h3>}
        {!!message && <p className="text-center promptMsg mt-3">{message}</p>}

        {hasReason && (
          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="description"
              placeholder="Write here"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
        )}
        {actionVariant === 'variant2' ? (
          <div className="d-flex g-1 py-3 justify-content-end">
            <Button variant="dark" onClick={handleSubmit} className="v2btn">
              Delete
            </Button>
          </div>
        ) : (
          <div className="d-flex g-1 py-3 justify-content-end">
            <Button variant="outline-dark" onClick={toggle}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleSubmit}>
              {submitButtonText ? submitButtonText : 'Delete'}
            </Button>
          </div>
        )}
      </Modal.Body>
    </StyledPromptModal>
  );
}

export default PromptModal;
