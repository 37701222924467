import Loader from 'components/Loader';
import { OrderDetailsWrapper } from './buyerCheckout.styled';

type CartPrice = {
  otherCost: string;
  subTotal: string;
  taxAmount: string;
  taxRate: string;
  totalAmount: string;
  discountAmount?: string;
};

const OrderSummary = ({ cartPrice }: { cartPrice?: CartPrice }) => {
  return (
    <OrderDetailsWrapper>
      {!cartPrice ? (
        <Loader height="initial" />
      ) : (
        <>
          <div className="fs-14">ORDER SUMMARY</div>
          <div className="d-flex mt-1 justify-content-between align-items-center">
            <div className="fs-14">Item total:</div>
            <div className="fs-14 fw-500">${cartPrice.subTotal}</div>
          </div>
          {Number(cartPrice.discountAmount) ? (
            <div className="d-flex mt-1 justify-content-between align-items-center">
              <div className="fs-14">Discount:</div>
              <div className="fs-14 fw-500">${cartPrice.discountAmount}</div>
            </div>
          ) : null}
          <div className="d-flex mt-1 justify-content-between align-items-center">
            <div className="fs-14">Shipping Charges:</div>
            <div className="fs-14 fw-500">${cartPrice.otherCost}</div>
          </div>
          <div className="d-flex mt-1 justify-content-between align-items-center">
            <div className="fs-14">Tax ({cartPrice.taxRate}%):</div>
            <div className="fs-14 fw-500">${cartPrice.taxAmount}</div>
          </div>
          <div className="summary-row-devider" />
          <div className="d-flex mt-1 justify-content-between align-items-center">
            <div className="fs-14">Total:</div>
            <div className="fs-20 fw-700">${cartPrice.totalAmount}</div>
          </div>
        </>
      )}
    </OrderDetailsWrapper>
  );
};

export default OrderSummary;
