import { ReactComponent as Edit } from 'assets/icons/edit.svg';

const ShippingMethodSummary = ({ method, onBack }: any) => {
  return (
    <div className="form content-wrap">
      <div className="d-flex justify-content-between">
        <div>
          {method ? method.shipping_name : 'Normal Shipping'}
        </div>
        <div className="pointer" onClick={onBack}>
          <Edit />
        </div>
      </div>
    </div>
  );
};

export default ShippingMethodSummary;
