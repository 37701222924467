import { apiClient } from 'helpers/http';
import React from 'react';
import { useQuery } from 'react-query';
const getCountries = () =>
  apiClient.get('/general/country').then((r) => r.data);
const getStates = (countryCode: string) =>
  apiClient
    .get(`/general/country?countryCode=${countryCode}`)
    .then((r) => r.data);
function useCityCountryState() {
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [selectedState, setSelectedState] = React.useState('');
  const { data: countryData } = useQuery('countries', getCountries);
  const { data: stateData, isLoading } = useQuery(
    ['state', selectedCountry],
    () => getStates(selectedCountry),
    {
      enabled: !!selectedCountry,
    }
  );
  return {
    countries: countryData?.data,
    setSelectedCountry,
    states: stateData?.data,
    selectedCountry,
    selectedState,
    setSelectedState,
  };
}

export default useCityCountryState;
