export const collectionResponsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 4
    },
    tablet_xs: {
        breakpoint: { max: 1200, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 767 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1
    }
};

export const brandResponsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
