import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  p.para {
    font-size: ${22 / 16}rem;
    line-height: 1.5;
  }
  .main-container {
    flex-direction: column;
    padding: 5rem;
  }
  .title {
    margin-top: 1.5rem;
  }
  .content {
    max-width: 413px;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 0.75rem;
  }
  .buttons {
    margin-top: 2.5rem
  }
`;

export const Content = styled(Container)`
  min-height: 80vh;
`;