import styled from 'styled-components';

export const FooterWrapper = styled.div`
  background-color: #000;
  padding: 2.4rem;
  .navbar-brand {
    margin-left: 0.8rem;
    margin-bottom: 0.25em;
    display: block;
  }
  .section-title {
    font-weight: 500;
    font-size: 1.25rem;
    color: #fff;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    @media (max-width: 680px) {
      font-size: 1rem;
    }
  }
  .nav-link {
    font-weight: 400;
    font-size: 1rem;
    color: #d9dbe1;
    @media (max-width: 680px) {
      font-size: 0.8rem;
    }
  }
  .copyright {
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    margin-left: 0.8rem;
    margin-bottom: 0.5rem;
    .moontek-link {
      text-decoration: none;
      border-bottom: 1px dashed #0078e7;
      padding-bottom: 2px;
    }
  }
`;

export const SocialIconButton = styled.a`
  background-color: rgba(255, 255, 255, 0.1);
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  margin-left: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.65;
  }
`;
