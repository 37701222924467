import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   process.env.REACT_APP_STRIPE_PUBLISH_KEY as string
// );

const stripePromise = (async () => {
  try {
    return await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);
  } catch (err) {
    console.error('Stripe error:>', err);
    window.location.reload();
    return null;
  }
})();

function StripePay({ clientSecret, total }: any) {
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm total={total} clientSecret={clientSecret} />
    </Elements>
  );
}

export default StripePay;
