import { mediaQuery } from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  .search-details {
    /* margin-top: 2rem; */
    margin-bottom: 2.75rem;
    @media ${mediaQuery.mobile} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .shadowBox {
    box-shadow: 0px 0.25rem 2.5rem rgba(0, 0, 0, 0.04);
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    margin: 0 0 1rem 0;
  }

  .product-item {
    /* margin: 0rem 0.9375rem 1.875rem 0rem; */
  }
  .size-button {
    padding: 0.5rem;
    min-width: 3rem;
    margin: 0.375rem;
    border: ${(props) => '1px solid ' + props.theme.colors.gray2};
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .size-button-selected {
    padding: 0.5rem;
    min-width: 3rem;
    margin: 0.375rem;
    border: ${(props) => '1px solid ' + props.theme.colors.black};
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .home-banner {
    min-height: 550px;
    background: #f5f5f5;
    border-radius: 20px;
    display: flex;
    align-items: center;

    .banner-slide {
      position: relative;
    }

    .display-center {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
    }

    .text-sub-title {
      color: #232f3e;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      opacity: 0.6;
      margin-bottom: 20px;
    }

    .text-title {
      font-weight: bold;
      font-size: 52px;
      line-height: 62px;
      color: #000000;
      margin-bottom: 40px;
    }

    .banner-image {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      position: relative;
    }

    .overlay {
      background: linear-gradient(
        153.66deg,
        rgba(255, 255, 255, 0.078) 27.36%,
        rgba(255, 255, 255, 0.78) 88.54%
      );
      width: 500px;
      height: 500px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 767px) {
    .home-banner {
      .text-title {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 25px;
      }
      .overlay {
        width: 300px;
        height: 300px;
      }
    }
  }
  .products-list {
    --bs-gutter-y: 1.5rem;
    min-height: 60vh;
  }
  /* .no-data-found {
    min-height: 100vh;
  } */
  .more-link {
    border-bottom: 1px dashed #000;
    padding-bottom: 3px;
    margin-top: 5px;
    width: 3.75rem;
  }
`;

export const Section = styled.div`
  position: relative;
  margin: 80px 0 100px;

  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;

    svg {
      margin-left: 10px;
    }
  }

  .carousel-button-group {
    position: absolute;
    top: 5px;
    right: 20px;

    .btn-slide {
      background: #ffffff;
      border: none;
    }
  }

  @media (max-width: 767px) {
    margin: 20px 0 40px;

    h3 {
      font-size: 24px;
      line-height: 34px;
    }
    .carousel-button-group {
      right: 0px;
      top: 3px;
    }
  }
`;
