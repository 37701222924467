import { apiClient } from './index';

export const resetPassword = (data: any) => {
  return apiClient.post('/auth/reset-password', data).then((res) => {
    if (!res.data.status) {
      throw new Error(res.data.message);
    }
    return res.data;
  });
};
