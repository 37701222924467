import { Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Input, LoginFormContainer } from './login.styled';
import { useForm } from 'react-hook-form';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(yup); // register the plugin

const schema = yup
  .object({
    email_id: yup
      .string()
      .email('Email must be a valid email')
      .max(255)
      .required('Email is required'),
  })
  .required();

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required.')
    .min(6)
    .max(255)
    .minUppercase(1)
    .minNumbers(1),
});

import bg from 'assets/loginbg.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StyledButton } from 'components/forms/Buttons';
import { apiClient } from 'helpers/http';
import toast from 'react-hot-toast';
import React from 'react';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { showErr } from 'helpers/utils/misc';
import { resetPassword } from 'helpers/http/auth';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${(props) => props.theme.colors.whiteSmoke} url(${bg}) no-repeat
    right top;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem;
  }
`;

function ForgetForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const onBack = () => navigate(-1);
  const [email, setEmail] = useLocalStorage('email', '');
  const [resetPasswordState, setResetPasswordState] = React.useState<
    any | null
  >(null);

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const urlParams = new URLSearchParams(location.search);

  const [screen, setScreen] = React.useState<'forgotPassword' | 'otp'>(
    'forgotPassword'
  );

  const onSubmit = (data: any) => {
    const type = urlParams.get('type') || 'buyer';
    const promise = apiClient
      .post('/auth/forgot-password', {
        type,
        email_id: data.email_id,
      })
      .then((res: any) => {
        if (!res.data.status) {
          throw new Error(res?.data.message);
        }
        return res;
      });
    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        // reset();
        setEmail(data.email_id);
        setScreen('otp');
        return res.data.message;
      },
      error: (err) => {
        return (
          err?.data?.response?.message ||
          err?.toString() ||
          'Error sending password reset link'
        );
      },
    });
  };
  const onResetInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResetPasswordState({
      ...resetPasswordState,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitOtp = (e: React.SyntheticEvent) => {
    const type = urlParams.get('type') || 'buyer';
    e.preventDefault();
    if (!resetPasswordState.otp_code) {
      showErr('Please enter OTP you received on your email');
      return;
    }
    if (!resetPasswordState.new_password) {
      showErr('Please enter new password');
    }
    passwordSchema
      .isValid({
        password: resetPasswordState.new_password,
      })
      .then((valid) => {
        if (!valid) {
          passwordSchema
            .validate({
              password: resetPasswordState.new_password,
            })
            .catch((err) => {
              showErr(err.message);
            });
          return false;
        }

        const promise = resetPassword({
          type,
          email_id: email,
          otp_code: resetPasswordState?.otp_code,
          new_password: resetPasswordState?.new_password,
        });
        toast.promise(promise, {
          loading: 'Loading...',
          success: (res) => {
            reset();
            setEmail('');
            navigate(type === 'seller' ? '/seller/login' : '/login');
            return res.message;
          },
          error: (err) => err.response?.data?.message || err.toString(),
        });
      });
  };

  const { errors } = formState;
  if (screen === 'otp') {
    return (
      <Wrapper>
        <LoginFormContainer onSubmit={onSubmitOtp}>
          <StyledButton
            variant="transparent"
            onClick={() => setScreen('forgotPassword')}
            className="ps-0"
          >
            <Back />
            Back
          </StyledButton>
          <h1 className="fw-700 fs-32">Verify OTP and reset password</h1>
          <p>
            Enter the otp you recieved on you email and update your password.
          </p>

          <Form.Group className="mb-4">
            <Form.Label>Your Email</Form.Label>
            <Input type="text" readOnly disabled value={email} />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>OTP</Form.Label>
            <Input
              type="text"
              placeholder="Enter otp"
              name="otp_code"
              autocomplete="off"
              maxLength={255}
              onChange={onResetInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>New password</Form.Label>
            <Input
              type="password"
              placeholder="Enter your new password"
              name="new_password"
              autocomplete="off"
              maxLength={255}
              onChange={onResetInputChange}
            />
          </Form.Group>

          <div className="text-center">
            <StyledButton
              className="btn btn-block btn-darken mb-3 "
              style={{
                width: '100%',
              }}
              height={51}
              variant="dark"
              type="submit"
            >
              Reset password
            </StyledButton>
          </div>
        </LoginFormContainer>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
        <StyledButton variant="transparent" onClick={onBack} className="ps-0">
          <Back />
          Back
        </StyledButton>
        <h1 className="fw-700 fs-32">Forgot password</h1>
        <p>
          Enter the email address associated with your account and we’ll send
          you a link to reset your password.
        </p>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <Input
                type="text"
                placeholder="Enter your email"
                {...register('email_id')}
                maxLength={255}
              />
              <ErrorMessage>{errors.email_id?.message}</ErrorMessage>
            </Form.Group>
          </Col>
        </Row>
        <div className="text-center">
          <StyledButton
            className="btn btn-block btn-darken mb-3 "
            style={{
              width: '100%',
            }}
            height={51}
            variant="dark"
            type="submit"
          >
            Send OTP
          </StyledButton>
        </div>
        <div className="text-center">
          <Link
            to={
              urlParams.get('type') == 'buyer'
                ? '/register'
                : '/seller/register'
            }
          >
            Don’t have an account? <span className="dotted-link">Register</span>
          </Link>
        </div>
      </LoginFormContainer>
    </Wrapper>
  );
}

export default ForgetForm;
