import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ChevronUp } from 'assets/icons/chevronUp.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg';

import { AccordionHeader, HeaderText } from './styled';

interface Props {
  title: string;
  details: any;
  defaultExpand?: boolean;
}

function AccordionView({ title, details, defaultExpand }: Props) {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const onToggle = useCallback(() => {
    setShowDetails((details) => !details);
  }, []);

  useEffect(() => {
    setShowDetails(Boolean(defaultExpand));
  }, [defaultExpand]);

  return (
    <div>
      <AccordionHeader
        onClick={onToggle}
        style={!showDetails ? { marginBottom: 0 } : {}}
      >
        <HeaderText className="fw-600 fs-sm">{title}</HeaderText>
        {!showDetails ? <ChevronDown /> : <ChevronUp />}
      </AccordionHeader>
      {showDetails && (details ? details : null)}
    </div>
  );
}

export default AccordionView;
