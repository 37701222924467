import { buyerApiClient } from 'helpers/http';

type Id = string;

export const getCart = (payload: Id[]) => {
  return buyerApiClient
    .post(`/checkout/cart`, { user_product_ids: payload })
    .then((res) => res.data);
};

export const getCartPrice = (
  payload: Id[],
  user_address_id?: string,
  coupon_code?: string,
  shipping_method?: string | null
) => {
  const body: any = {
    user_product_ids: payload,
    user_address_id: '',
  };
  if (user_address_id) {
    body.user_address_id = user_address_id;
  }
  if (coupon_code) {
    body.coupon_code = coupon_code;
  }
  if (shipping_method) {
    body.shipping_method = shipping_method;
  }
  return buyerApiClient
    .post(`/checkout/cart/price`, body)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const createCheckoutSession = (
  payload: Id[],
  user_address_id?: string,
  coupon_code?: string,
  shipping_method?: string | null
) => {
  const body: any = {
    user_product_ids: payload,
    user_address_id: '',
  };
  if (user_address_id) {
    body.user_address_id = user_address_id;
  }
  if (coupon_code) {
    body.coupon_code = coupon_code;
  }
  if (shipping_method) {
    body.shipping_method = shipping_method;
  }
  return buyerApiClient
    .post(`/checkout/session/create`, body)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkoutApi = (payload: any) => {
  return buyerApiClient.post('/checkout/payment', payload).then((res) => {
    if (!res.data.status) {
      throw res.data.message;
    }
    return res.data;
  });
};

export const processPayment = (data: any) => {
  return buyerApiClient
    .post(`checkout/process-payment`, data)
    .then((res) => {
      if (!res.data.status) {
        throw res.data.message;
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getShippingMethods = () => {
  return buyerApiClient.get(`/shipping/master`).then((res) => res.data);
};

export const checkPaymentStatusApi = (payload: any) => {
  return buyerApiClient
    .post('/checkout/payment/status', payload)
    .then((res) => {
      if (!res.data.status) {
        throw res.data.message;
      }
      return res.data;
    });
};
