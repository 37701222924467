import { mediaQuery } from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

export const ProductWrapper = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(174, 166, 166, 0.2);
  /* margin: 30px auto; */
  /* width: 270px; */
  max-height: 389px;
  transition: all 0.15s;
  @media ${mediaQuery.mobile} {
    /* max-width: 148px; */
  }
  &:hover {
    box-shadow: 0px 4px 20px rgba(174, 166, 166, 0.4);
    transform: scale(1.025);
  }
  .product-image {
    max-width: 200px;
    width: 100%;
    margin: 0 auto 12px;
    height: 206px;
    object-fit: contain;
    @media ${mediaQuery.mobile} {
      height: 120px;
    }
  }

  .text-brand {
    color: #8e8e8e;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0.5rem;
  }
  .text-product {
    font-weight: 500;
    font-size: 16px;
    height: 55px;
    color: #232f3e;
    line-height: 1.2;
    margin-bottom: 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .text-price-title {
    font-size: 12px;
    color: #101010;
    opacity: 0.6;
    margin-bottom: 0px;
  }
  .text-price {
    font-weight: 800;
    font-size: 18px;
    color: #101010;
    margin-bottom: 0px !important;
  }

  @media (max-width: 767px) {
  }
`;

export const CategoryWrapper = styled.div`
  text-align: center;
  border: 1px solid #dddddd;
  margin: 10px auto;
  padding: 30px 20px;
  border-radius: 16px;
  transition: all 0.25s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 20px rgba(174, 166, 166, 0.2);
    .product-image {
      transform: scale(1.1);
    }
  }
  .product-image {
    max-width: 200px;
    max-height: 100px;
    transition: all 0.25s;
    margin: 0 auto 30px;
  }

  .text-category {
    font-weight: normal;
    font-size: 20px;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
  }
`;

export const BrandWrapper = styled.div`
  position: relative;
  border: 1px solid #dddddd;
  margin: 20px auto;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  .brand-image {
    width: 100%;
    max-width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${mediaQuery.mobile} {
    border-radius: 1rem;
    width: 95%;
  }
`;
