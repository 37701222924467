import React, { useEffect } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from 'helpers/contexts/cart-context';
import { ReactComponent as Cart } from 'assets/icons/cart.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { StyledButton } from 'components/forms/Buttons';
import Search from 'components/forms/Search';
import { HeaderWrapper } from './styled';
import { useBuyerAuth } from 'helpers/contexts/buyer-auth-context';
import { motion } from 'framer-motion';
import { getCategories } from 'helpers/http/common';
import useResponsive from 'helpers/hooks/useResponsive';
import cns from 'classnames';
import useGoToCart from 'helpers/hooks/useGoToCart';
import { useQuery } from 'react-query';
import { getCart } from 'helpers/http/cartApi';
import { showMsg } from 'helpers/utils/misc';

type CategoryType = {
  category_id: number;
  category_name: string;
};

function SiteHeader({
  searchable = false,
  value,
  onChange,
  fetching,
  type,
  autoFocus,
}: {
  searchable?: any;
  value?: string;
  onChange?: React.ChangeEvent<HTMLInputElement> | any;
  fetching?: boolean;
  type?: string;
  autoFocus?: boolean;
}) {
  const CustomToggle = ({ onClick }: any) => (
    <div
      className={`pointer ${isMobile ? 'nav-link mt-1 mb-3' : ''}`}
      onClick={onClick}
    >
      <User />
      &nbsp;Login
    </div>
  );
  const [categories, setCategories] = React.useState<CategoryType[]>([]);

  const auth = useBuyerAuth();
  const location = useLocation();
  const { cart, setCart } = useCart();
  const ids = cart.map((c: any) => c.user_product_id);
  const { data: cartData } = useQuery(['home-cart', ids], () => getCart(ids), {
    enabled: ids.length > 0,
  });

  useEffect(() => {
    if (cartData?.data && location.pathname !== '/checkout') {
      const removedLength = ids.length - cartData?.data?.length;
      if (removedLength > 0) {
        showMsg(
          `${removedLength} product(s) were taken out of the cart because they are out of stock`
        );
      }
      setCart([...cartData.data]);
    }
  }, [cartData]);

  React.useEffect(() => {
    getCategories('?top=1').then((res) => {
      setCategories(res.data);
    });
  }, []);
  const { isMobile, isIpadPro } = useResponsive();
  const goToCart = useGoToCart();
  if (type === 'collection') {
    return (
      <div>
        <HeaderWrapper>
          <Navbar expand="lg">
            <Container className="d-flex justify-content-between">
              <Navbar.Brand>
                <Link to="/">
                  <motion.img
                    src="/logo-black.png"
                    title="breakout la"
                    alt="breakout la"
                    width={100}
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                    }}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="w-100 top-navigation">
                  <div
                    className={cns('d-sm-flex justify-content-between w-100', {
                      'flex-column align-items-center gap-3': isIpadPro,
                    })}
                  >
                    <div className="d-sm-flex flex-1 justify-content-center align-items-center header-link">
                      {categories.map(({ category_name, category_id }) => (
                        <Link
                          to={`/search?ci=${category_id}`}
                          key={category_id}
                        >
                          <motion.a
                            whileHover={{
                              scale: 1.025,
                            }}
                            whileTap={{
                              scale: 0.9,
                            }}
                            role="button"
                            className="nav-link"
                            tabIndex={0}
                          >
                            {category_name}
                          </motion.a>
                        </Link>
                      ))}

                      <div className="d-none d-lg-block">
                        <Search
                          placeholder="Search"
                          containerClassName="searchbox"
                          value={value}
                          onChange={onChange}
                          fetching={fetching}
                          autoFocus={false}
                        />
                      </div>
                    </div>
                    {isMobile ? (
                      <div className="">
                        {auth.user
                          ? !auth.user?.isGuest && (
                              <Link to="/my-account">
                                <a
                                  role="button"
                                  className="nav-link"
                                  tabIndex={0}
                                >
                                  <User />
                                  &nbsp;My Account
                                </a>
                              </Link>
                            )
                          : null}

                        {auth.user ? (
                          <>
                            <Link
                              to="/seller/sell-with-breakout"
                              className="d-block p-2"
                            >
                              <StyledButton
                                height="51"
                                variant="secondary"
                                className={isMobile ? 'w-100' : ''}
                              >
                                Sell
                              </StyledButton>
                            </Link>
                          </>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              Login
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="mb-2">
                              <Dropdown.Item href="/login">
                                <div>Buyer Login</div>
                              </Dropdown.Item>
                              <Dropdown.Item href="/seller/login">
                                <div>Seller Login</div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        <span onClick={goToCart} className="d-block p-2 mr-2">
                          <StyledButton
                            style={{ position: 'relative' }}
                            height="51"
                            variant="secondary"
                            className={isMobile ? 'w-100' : ''}
                          >
                            <Cart /> Cart
                            {cart?.length !== 0 && (
                              <div className="cart-count-badge d-flex justify-content-center align-items-center">
                                {cart?.length}
                              </div>
                            )}
                          </StyledButton>
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span onClick={goToCart}>
                          <StyledButton
                            style={{ position: 'relative' }}
                            height="51"
                            variant="secondary"
                            className="me-3"
                          >
                            <Cart /> Cart
                            {cart?.length !== 0 && (
                              <div className="cart-count-badge d-flex justify-content-center align-items-center">
                                {cart?.length}
                              </div>
                            )}
                          </StyledButton>
                        </span>
                        {auth.user ? (
                          <>
                            {!auth.user?.isGuest && (
                              <Link to="/my-account">
                                <a
                                  role="button"
                                  className="nav-link"
                                  tabIndex={0}
                                >
                                  <User />
                                  &nbsp;My Account
                                </a>
                              </Link>
                            )}
                            <Link to="/seller/sell-with-breakout">
                              <StyledButton height="51" variant="secondary">
                                Sell
                              </StyledButton>
                            </Link>
                          </>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                            >
                              Login
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="/login">
                                <div>Buyer Login</div>
                              </Dropdown.Item>
                              <Dropdown.Item href="/seller/login">
                                <div>Seller Login</div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    )}
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div className="d-block d-lg-none">
            <Link to="/search" state={{ autofocus: true }}>
              <Container>
                <Search
                  placeholder="Search"
                  value={value}
                  onChange={onChange}
                  fetching={fetching}
                  containerClassName="mobile-searchbox mb-4"
                  autoFocus={false}
                />
              </Container>
            </Link>
          </div>
        </HeaderWrapper>
      </div>
    );
  }
  return (
    <div>
      <HeaderWrapper>
        <Navbar expand="lg">
          <Container className="d-flex justify-content-between">
            <Navbar.Brand>
              <Link to="/">
                <motion.img
                  src="/logo-black.png"
                  title="breakout la"
                  alt="breakout la"
                  width={100}
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="w-100 top-navigation">
                <div
                  className={cns('d-sm-flex justify-content-between w-100', {
                    'flex-column align-items-center gap-3': isIpadPro,
                  })}
                >
                  {!searchable ? (
                    <div className="d-sm-flex flex-1 justify-content-center align-items-center header-link">
                      {categories.map(({ category_name, category_id }) => (
                        <Link
                          to={`/search?ci=${category_id}`}
                          key={category_id}
                        >
                          <motion.a
                            whileHover={{
                              scale: 1.025,
                            }}
                            whileTap={{
                              scale: 0.9,
                            }}
                            role="button"
                            className="nav-link"
                            tabIndex={0}
                          >
                            {category_name}
                          </motion.a>
                        </Link>
                      ))}
                      <Link to="/search" state={{ autoFocus: true }}>
                        <div className="d-none d-lg-block">
                          <Search
                            placeholder="Search"
                            containerClassName="searchbox"
                            value={value}
                            onChange={onChange}
                            fetching={fetching}
                          />
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div className="d-none d-lg-block" style={{ flex: 1 }}>
                      <Search
                        placeholder="Search by Name or SKU#"
                        containerClassName="searchbox"
                        value={value}
                        onChange={onChange}
                        fetching={fetching}
                      />
                    </div>
                  )}
                  {isMobile ? (
                    <div className="">
                      {auth.user
                        ? !auth.user?.isGuest && (
                            <Link to="/my-account">
                              <a
                                role="button"
                                className="nav-link"
                                tabIndex={0}
                              >
                                <User />
                                &nbsp;My Account
                              </a>
                            </Link>
                          )
                        : null}

                      {auth.user ? (
                        <>
                          <Link
                            to="/seller/sell-with-breakout"
                            className="d-block p-2"
                          >
                            <StyledButton
                              height="51"
                              variant="secondary"
                              className={isMobile ? 'w-100' : ''}
                            >
                              Sell
                            </StyledButton>
                          </Link>
                        </>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            Login
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="mb-2">
                            <Dropdown.Item href="/login">
                              <div>Buyer Login</div>
                            </Dropdown.Item>
                            <Dropdown.Item href="/seller/login">
                              <div>Seller Login</div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      <span onClick={goToCart} className="d-block p-2 mr-2">
                        <StyledButton
                          style={{ position: 'relative' }}
                          height="51"
                          variant="secondary"
                          className={isMobile ? 'w-100' : ''}
                        >
                          <Cart /> Cart
                          {cart?.length !== 0 && (
                            <div className="cart-count-badge d-flex justify-content-center align-items-center">
                              {cart?.length}
                            </div>
                          )}
                        </StyledButton>
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <span onClick={goToCart}>
                        <StyledButton
                          style={{ position: 'relative' }}
                          height="51"
                          variant="secondary"
                          className="me-3"
                        >
                          <Cart /> Cart
                          {cart?.length !== 0 && (
                            <div className="cart-count-badge d-flex justify-content-center align-items-center">
                              {cart?.length}
                            </div>
                          )}
                        </StyledButton>
                      </span>
                      {auth.user ? (
                        <>
                          {!auth.user?.isGuest && (
                            <Link to="/my-account">
                              <a
                                role="button"
                                className="nav-link"
                                tabIndex={0}
                              >
                                <User />
                                &nbsp;My Account
                              </a>
                            </Link>
                          )}

                          <Link to="/seller/sell-with-breakout">
                            <StyledButton height="51" variant="secondary">
                              Sell
                            </StyledButton>
                          </Link>
                        </>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            Login
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="/login">
                              <div>Buyer Login</div>
                            </Dropdown.Item>
                            <Dropdown.Item href="/seller/login">
                              <div>Seller Login</div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  )}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {searchable ? (
          <div className="d-block d-lg-none">
            <Container>
              <Search
                placeholder="Search"
                value={value}
                onChange={onChange}
                fetching={fetching}
                containerClassName="mobile-searchbox mb-4"
                autoFocus={autoFocus}
              />
            </Container>
          </div>
        ) : (
          <div className="d-block d-lg-none">
            <Link to="/search">
              <Container>
                <Search
                  placeholder="Search"
                  value={value}
                  onChange={onChange}
                  fetching={fetching}
                  containerClassName="mobile-searchbox mb-4"
                />
              </Container>
            </Link>
          </div>
        )}
      </HeaderWrapper>
    </div>
  );
}

export default SiteHeader;
