import React from 'react';

import {
  Button,
  Image,
  NavLink,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from 'helpers/contexts/auth-context';

import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as List } from 'assets/icons/listing.svg';
import { ReactComponent as Transactions } from 'assets/icons/transactions.svg';
import { ReactComponent as Setting } from 'assets/icons/setting.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Help } from 'assets/icons/message-question.svg';
import { useDraft } from 'helpers/contexts/draft-context';
import useResponsive from 'helpers/hooks/useResponsive';
import Hamburger from 'components/ui/hamburger';

const SidebarWrapper = styled.div`
  .HamburgerToggle {
    position: absolute;
    transform: scale(0.75);
    right: 1rem;
  }
  .minimizeSidebar {
    width: var(--minimizeNavWidth) !important;
    .toggle {
      transform: rotate(180deg);
      background-color: transparent !important;
    }
  }
  .sidenav {
    margin: 10px;
    border-radius: 1rem;
    background: black;
    color: white;
    transition: all 0.2s ease;
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    height: calc(100% - 20px);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* svg {
      fill: currentColor;
      width: 30px;
      height: 20px;
    } */
  }
  .searchMini {
    color: var(--textTinted);
    background-color: var(--searchBg) !important;
  }
  .search {
    position: relative;
    color: var(--textTinted);
    input {
      background-color: var(--searchBg);
      color: var(--text);
      border-radius: 4px;
      outline: 0;
      padding: 0.525rem 0.745rem;
      padding-left: 2.4rem;
      border: 0;
      width: 100%;
    }
    svg {
      position: absolute;
      left: 10px;
      top: 8px;
      width: 22px;
      height: 22px;
    }
  }

  .sidenavInner {
    padding-left: 0;
    list-style: none;
    flex: 1;
    li > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.6rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      color: #c1c9d2;
      svg {
        stroke: #c1c9d2;
        margin-right: 0.8rem;
      }

      &.active,
      &:hover {
        background-color: #e7e7e7;
        color: #292d32;
        svg {
          stroke: #292d32;
        }
      }
      &.link {
        justify-content: space-between;
      }
      &.minimized {
        svg {
          margin-right: 0;
        }
      }
      .icon {
        margin-right: 10px;
      }
    }
  }
`;
const BackButton = styled(Button)`
  width: 38px;
  height: 38px;
  background-color: #3e3e3e;
  border: none;
  box-shadow: 0;
  outline: 0;
  position: absolute;
  right: 0;
  top: 30px;
  border-radius: 8px 0px 0px 8px;
  &:hover,
  &:focus {
    background-color: #3e3e3e;
  }
`;

const CountTag = styled.div`
  background-color: ${(props) => props.theme.colors.body};
  padding: 0.25rem 0.5rem;
  border-radius: 49px;
  color: ${(props) => props.theme.colors.black};
`;

const LINKS = [
  { title: 'Dashboard', toLink: '/seller/dashboard', icon: <Dashboard /> },
  { title: 'Listings', toLink: '/seller/listing', icon: <List /> },
  {
    title: 'Transactions',
    toLink: '/seller/transactions',
    icon: <Transactions />,
  },
  { title: 'Settings', toLink: '/seller/settings', icon: <Setting /> },
  { title: 'Help', toLink: '/seller/faq', icon: <Help /> },
];

function SideBar({ show, toggle, toggleMobileMenu }: any) {
  const { isMobile } = useResponsive();
  const { signout, user } = useAuth();
  const { listingState } = useDraft();
  const handleCustomize = () => {
    return listingState.total;
  };
  return (
    <SidebarWrapper>
      <aside
        className={`sidenav  p-3`}
        style={{
          width: show || isMobile ? '250px' : '76px',
        }}
      >
        <div className="app-brand d-flex justify-content-between align-items-center mb-4 mt-1">
          {show ? (
            <NavLink>
              <Image src="/logo-white.png" width="78px" fluid />
            </NavLink>
          ) : (
            <div style={{ paddingTop: 70 }} />
          )}

          <BackButton onClick={isMobile ? toggleMobileMenu : toggle}>
            <span
              className="d-block"
              style={{
                transform: !show ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              <Chevron stroke="white" />
            </span>
          </BackButton>
        </div>

        <ul className={'sidenavInner'}>
          {LINKS.map((alink) => (
            <CustomNavLink
              {...alink}
              key={alink.title}
              isMinimized={!show}
              active={window.location.pathname.includes(alink.toLink)}
              getCustomize={handleCustomize}
            />
          ))}
        </ul>

        <div
          className={`${'footer'} d-flex align-items-center justify-content-between`}
        >
          {show && (
            <div className="d-flex">
              <div>
                <Image src="/images/avatar1.png" width={44} />
              </div>
              <div className="flex-1 ms-2">
                <h6 className="mb-0 text-capitalize">{user?.name}</h6>
                <span className="text-muted text-capitalize">{user?.type}</span>
              </div>
            </div>
          )}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-logout`}>Log out</Tooltip>}
          >
            <Button title="Logout" variant="link" onClick={signout}>
              <Logout stroke="white" />
            </Button>
          </OverlayTrigger>
        </div>
      </aside>
    </SidebarWrapper>
  );
}

export default SideBar;

function CustomNavLink(props: {
  title: string;
  icon?: React.ReactNode;
  active: boolean;
  isMinimized: boolean;
  toLink: string;
  disable?: boolean;
  customizeKey?: string;
  getCustomize: (key: string) => any;
}) {
  let count;
  if (props.customizeKey) {
    count = props.getCustomize(props.customizeKey);
  }
  return (
    <li key={props.title}>
      <NavLink
        disabled={props.disable}
        as={Link}
        to={props.toLink}
        className={`${'link'} ${
          props.active && !props.disable ? 'active' : ''
        } ${props.isMinimized ? 'minimized' : ''}`}
      >
        <div>
          {props.icon}
          {!props.isMinimized && <span>{props.title}</span>}
        </div>
        {count && <CountTag>{count}</CountTag>}
      </NavLink>
    </li>
  );
}
