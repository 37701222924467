import moment from 'moment';
import toast from 'react-hot-toast';

export const pxToRem = (px: number) => `${px / 16}rem`;
export const getImageUrl = (
  data: string,
  type: 'single' | 'multi'
): string | string[] => {
  const parsed = JSON.parse(data);
  if (type === 'single') {
    return parsed[0];
  }
  return parsed;
};

export const showMsg = (msg: string) => {
  toast.dismiss();
  toast.success(msg);
};
export const showErr = (msg: string) => {
  toast.dismiss();
  toast.error(msg);
};

export const asyncToast = ({
  promise,
  loading,
  success,
}: {
  promise: any;
  loading: string;
  success: string;
}) => {
  toast.promise(promise, {
    loading,
    success,
    error: (error) => error.toString() || 'Internal error, try later.',
  });
};

export const limitDecimals = (number: number | undefined, decimal = 2) => {
  if (!number) return 0;
  if (Number.isInteger(number)) return numberWithCommas(number);
  return numberWithCommas(Number(number).toFixed(decimal));
};

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatLocalDate(date: string, format?: string) {
  return moment
    .utc(date)
    .local()
    .format(format || 'Do MMM YYYY hh:mm a');
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const formattedNumber = phoneNumberString
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return formattedNumber;
};

/**
 * getYupErrors will format errors to simple object
 * from yup error inner object.
 */
export const getYupErrors = (error: any) => {
  const errors = error.inner.reduce((acc: any, error: any) => {
    return {
      ...acc,
      [error.path]: error.message,
    };
  }, {});
  return errors;
};

/**
 * Maps status with label.
 */
export const statusTitleMapper: { [key: string]: string } = {
  processing: 'Shipping Pending',
  fulfilled: 'Order is fulfilled',
};

/**
 * This will return label related to given status.
 * If not found then status itself will be return.
 * @param status string
 * @returns status label
 */
export function getStatusTitle(status: string) {
  return statusTitleMapper[status] || status;
}

export const transformAddress = (addressStr: string) => {
  if (typeof addressStr !== 'string') return {};
  const addressArr = addressStr.split(',');
  if (addressArr.length > 2) {
    const [, state, city, address_2, address_1] = addressArr.reverse();
    return {
      state: state ? state.trim() : state,
      city: city ? city.trim() : city,
      address_1,
      address_2,
    };
  }
  return {
    state: '',
    city: '',
    address: addressStr,
  };
};

export const placesToAddress = (places: any): any => {
  if (!places) return {};
  const { address_components } = places;

  const city = address_components.find((component: any) => {
    return component.types.includes('locality');
  })?.long_name;
  const street_number = address_components.find((component: any) => {
    return component.types.includes('street_number');
  })?.long_name;
  const route = address_components.find((component: any) => {
    return component.types.includes('route');
  })?.long_name;
  const neighborhood = address_components.find((component: any) => {
    return component.types.includes('neighborhood');
  })?.long_name;
  const sublocality = address_components.find((component: any) => {
    return component.types.includes('sublocality');
  })?.long_name;
  const administrative_area_level_2 = address_components.find(
    (component: any) => {
      return component.types.includes('administrative_area_level_2');
    }
  )?.short_name;
  const state = address_components.find((component: any) => {
    return component.types.includes('administrative_area_level_1');
  })?.short_name;
  const postal_code = address_components.find((component: any) => {
    return component.types.includes('postal_code');
  })?.short_name;
  const country = address_components.find((component: any) => {
    return component.types.includes('country');
  })?.short_name;

  return {
    state: state,
    city: city,
    address_1: `${street_number || ''} ${route || ''}`,
    address_2: neighborhood || sublocality || administrative_area_level_2,
    postal_code,
    country: country,
  };
};

// -----
export const getListingDate = (listing: any) => {
  const dateKey: any = {
    active: ['Approval date', 'approve_date'],
    pending: ['Created date', 'date_created'],
    removed: ['Removed date', 'removal_date'],
    sold: ['Sold date', 'date_sold'],
  };
  const item = dateKey[listing.status];
  const date = listing[item[1]];
  if (!item) return null;
  return {
    label: item[0],
    date: formatLocalDate(date),
  };
};

// search params to object
export function getParamsAsObject(search: string) {
  if (!search) return {};
  const params = new URLSearchParams(search);
  return Object.fromEntries(params.entries());
}
