import * as yup from 'yup';


/**
 * Yup schema to validate address details form
 */
export const validateCompleteFormSchema = yup.object({
  address_1: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  zipcode: yup.string().required("Zipcode is required"),
  // check_name: yup.string().required("Check name is required"),
})
.required();

const phoneRegExp =
  /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


/**
 * Yup schema to validate profile details form
 */
export const validateEditProfileSchema = yup
  .object({
    name: yup.string().required('Name is required'),
    email_id: yup.string().required('Email id is required'),
    phone_number: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Please enter a valid 10 digit phone number.'),
  })
  .required();
  
