import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import NotFound from 'components/ui/NotFoundView';
import SiteHeader from 'components/header/Header';
import PaginationComponent from 'components/ui/Pagination';
import SiteFooter from 'components/footer/Footer';
import ProductBox from 'components/general/ProductBox';
import FilterView from './FilterView';
import { Wrapper } from './search.styled';
// import { searchProducts } from 'helpers/http/product';
import { ProductType } from 'pages/default-products/product.type';
import AppliedFilterView from './AppliedFilterView';
import { DEFAULT_FILTER } from './filter.type';
import useBrandsQuery from 'helpers/hooks/useBrands';
import useCategoriesQuery from 'helpers/hooks/useCategories';
import 'react-multi-carousel/lib/styles.css';
// import useLocalStorage from 'helpers/hooks/useLocalStorage';
import useSearch, {
  setFilters,
  setPageNo,
  setSearchText,
  clearFilter,
} from './useSearch';
import { useLocation } from 'react-router-dom';

function Search() {
  const { productFilters, dispatch, productData, debouncedSearch } =
    useSearch();
  const location = useLocation();
  const { brands } = useBrandsQuery();
  const { categories } = useCategoriesQuery();

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(dispatch, e.target.value);

  const handleClearFilter = () => clearFilter(dispatch);
  const handleFilterChange = (updatedFilters: typeof DEFAULT_FILTER) => {
    setFilters(dispatch, updatedFilters);
  };
  return (
    <div>
      <Wrapper>
        <SiteHeader
          // fetching={productData?.isLoading}
          value={productFilters.search}
          onChange={onSearch}
          searchable={true}
          // autoFocus={false}
          autoFocus={location.search.length === 0 ? true : false}
        />
        <div className="search-details d-flex align-items-center justify-content-center text-center">
          {productData?.isLoading ? (
            <div className="d-flex align-items-center mt-4 mb-4">
              <Spinner animation="border" />
              &nbsp;&nbsp;Searching...
            </div>
          ) : (
            !!debouncedSearch && (
              <div className='mt-4'>
                <h3>Search results for ‘{debouncedSearch}’</h3>
                <p className="fs-20 fw-400">
                  {productData?.total?.toLocaleString('en-US')} result(s) found
                </p>
              </div>
            )
          )}
        </div>
        <Container>
          <Row>
            <Col md={3} xs={12}>
              <FilterView
                filters={productFilters.filters}
                refetchProducts={handleFilterChange}
                brands={brands}
                categories={categories}
                availableSizes={productData?.availableSizes}
              />
            </Col>
            <Col md={9} xs={12}>
              {brands ? (
                <AppliedFilterView
                  filters={{
                    brandId: productFilters.filters?.brandId,
                    categoryId: productFilters.filters?.categoryId,
                    gender: productFilters.filters?.gender,
                    sizes: productFilters.filters?.sizes,
                    conditions: productFilters.filters?.conditions,
                  }}
                  onClear={handleClearFilter}
                  onFilterChange={handleFilterChange}
                  brands={brands}
                  categories={categories}
                />
              ) : null}
              <div className="products-list row">
                {productData?.data?.length > 0 ? (
                  productData?.data?.map((item: ProductType, index: number) => (
                    <Col key={index} lg="4" xl="3" xs="6">
                      <ProductBox
                        productItem={item}
                        containerClassName="product-item"
                      />
                    </Col>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center w-100 no-data-found">
                    {!productData.isLoading && (
                      <NotFound
                        title={'No products found'}
                        containerMargin="2.5rem 0 1rem 0"
                      />
                    )}
                  </div>
                )}
              </div>
              {productData.totalPages > 0 && (
                <PaginationComponent
                  total={productData.totalPages}
                  onPageChange={(page) => {
                    setPageNo(dispatch, page.selected + 1);
                  }}
                  currentPage={productFilters.pageno}
                  className="mt-3"
                />
              )}
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <SiteFooter />
    </div>
  );
}

export default Search;
