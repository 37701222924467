import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SiteHeader from './header/Header';
import SiteFooter from './footer/Footer';
import { StyledButton } from './forms/Buttons';

const Wrapper = styled.div`
  height: 100vh;
  .pageTitle {
    font-size: 3.25rem;
    margin-top: 2.5rem;
  }
  .notFoundText {
    opacity: 0.9;
    margin-bottom: 0.938rem;
    margin-top: 1.25rem;
  }
  .notFoundDescription {
    color: ${(props) => props.theme.colors.gray4};
    margin-bottom: 2.25rem;
  }
`;

const MainPageWrapper = styled.div`
  min-height: 70vh;
  padding: 1rem;
`;

function NotFoundView({ isMainPage }: { isMainPage?: boolean }): JSX.Element {
  const navigate = useNavigate();
  const path = isMainPage ? -2 : -1;
  return (
    <Wrapper className="d-flex justify-content-center align-items-center flex-column">
      <Image
        src="/images/notFound.png"
        width="275px"
        height="200px"
        fluid
        alt="not found image"
      />

      <div className="fw-bold pageTitle">404</div>
      <h1 className="fw-bold fs-32 notFoundText">Page not found!</h1>
      <div className="fw-500 fs-1rem text-center notFoundDescription">
        The page you are looking for doesn’t exist or is unavailable.
      </div>
      <StyledButton variant="dark" type="submit" onClick={() => navigate(path)}>
        Go back
      </StyledButton>
    </Wrapper>
  );
}

function Page404({ isMainPage }: { isMainPage?: boolean }): JSX.Element {
  if (isMainPage) {
    return (
      <>
        <MainPageWrapper>
          <SiteHeader />
          <NotFoundView isMainPage={isMainPage} />
        </MainPageWrapper>
        <SiteFooter />
      </>
    );
  }
  return <NotFoundView />;
}

export default Page404;
