import { getCategories } from 'helpers/http/common';
import { useQuery } from 'react-query';

export type Category = {
  category_id: number;
  category_name: string;
};
export type Categories = Array<Category>;

const useCategoriesQuery = () => {
  const { data } = useQuery<Categories>('categories', () =>
    getCategories().then((res) => res.data)
  );
  return { categories: data || [] };
};

export default useCategoriesQuery;
