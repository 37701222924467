import { StyledButton } from 'components/forms/Buttons';
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  min-height: 100vh;
  h1.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.75rem;
  }
  h2.heading {
    font-size: 2rem;
    font-weight: 700;
  }
  li {
    margin-bottom: 0.75rem;
  }
`;

function ContractForm({ onNext, onBack }: any) {
  const [agree, setAgree] = React.useState(false);
  const toggleAgree = () => setAgree(!agree);
  return (
    <Wrapper>
      {onBack ? (
        <StyledButton variant="transparent" onClick={onBack} className="ps-0 mt-3">
          <Back />
          Back
        </StyledButton>
      ) : null}

      <div className="mt-4">
        <h1 className="title">Seller contract</h1>
        <ul className="text-left">
          <li>
            Consignor is responsible for all shipping costs to get the items to
            & from Breakout.
          </li>
          <li>
            We help you determine your sell prices in accordance with reasonable
            market values using our experience and extensive data.
          </li>
          <li>
            Once item(s) have been submitted , Breakout has all rights to
            approve , decline or modify submitted listing.
          </li>
          <li>
            Breakout has all rights to recall for additional "Authentication,
            Approved & listed" Item(s) after we have "Approved & Listed"
          </li>
          <li>
            Breakout has all rights to contact seller / consignor to redeem or
            pick up unsold merchandise item(s).
          </li>
          <li className="fw-bold">
            The consignment rate will be 85/15. 85% of the sale goes to the
            consignor , 15% goes to BreakoutLA or $30, whichever is greater.
          </li>
          <li>
            All item(s) currently listed & on moving forward item(s) as of
            06/30/2022 (June 30, 2022) will be transitioned into the{' '}
            <u>
              new consignment rate of 15% consignment fee or $30, whichever is
              greater.
            </u>
          </li>
          <li>
            Consignor will pay a $20 fee for <u className="fw-bold">each</u>{' '}
            item being removed / pulled out of our stock/inventory before 15
            calendar days after being listed.
          </li>
          <li>
            Items must be supplied clean and ready to be displayed to avoid a
            cleaning fee.
          </li>
          <li>
            Once Breakout has possession of the Consignors item(s) , there will
            be a authentication verification process before item becomes
            available for sell.
          </li>
          <li>
            <u className="fw-bold">
              BREAKOUT HAS THE RIGHT TO REFUSE ANY ITEMS OR DECLINE ANY SERVICE.
            </u>
          </li>
          <li>
            If “Listed Price” isn't conforming with current market value , we
            will contact the consignor to allow Breakout Los Angeles to adjust
            the price.
          </li>
        </ul>
      </div>
      {!!onNext && (
        <div className="action-area d-flex justify-content-between align-items-center my-4">
          <div className="d-flex gap-2">
            <Form.Group className="mb-3" controlId="contractCheckbox">
              <Form.Check
                type="checkbox"
                label="I Agree."
                onChange={toggleAgree}
                checked={agree}
              />
            </Form.Group>
          </div>
          <div>
            <StyledButton variant="dark" onClick={onNext} disabled={!agree}>
              Next <Chevron stroke="currentColor" className="flip-x ms-2" />
            </StyledButton>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default ContractForm;
