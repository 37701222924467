// /admin/dashboard-stats

import { apiClient } from './index';
import { ContactUsFormType } from '../../pages/ContactUs';

export const dashboardStats = () => {
  return apiClient.get('/admin/dashboard-stats').then((res) => res.data);
};

export const generateAwsUrl = (data: {
  folder: string;
  file_name: string;
  content_type: string;
}) => {
  return apiClient.post('/general/image/upload', data).then((r) => r.data);
};

export const getBanners = () => {
  return apiClient
    .get('/general/home/banners')
    .then((res) => {
      if (res.data.status) {
        return res.data?.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getBrands = (subUrl?: string) => {
  let url = '/general/brands';
  if (subUrl) {
    url = `${url}${subUrl}`;
  }
  return apiClient
    .get(url)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCategories = (subUrl?: string) => {
  let url = '/product/types';
  if (subUrl) {
    url = `${url}${subUrl}`;
  }
  return apiClient
    .get(url)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getStates = () => {
  return apiClient
    .get('/general/country?countryCode=US')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const saveContactForm = (data: ContactUsFormType) => {
  return apiClient
    .post(`/general/support-request`, data)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getTracking = ({
  orderId,
  trackingId,
}: {
  orderId: string | undefined;
  trackingId: string | undefined;
}) => {
  if (!orderId || !trackingId) {
    return false;
  }
  return apiClient
    .post('/shipping/track', {
      user_order_id: orderId,
      tracking_number: trackingId,
    })
    .then((res) => {
      if (!res.data?.status) {
        throw new Error(res.data?.message);
      }
      return res.data?.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const appSettings = () => {
  return apiClient
    .get('/general/app-settings')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const viewGuestOrder = (payload: {
  order_no: string;
  email_id: string;
}) => {
  return apiClient
    .post('/buyer/view-guest-order', payload)
    .then((res) => res.data);
};
