import { mediaQuery } from 'helpers/hooks/useResponsive';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  min-height: 70vh;
  .home-category--title {
    svg {
      transition: all 0.25s;
    }
    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }
  .home-banner {
    min-height: 550px;
    background: #0d0d0d;
    border-radius: 20px;
    display: flex;
    align-items: center;

    /** sale Banner style */
    background-color: transparent;
    position: relative;
    padding: 0;
    align-items: end;
    justify-content: center;
    .temp-banner {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      bottom: 0;
      height: 100%;
      padding: 0;
      border-radius: 20px;
      object-fit: cover;
    }
    @media ${mediaQuery.tablet} {
      min-height: 450px;
    }
    @media ${mediaQuery.largeDesktop} {
      min-height: 650px;
    }
    @media ${mediaQuery.superLargeDesktop} {
      min-height: 700px;
    }
    @media ${mediaQuery.ultraLargeDesktop} {
      min-height: 1150px;
    }
    /** sale Banner */

    @media ${mediaQuery.mobile} {
      // min-height: auto;
      min-height: 300px; //just for statick sale Banner
      .temp-banner {
        //just for statick sale Banner
        object-fit: cover;
      }
    }
    .banner-slide {
      position: relative;
    }

    .display-center {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;

      /** sale Banner style */
      z-index: 2;
      flex: 1;
      /** sale Banner style */
    }

    .text-sub-title {
      /* color: #232f3e; */
      color: white;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      opacity: 0.6;
      margin-bottom: 20px;
    }

    .text-title {
      font-weight: bold;
      font-size: 52px;
      line-height: 62px;
      color: white;
      margin-bottom: 40px;
    }

    .banner-image {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      position: relative;
      /* new  */
      /* box-shadow: 30px 20px 50px rgba(0, 0, 0, 52%); */
      border-radius: 5rem;
      display: block;
      @media ${mediaQuery.tablet} {
        max-width: 80%;
      }
      @media ${mediaQuery.mobile} {
        max-width: 215px;
      }
    }

    .overlay {
      background: linear-gradient(
        153.66deg,
        rgba(255, 255, 255, 0.078) 27.36%,
        rgba(255, 255, 255, 0.78) 88.54%
      );
      width: 500px;
      height: 500px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .shop-now {
      svg {
        margin-left: 0.5rem;
        width: 1rem;
        height: 1.25rem;
      }
      path {
        stroke: black;
        /* stroke-width: 2.5; */
      }
    }
  }

  @media (max-width: 767px) {
    .home-banner {
      .text-title {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 25px;
      }

      .overlay {
        width: 300px;
        height: 300px;
      }
    }
  }
`;

export const Content = styled(Container)`
  max-width: 970px;
`;

export const Section = styled.div`
  position: relative;
  margin: 80px 0 100px;

  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;

    svg {
      margin-left: 10px;
    }
  }

  .carousel-button-group {
    position: absolute;
    top: 5px;
    right: 20px;

    .btn-slide {
      background: #ffffff;
      border: none;
      transition: all 0.25s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  @media (max-width: 767px) {
    margin: 20px 0 40px;

    h3 {
      font-size: 24px;
      line-height: 34px;
    }
    .carousel-button-group {
      right: 0px;
      top: 3px;
    }
  }

  .home-category--slider {
    .home-category--slider-item {
      margin: 1rem;
    }
  }
`;
