import styled from 'styled-components';

export const SellerSearchWrapper = styled.div`
  .search-head {
    display: flex;
    gap: 15px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    justify-content: center;
    .search-container {
      flex: 1;
      max-width: 586px;
    }
  }
  .product-table {
    td {
      vertical-align: middle;
    }
    tr,
    td {
      border: none;
    }
    tr:hover {
      background: #f8f8f8;
      border-radius: 16px;
    }
  }
  .message-content {
    max-width: 526px;
    margin: 1rem auto;
    p {
      font-size: ${18 / 16}rem;
      line-height: 1.5;
    }
  }
`;
