import React from 'react';
import styled from 'styled-components';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarWrapper = styled(Navbar)`
  position: fixed;
  width: 100%;
  background-color: #f5f5f5;
  z-index: 999;
`;

const Wrapper = styled.div`
  & {
    width: 30px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  & span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  & span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  & span:nth-child(2) {
    top: 11px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  & span:nth-child(3) {
    top: 22px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 4px;
    left: 8px;
  }

  &.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px;
    left: 8px;
  }
`;
const StyledMenu = styled.div`
  background-color: #3e3e3e;
`;

function Hamburger({ open, onToggle }: any) {
  return (
    <NavbarWrapper expand="lg">
      <Container className="d-flex justify-content-between">
        <Navbar.Brand>
          <Link to="/">
            <img
              src="/logo-black.png"
              title="breakout la"
              alt="breakout la"
              width={100}
            />
          </Link>
        </Navbar.Brand>
        <StyledMenu className="HamburgerToggle">
          <Wrapper className={`${open ? 'open' : ''}`} onClick={onToggle}>
            <span></span>
            <span></span>
            <span></span>
          </Wrapper>
        </StyledMenu>
      </Container>
    </NavbarWrapper>
  );
}

export default Hamburger;
