import { mediaQuery } from 'helpers/hooks/useResponsive';
import { createGlobalStyle } from 'styled-components';
import { myTheme } from './theme';
export const MyGlobalStyle = createGlobalStyle`
:root{
  @media ${mediaQuery.mobile} {
        font-size: 14px;
    }
  }
  body {
    margin: 0;
    font-family: ${myTheme.font.primary};
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  .form-check-input:checked{
    background-color:#292D32;
  }
  .btn.btn-link{
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    color: #283EFF;
    &:hover{
      transform: scale(1.02s); 
    }
  }
  button.btn:not(.btn-link, .btn-transparent) {
    transition: all 0.15s ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
      transform: scale(1.035); 
    }
  }
  .heading-sm{
    color: #0D0D0D;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5;
  }
  .modal-open  .App{ 
    filter: blur(2.5px);
  }
  .delete-listing-modal{
    .modal-dialog{
      min-width: 500px;
    }
    .modal-body{
      padding: 2rem;
    }
    .promptTitle{
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.75rem;
    }
    .promptMsg{
      text-align: left!important;
    }
  }
  .pointer{
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #CACACA;
  }

  .btn.btn-secondary{
    background-color: #E7E7E7;
    color: #000000;
    border: none;
  }
  .stripeEleClass{
    background-color: red;
  }
  .animation-link1{
    transition: all .15s ease-in-out ;
    &:hover{
      transform: scale(1.05) translateX(5px);
      text-shadow: 0px 0px 10px currentColor;
    }
  }
  .animate-w-shadow {
    &:hover {
      box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
      transform: translateY(-2px);
      transition: all 0.2s ease;
      cursor: pointer;
    }
  }
    .table-responsive{
    tbody {
      tr {
        transition: all .25s;
        &:hover {
          border-top: 0 !important;
          background-color: rgba(0,0,0, 0.04);
        }
      }
    }
  }
  .table-responsive{
    thead th{
      @media ${mediaQuery.mobile} {
        font-size: 0.857rem;
        font-weight: 500;
      }
    }
  }
  /* input type number  */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .pac-container {
    z-index: 10000 !important;
  }
`;
