import { mediaQuery } from 'helpers/hooks/useResponsive';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid #DDDDDD;
  .navbar-nav .nav-link {
    font-weight: 400;
    font-size: 1rem;
    margin: 0 0.75rem;
    color: #000;
    @media ${mediaQuery.mobile} {
      text-align: center;
    }
  }
  .top-navigation{
    
  }
  .cart-count-badge {
    background-color: ${(props) => props.theme.statusColors.green.color};
    color: ${(props) => props.theme.colors.body};
    position: absolute;
    top: -0.8125rem;
    right: -0.8125rem;
    border-radius: 100%;
    height: 1.625rem;
    width: 1.625rem;
    font-size: 0.75rem;
  }
  .searchbox {
    width: 98%;
    max-width: none;
    box-shadow: none;
    border: 1px solid #dddddd;
    input {
      height: 44px;
    }
  }
  .mobile-searchbox {
    width: 100%;
    max-width: none;
    box-shadow: none;
    border: 1px solid #dddddd;
    input {
      height: 44px;
    }
  }
  .search-link-view {
    border: 1px solid #dddddd;
    padding: 12px;
    border-radius: 8px;
    span {
      margin-left: 0.875rem;
    }
    @media (max-width: 992px) {
      margin-left: 0.3rem;
    }
  }
  .header-link {
    @media (max-width: 992px) {
      flex-wrap: wrap !important;
      justify-content: flex-start !important;
    }
  }
`;
