import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProductWrapper } from './styled';
import { getImageUrl } from 'helpers/utils/misc';
import { ProductType } from 'pages/default-products/product.type';

function ProductBox({
  containerClassName,
  productItem,
}: {
  containerClassName?: string;
  productItem?: ProductType;
}) {
  return (
    <Link to={`/product/${productItem?.product_catalog_id}`}>
      <ProductWrapper className={containerClassName}>
        <div className="text-center">
          <Image
            src={
              getImageUrl(
                productItem?.catalog_images || '',
                'single'
              ) as string as string
            }
            alt="product item"
            className="product-image"
          />
        </div>
        <p className="text-brand">{productItem?.brand_name}</p>
        {!!productItem?.catalog_name && (
          <p className="text-product" title={productItem?.catalog_name}>
            {productItem?.catalog_name}
            {/* {productItem?.catalog_name?.length > 56
              ? `${productItem?.catalog_name?.substring(0, 56)}...`
              : productItem?.catalog_name} */}
          </p>
        )}
        {productItem?.sell_price ? (
          <>
            <p className="text-price-title">Starting from</p>
            <p className="text-price">${productItem?.sell_price}</p>
          </>
        ) : (
          <p className="text-price">Out of stock</p>
        )}
      </ProductWrapper>
    </Link>
  );
}
export default ProductBox;
