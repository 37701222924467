import Search from 'components/forms/Search';
import React from 'react';
import { SellerSearchWrapper } from './styled';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { StyledButton } from 'components/forms/Buttons';
import SiteHeader from 'components/header/Header';
import { Container, Image, Table } from 'react-bootstrap';
import { getDefaultListing } from 'helpers/http/product';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { getImageUrl } from 'helpers/utils/misc';
import useDebounce from 'helpers/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import FilterPopup from './FilterPopup';
import { mediaQuery } from 'helpers/hooks/useResponsive';

type Listing = {
  brand_id: number;
  brand_name: string;
  catalog_images: string;
  catalog_name: string;
  category_id: number;
  category_name: string;
  default_price: string;
  gender: string;
  product_catalog_id: string;
  sku: string;
};

const Product = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  img {
    width: 70px;
    height: 90px;
    border-radius: 6px;
    object-fit: contain;
    margin-right: 10px;
  }
  @media ${mediaQuery.mobile} {
    flex-direction: column;
    gap: 0;
    img {
      margin-right: 10px;
    }
  }
`;

const defaultFilters = {
  categoryId: [],
  brandId: [],
  gender: ['men', 'women', 'kid'],
};

function SellerSearch() {
  const navigate = useNavigate();
  const [listing, setListing] = React.useState<Listing[] | null>(null);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const [isFetching, setIsFetching] = React.useState(false);
  const [filterDialog, setFilterDialog] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState(defaultFilters);

  const fetchListing = React.useCallback(
    (search?: string, filterParams: any = filters) => {
      setIsFetching(true);
      getDefaultListing(search || '', filterParams)
        .then((res) => {
          setListing(res.data);
          setIsFetching(false);
        })
        .catch(() => setIsFetching(false));
    },
    [filters]
  );

  React.useEffect(() => {
    fetchListing(debouncedSearchKeyword);
  }, [debouncedSearchKeyword, fetchListing]);

  function handleSearch(e: any) {
    setSearchKeyword(e.target.value);
  }

  const goToAddLising = (listing: any) => {
    navigate('/seller/add-listing', { state: { selectedProduct: listing } });
  };

  const toggleFilterDialog = React.useCallback(() => {
    setFilterDialog((dialog: boolean) => !dialog);
  }, []);

  const handleFilter = (filters: any) => {
    toggleFilterDialog();
    // setPage(1);
    setFilters(filters);
    fetchListing('', filters);
  };

  return (
    <>
      <SiteHeader />
      <SellerSearchWrapper>
        <Container style={{ maxWidth: 1000 }}>
          <h2 className="mt-5 fs-24 fw-700 text-center">
            Search the product you want to sell
          </h2>

          <header className="search-head">
            <Search
              containerClassName="search-container"
              placeholder="Search by name, SKU, type"
              onChange={handleSearch}
              value={searchKeyword}
              fetching={isFetching}
            />
            <StyledButton
              onClick={toggleFilterDialog}
              variant="transparent"
              shadow={1}
            >
              <FilterIcon />
              <span className="ml-2">Filters</span>
            </StyledButton>
          </header>
          <div className="content mt-5">
            {listing === null && <Loader />}
            <Table className="product-table" responsive>
              <tbody>
                {!!listing?.length &&
                  listing.map((listing) => (
                    <tr
                      key={listing.product_catalog_id}
                      className="listing-item pointer"
                      onClick={() => goToAddLising(listing)}
                    >
                      <td>
                        <Product className="ps-4">
                          <Image
                            src={getImageUrl(listing.catalog_images , 'single') as string}
                          />
                          <div>
                            <div className="text-muted fs-14">
                              {listing.brand_name}{' '}
                            </div>
                            <div>{listing.catalog_name}</div>
                          </div>
                        </Product>
                      </td>
                      <td>
                        <div>
                          <div className="text-muted fs-14">SKU</div>
                          <div>{listing.sku}</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-muted fs-14">Type</div>
                          <div>{listing.category_name}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {!!listing && listing.length === 0 ? <Message /> : null}
          </div>
        </Container>
        <FilterPopup
          show={filterDialog}
          onClose={toggleFilterDialog}
          onFilter={handleFilter}
        />
      </SellerSearchWrapper>
    </>
  );
}

const Message = () => {
  return (
    <div className="text-center message-content">
      <p>
        Item you're looking to list but not popping up? <br /> <br />
        No worries! We are working on getting every potential item in the
        marketplace in our data base. <br /> <br />
        Please , continue to list the item as usual and we will handle the rest.
      </p>
      <StyledButton variant="dark" shadow={1} className="mt-4">
        Continue with custom item
      </StyledButton>
    </div>
  );
};
export default SellerSearch;
