import { Container, Navbar, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FooterWrapper, SocialIconButton } from './styled';
import { motion } from 'framer-motion';
import useResponsive from 'helpers/hooks/useResponsive';
import cns from 'classnames';

const socialLinks = [
  {
    name: 'Instagram',
    path: 'https://www.instagram.com/breakout.la',
    icon: '/images/insta.png',
  },
  {
    name: 'Youtube',
    path: 'https://www.youtube.com/channel/UCZJdHEvj__HashgUl99INOw',
    icon: '/images/youtube.png',
  },
];

const ACCOUNT = [
  {
    name: 'My Account',
    path: '/my-account',
  },
  {
    name: 'Track My Order',
    path: '/my-account',
  },
  {
    name: 'Sell With Breakout',
    path: '/seller/sell-with-breakout',
  },
  {
    name: 'Contact Us',
    path: '/contactus',
  },
];

const SUPPORT = [
  {
    name: 'Help center',
    path: '/faq',
  },
  {
    name: 'Terms & conditions',
    path: '/terms',
  },
  {
    name: 'Privacy policy',
    path: '/privacy',
  },
];

const SocialLinks = () => {
  return (
    <div className="d-flex">
      {socialLinks.map(({ name, icon, path }) => (
        <SocialIconButton
          key={name}
          href={path}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={icon} alt={name} width={18} />
        </SocialIconButton>
      ))}
    </div>
  );
};

function SiteFooter() {
  const { isMobile } = useResponsive();
  return (
    <FooterWrapper>
      <Container
        className={cns('d-flex justify-content-around', {
          'flex-column gap-4': isMobile,
        })}
      >
        <div>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/logo-white.png"
                title="breakout la"
                alt="breakout la"
                width={100}
              />
            </Link>
          </Navbar.Brand>
          <p className="copyright">Copyright © 2024 Breakout LA</p>
          <p className="copyright">All rights reserved</p>
          <p className="copyright">
            Developed by{' '}
            <a href="https://www.micro1.ai" className="moontek-link">
              micro1.ai
            </a>
          </p>
          <SocialLinks />
        </div>
        <div>
          <Nav>
            <div className="section-title">Account</div>
          </Nav>
          {ACCOUNT.map(({ name, path }) => (
            <Link to={path} key={name}>
              <a
                role="button"
                className="nav-link animation-link1"
                tabIndex={0}
              >
                {name}
              </a>
            </Link>
          ))}
        </div>
        <div>
          <div className="section-title">Support</div>
          {SUPPORT.map(({ name, path }) => (
            <Link to={path} key={name}>
              <a
                role="button"
                className="nav-link animation-link1"
                tabIndex={0}
              >
                {name}
              </a>
            </Link>
          ))}
        </div>
      </Container>
    </FooterWrapper>
  );
}

export default SiteFooter;
