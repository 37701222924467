import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { motion } from 'framer-motion';
export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.27);
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.15s linear;
  z-index: 9999;
  transition: all 0.5s;
  .divider {
    border: 1px solid #dddddd;
  }
`;

export const RightSideView = styled(motion.div)`
  width: 30%;
  min-width: 556px;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.body};
  @media (max-width: 767px) {
    min-width: 100%;
  }
`;

export const CloseView = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.125rem;
  margin-right: 1.125rem;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ProductInfo = styled.div`
  padding: 2.25rem;
  overflow: auto;
  height: calc(100% - 46px);
  padding-bottom: 200px!important;
  .product-full-image {
    margin: 1rem 1rem 2rem 1rem;
  }
  .product-full-image > img {
    height: 200px;
  }
  @media (max-width: 767px) {
    padding: 0.75rem;
  }
  .brand-name {
    color: ${(props) => props.theme.colors.gray3};
  }
`;

export const AddToCartView = styled.div`
  margin-top: 3rem;
`;

export const ProductImageView = styled.div`
  height: 78px;
  width: 78px;
  margin-right: 1rem;
`;

export const ProductImage = styled.img`
  width: 4.65rem;
  height: 3.546rem;
  object-fit: contain;
`;

export const ProductListContainer = styled.div`
  margin: 32px;
  @media (max-width: 767px) {
    margin: 0.75rem;
  }
`;

export const Divider = styled.div`
  border: 1px solid #dddddd;
`;

export const ProductSize = styled.div`
  padding: 0.5rem 1rem;
  background: #efefef;
  border-radius: 0.5rem;
  width: fit-content;
`;

export const ProductName = styled.div`
  color: #232f3e;
  margin: 0.375rem 0px;
`;

export const ProductDetailView = styled.div`
  margin: 0rem 2rem 1.5rem 2rem;
`;

export const ProductListingTitle = styled.div`
  margin-bottom: 1.25rem;
`;

export const PriceTitle = styled.div`
  font-size: 1rem;
  line-height: 19px;
  color: #292d32;
  opacity: 0.6;
`;

export const IssueText = styled.div`
  font-size: 1.125rem;
  color: #292d32;
  margin-top: 0.75rem;
`;

export const InfoTitle = styled.div`
  color: #292d32;
  opacity: 0.6;
  margin-bottom: 0.75rem;
`;

export const InfoValue = styled.div`
  background: #e7e7e7;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
`;

export const ProductInformationView = styled.div`
  margin: 1.5rem 0rem;

  @media (max-width: 767px) {
    justify-content: flex-start !important;
  }
`;

export const ProductList = styled.div`
    height: 69vh;
    @media (max-width: 767px) {
        height: 64vh;
    }
    padding-bottom: 200px;
`;

export const ProductInfoWrapper = styled.div`
  @media (max-width: 767px) {
    margin: 0.5rem;
  }
`;

export const BackView = styled.div`
  margin-left: 2rem;
  margin-top: 1.125rem;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-left: 0.75rem;
  }
`;

export const BackButtonText = styled.div`
  margin-left: 0.25rem;
`;

export const CartSummary = styled.div`
  position: absolute;
  bottom: 1.5rem;
  padding: 1.5rem;
  background-color: #121212;
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.body};
  width: 90%;
  left: 0;
  right: 0;
  margin: auto;
`;
