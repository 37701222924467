import { getBrands } from 'helpers/http/common';
import { useQuery } from 'react-query';

export type Brand = {
  brand_id: number;
  brand_image: string;
  brand_name: string;
};
export type Brands = ReadonlyArray<Brand>;

const useBrandsQuery = () => {
  const { data, isLoading } = useQuery<any>('brands', () => getBrands());
  return {
    brands: data?.data?.length > 0 ? data.data : [],
    isBrandsLoading: isLoading,
  };
};

export default useBrandsQuery;
