import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  .cart-main {
    margin: 3rem 0px 0px 0rem;
    @media (max-width: 768px) {
      margin: 3rem 0px 0px 0rem;
    }
    .cart-item-price {
      font-size: 1.375rem;
      font-weight: 800;
    }
  }
  .green-line {
    height: 2.5rem;
    margin-left: 1.3225rem;
    width: 2px;
    background-color: #53ca74;
  }
  .dashed-line {
    height: 2.5rem;
    margin-left: 1.3225rem;
    width: 1px;
    border: ${(props) => '1px ' + props.theme.colors.grey1};
    border-style: dashed;
  }
  .status-text {
    margin-top: 0.5rem;
  }
  .order-details {
    margin-top: 3.625rem;
    margin-bottom: 3.625rem;
    .track-order {
      margin-top: 2rem;
    }
  }
  .products-view {
    flex: 3;
  }
  .order-summary {
    flex: 1;
    padding: 1.5rem;
    .summary-text {
      opacity: 0.6;
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled(Container)`
  max-width: 970px;
  min-height: 60vh;
`;

export const CartSummaryWrapper = styled(Container)`
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  min-width: 15.625rem;
  margin: 0rem 0px 0px 1rem;
  @media (max-width: 768px) {
    margin: 0;
  }
  .summary-row-devider {
    opacity: 0.1;
    border: 1px solid #000000;
    margin: 1rem 0px 1rem;
  }
`;

export const CartItemWrapper = styled(Container)`
  box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.07);
  margin: 0rem 0px 1.5rem 0rem;
  padding: 0;
  border-radius: 12px;
  .left-section {
    flex: 3;
    padding: 1.5rem;
    @media (max-width: 768px) {
      border-right: none;
      width: 100%;
      padding: rem 0rem 1.5rem 0rem;
    }
    @media (max-width: 968px) {
      border-right: none;
      width: 100%;
      padding: 1.5rem 1.5rem 0rem 1.5rem;
    }
  }
  .main-content {
    padding: 0rem 1.5rem;
    @media (max-width: 768px) {
      padding: 1.5rem 0rem;
    }
    @media (max-width: 968px) {
      padding: 1.5rem 0rem;
    }
  }
  .right-section {
    flex: 1;
    padding: 1.5rem;
    @media (max-width: 768px) {
      padding: 0rem 1.5rem 1.5rem 1.5rem;
    }
    @media (max-width: 968px) {
      padding: 0rem 1.5rem 1.5rem 1.5rem;
    }
  }
  .product-image {
    width: 137px;
    height: 95px;
    object-fit: contain; 
  }
`;
