import React from 'react';
import SideBar from 'components/Sidebar';
import PageWrapper from 'components/styled/PageWrapper';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/auth-context';
import Hamburger from 'components/ui/hamburger';
import styled from 'styled-components';
import useResponsive from 'helpers/hooks/useResponsive';

const SidebarWrapper = styled.div`
  #sidebar {
    .sidenav {
      /* position: absolute; */
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
  &.sidebarHidden {
    #sidebar {
      .sidenav {
        transform: translateX(-105%);
        opacity: 0;
      }
    }
  }

  .HamburgerToggle {
    position: fixed;
    z-index: 99;
    display: flex;
    top: 5px;
    right: 5px;
    width: 50px;
    height: 48px;
    border-radius: 7px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    &:hover,
    &:focus,
    &:active {
      box-shadow: -12px 12px 30px rgba(0, 0, 0, 0.25);
    }
  }
`;

function PageLayout() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const [openMobileMenu, setMobileMenu] = React.useState(false);
  const toggleMobileMenu = () => setMobileMenu((prev) => !prev);
  const auth = useAuth();
  const { isMobile } = useResponsive();
  const showSidebar = React.useMemo(() => {
    return !location.pathname.includes('complete-profile');
  }, [location.pathname]);

  if (!auth.user) return <Outlet />;

  return (
    <PageWrapper
      className={`${!isSidebarOpen ? 'withMinimized' : ''} ${
        !showSidebar ? 'no-sidebar' : ''
      }`}
    >
      {showSidebar &&
        (isMobile ? (
          <SidebarWrapper
            className={
              openMobileMenu
                ? 'sidebarVisible sidebarmobile'
                : 'sidebarHidden sidebarmobile'
            }
          >
            {!openMobileMenu && (
              <Hamburger
                open={openMobileMenu}
                onToggle={() => setMobileMenu((prev) => !prev)}
              />
            )}
            <div id="sidebar">
              <SideBar
                show={isSidebarOpen}
                toggle={toggleSidebar}
                toggleMobileMenu={toggleMobileMenu}
              />
            </div>
          </SidebarWrapper>
        ) : (
          <SideBar
            show={isSidebarOpen}
            toggle={toggleSidebar}
            toggleMobileMenu={toggleMobileMenu}
          />
        ))}

      <div className={!isMobile ? `container-f` : 'container pt-5'}>
        <section className={isMobile ? 'p-1 pt-5' : 'p-3'}>
          <Outlet />
        </section>
      </div>
    </PageWrapper>
  );
}

export default PageLayout;
