import React from 'react';
import { BreadCrumbWrapper, BreadCrumbItem } from './BreadCrumbViewStyled';
import {
  BreadCrumbViewProps,
  BreadCrumbItem as BreadCrumbItemType,
} from './breadcrumb.type';
import { useNavigate } from 'react-router-dom';

function BreadCrumbView({ list }: BreadCrumbViewProps) {
  const navigate = useNavigate();
  const navigateBack = (index: number) => {
    navigate(-(list.length - index - 1));
  };
  // TODO: Redo this component
  const renderBreadCrumb = (
    item: BreadCrumbItemType,
    active: boolean,
    i: number
  ) => {
    if (item.title?.toLowerCase() === 'home') {
      <BreadCrumbItem
        key={i}
        active={active}
        onClick={() => navigate('/home')}
      >
        {item.title}
        {'   '}
        {!active && '/'}
      </BreadCrumbItem>;
    }
    return (
      <BreadCrumbItem
        key={i}
        active={active}
        onClick={() => !active && navigateBack(i)}
      >
        {item.title}
        {'   '}
        {!active && '/'}
      </BreadCrumbItem>
    );
  };

  return (
    <BreadCrumbWrapper className="d-flex flex-wrap">
      {list.map((item: any, i: number) =>
        renderBreadCrumb(item, list.length - 1 === i, i)
      )}
    </BreadCrumbWrapper>
  );
}

export default BreadCrumbView;
