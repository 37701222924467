import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';
import BreadCrumbView from 'components/breadcrumb-view/BreadCrumbView';
import { BreadCrumbItem } from 'components/breadcrumb-view/breadcrumb.type';
import { Wrapper, Content, CartItemWrapper } from './order-details.styled';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { ReactComponent as Info } from 'assets/icons/info-circle.svg';
import { getMyOrderDetails } from 'helpers/http/account';
import Loader from 'components/Loader';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { getImageUrl, getStatusTitle } from 'helpers/utils/misc';

function OrderDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    getMyOrderDetails(id).then((res) => {
      setLoading(false);
      setOrderDetails({ ...res.data });
    });
  }, [id]);

  const CartItem = ({ item = {} }: any) => (
    <CartItemWrapper className="d-lg-flex align-items-center">
      <div className="left-section d-lg-flex align-items-center">
        <div className="mb-2">
          <img
            className="product-image"
            src={getImageUrl(item.catalog_images || '[]', 'single') as string}
            alt={item.catalog_name}
          />
        </div>
        <div className="main-content">
          <div className="fs-sm fw-400">{item.brand_name}</div>
          <div className="fs-20 fw-500 mt-1">{item.catalog_name}</div>
        </div>
      </div>
    </CartItemWrapper>
  );

  const breadcrumbList: BreadCrumbItem[] = [
    { title: 'Home' },
    { title: 'My Orders' },
  ];

  return (
    <>
      <Wrapper>
        <SiteHeader />
        <Container>
          <BreadCrumbView
            list={[
              ...breadcrumbList,
              { title: orderDetails?.products?.[0]?.catalog_name },
            ]}
          />
          <Content>
            <div
              className="d-flex align-items-end"
              onClick={() => navigate(-1)}
              role="button"
            >
              <Back />
              &nbsp;Back
            </div>
            {orderDetails && (
              <div className="cart-main">
                <Row className="d-flex">
                  <Col md={8}>
                    <div className="products-view">
                      {orderDetails.products?.map((product: any) => (
                        <CartItem
                          key={product.user_product_id}
                          item={{
                            ...product,
                            other_cost: orderDetails.other_cost,
                            total_amount: orderDetails.total_amount,
                            order_number: orderDetails.order_number,
                          }}
                        />
                      ))}
                    </div>
                  </Col>
                  <Col md={4}>
                    <CartItemWrapper className="align-items-center order-summary">
                      <div className="fw-500 summary-text fs-14">SUMMARY</div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Order number:</div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          {orderDetails.order_number}
                        </div>
                      </div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Item total:</div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          ${orderDetails.sub_total_amount}
                        </div>
                      </div>
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">
                          Delivery charges:
                        </div>
                        <div className="flex-1 fs-12 fw-400 text-end">
                          ${orderDetails.other_cost}
                        </div>
                      </div>
                      {orderDetails?.tax_amount && (
                        <div className="mb-1 d-flex align-items-center justify-content-evenly">
                          <div className="flex-1 fs-12 fw-400">Tax amount:</div>
                          <div className="flex-1 fs-12 fw-400 text-end">
                            ${orderDetails.tax_amount}
                          </div>
                        </div>
                      )}
                      <div className="mb-1 d-flex align-items-center justify-content-evenly">
                        <div className="flex-1 fs-12 fw-400">Order total:</div>
                        <div className="flex-1 fs-12 fw-700 text-end">
                          ${orderDetails.total_amount}
                        </div>
                      </div>
                    </CartItemWrapper>
                  </Col>
                </Row>
                <DeliveryStatus orderDetails={orderDetails} />
              </div>
            )}
            {loading && <Loader />}
          </Content>
        </Container>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

const DeliveryStatus = ({ orderDetails }: any) => {
  return (
    <div className="order-details d-flex flex-column justify-content-center align-items-center">
      <div>
        <div className="fs-24 fw-600 text-center">Delivery Status</div>
        {orderDetails?.tracking?.tracking_number &&
        orderDetails?.order_status !== 'fulfilled' ? (
          <>
            <div className="mt-1 fs-1rem fw-400 text-center">
              Tracking ID: {orderDetails?.tracking?.tracking_number}
            </div>
            <div className="text-center track-order">
              <StyledButton
                target="_blank"
                padding="16px 32px 16px 26px"
                variant="dark"
                shadow={1}
                href={orderDetails?.tracking?.tracking_url_provider}
              >
                <LocationIcon /> Track order
              </StyledButton>
            </div>
          </>
        ) : (
          <div className="fs-20 ">
            {getStatusTitle(orderDetails?.order_status || 'Shipping Pending')}{' '}
            <Info stroke="currentColor" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
