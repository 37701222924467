import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

export const Container = styled.div``;

export const ProductSizeItem = styled.div<{ selected?: boolean }>`
  outline: ${(props) =>
    props.selected
      ? '2px solid ' + props.theme.colors.black
      : '1px solid ' + props.theme.colors.gray2};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  min-width: 58px;
  text-align: center;
  margin: 0px 10px 12px 0px;
  cursor: pointer;
  @media (max-width: 767px) {
    margin: 0px 7px 12px 0px;
  }
`;

export const SizeTitle = styled.div``;

export const ChartLink = styled.div`
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  svg {
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    transform: scale(1.0125);
    font-weight: 500;
    svg {
      transform: translateX(5px);
    }
  }
`;

export const ChartLinkText = styled.div`
  font-size: 0.875rem;
  letter-spacing: -0.01em;
  margin-right: 0.5rem;
`;

export const ChartLinkIcon = styled(ChevronRight)`
  width: 0.875rem;
  height: 0.875rem;
`;
