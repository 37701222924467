import React, { useCallback, useState } from 'react';
import ProductImageList from './ProductImageList';

import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ImageView, ImageContainer } from './productImageViewStyled';
import { motion } from 'framer-motion';
interface ProductImageViewProps {
  imageList: string[];
  productImageWidth?: string;
  productImageHeight?: string;
  hasArrowNavigation?: boolean;
  altName?: string;
}

function ProductImageView({
  imageList,
  productImageHeight,
  productImageWidth,
  hasArrowNavigation,
  altName,
}: ProductImageViewProps) {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const onImageSelect = useCallback((image: number) => {
    setCurrentImage(image);
  }, []);

  const onPrev = React.useCallback(() => {
    if (currentImage !== 0) {
      setCurrentImage((img: number) => img - 1);
    }
  }, [currentImage]);

  const onNext = React.useCallback(() => {
    if (imageList.length - 1 > currentImage) {
      setCurrentImage((img: number) => img + 1);
    }
  }, [imageList, currentImage]);

  return (
    <div className="d-flex flex-column">
      <ImageContainer className="product-full-image d-flex align-items-center">
        {hasArrowNavigation && <ArrowLeft role="button" onClick={onPrev} />}
        <div className="image-view-container text-center">
          <ImageView
            src={imageList[currentImage]}
            width={productImageWidth}
            height={productImageHeight}
            alt={altName}
          />
        </div>
        {hasArrowNavigation && <ArrowRight role="button" onClick={onNext} />}
      </ImageContainer>
      <motion.div
        initial={{
          translateY: 10,
          opacity: 0,
        }}
        animate={{
          translateY: 0,
          opacity: 1,
        }}
        transition={{
          delay: 1,
          duration: 0.25,
          type: 'spring',
          stiffness: 100,
        }}
      >
        <ProductImageList
          imageList={imageList}
          onImageSelect={onImageSelect}
          selectedImage={currentImage}
          altName={altName}
        />
      </motion.div>
    </div>
  );
}

export default ProductImageView;
