import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';

const Wrapper = styled(Container)`
  max-width: 770px;
  line-height: 1.5;
  letter-spacing: 0.25px;
  h1.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
  min-height: 70vh;
`;

function Terms() {
  return (
    <>
      <HeaderWrapper>
        <SiteHeader />
        <Wrapper>
          <div className="mt-4">
            <h1 className="title">Terms and Conditions</h1>

            <p>
              You bring us , or ship us your item(s). Consignor is responsible
              for all shipping costs to get the items to & from Breakout.
            </p>
            <ul className="text-left">
              <li>
                We help you determine your sell prices in accordance with
                reasonable market values using our experience and extensive
                data.
              </li>
              <li>
                The consignment rate will be 85/15. 85% of the sale goes to the
                consignor, 15% goes to BreakoutLA or $30, whichever is greater.
              </li>
              <li>
                Consignor will pay a $20 fee for each item being removed /
                pulled out of our stock/inventory before 15 calendar days after
                dropping off.
              </li>
              <li>
                Items must be supplied clean and ready to be displayed to avoid
                a cleaning fee.
              </li>
              <li>
                Once Breakout has possession of the Consignors item(s) , there
                will be a authentication verification process before item
                becomes available for sell.
              </li>
              <li>
                <div className="fw-600 fs-1rem mb-1 underlined-list-item">
                  BREAKOUT HAS THE RIGHT TO REFUSE ANY ITEMS OR DECLINE ANY
                  SERVICE.
                </div>
              </li>
              <li>
                If “Agreed Asking Price” isn't conforming with current market
                value , we will contact the consignor to allow Breakout Los
                Angeles to adjust the price.
              </li>
            </ul>
          </div>
        </Wrapper>
      </HeaderWrapper>
      <SiteFooter />
    </>
  );
}

export default Terms;
