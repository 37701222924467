import React from 'react';

import { ProductSizeItem, SizeTitle } from './styled';

interface ProductSizeViewProps {
  productSizeList: string[];
  onSizeSelect: (size: string) => void;
  size?: number | string;
  sizeUnit?: number | string;
}

function ProductSizeView({
  productSizeList,
  onSizeSelect,
  size,
  sizeUnit,
}: ProductSizeViewProps) {
  // const onSizeChartClick = React.useCallback(() => {
  // }, []);

  // const filteredSizes = React.useMemo<any>(
  //   () =>
  //     productSizeList.sort(function (a, b) {
  //       return a - b;
  //     }),
  //   [productSizeList]
  // );
  if (productSizeList.length === 0) return null;
  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <SizeTitle>Select size {sizeUnit && `(${sizeUnit})`}</SizeTitle>
        {/* <ChartLink
          className="d-flex align-items-center"
          onClick={onSizeChartClick}
        >
          <ChartLinkText>Size Chart</ChartLinkText>
          <ChartLinkIcon />
        </ChartLink> */}
      </div>
      <div className="d-flex flex-wrap mb-2r">
        {productSizeList.map((productSize: string, i: number) => (
          <ProductSizeItem
            selected={size === productSize}
            key={i}
            onClick={() => onSizeSelect(productSize)}
          >
            {productSize}
          </ProductSizeItem>
        ))}
      </div>
    </div>
  );
}

export default ProductSizeView;
