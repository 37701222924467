import React from 'react';
import PageLayout from 'components/Layout/PageLayout';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SuspenseWrapper from './SuspenseWrapper';
import { useAuth } from 'helpers/contexts/auth-context';
import WebsiteLayout from 'components/Layout/PublicLayout';
import Page404 from 'components/Page404';

// seller routes starts
import RequireAuth, { RequireBuyerAuth } from './RequireAuth';
import Terms from 'pages/Terms';
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy';
import SellerSearch from 'pages/seller-search/SellerSearch';
import SellWithBreakout from 'pages/sell-with-breakout/SellWithBreakout';
import ContactUs from 'pages/ContactUs';
import Faq from 'pages/faq/Faq';

import ForgetPassword from 'pages/login/ForgetPassword';
import { sellerRoutes } from './seller-routes';
import { buyerRoutes } from './buyer-routes';
import DocumentTitle from 'components/DocumentTitle';
import { TrackingOrder } from 'pages/TrackingOrder';
import PermissionController from 'modules/PermissionController';
import ViewOrder from 'pages/view-order';

const Settings = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/settings')
);
const Profile = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/settings/Profile')
);
const Payments = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/settings/Payments')
);

const HomePage = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/home')
);

const AllBrands = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/all-brands')
);

const CollectionProducts = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/collection-products')
);

export const publicRoutes = [
  {
    path: '/home',
    component: <HomePage />,
    title: 'Breakout LA | Shop with breakout',
    metaDescription:
      'Sneakers & Urban Streetwear Consignment Shop - Buy - Sell - Trade - Consign. Breakout provides online & in store facility to buy & sell product with best price.',
  },
  {
    path: '/brands',
    component: <AllBrands />,
    title: 'Breakout LA | All brands',
    metaDescription:
      'Browse all brands sneakers, nike, Urban Streetwear, old products, new products & discounts. Breakout provides all top brand products like nike sneakers, nike shirts.',
  },
  {
    path: '/collection-products',
    component: <CollectionProducts />,
    title: 'Breakout LA | All brands & collections of wide range of products.',
    metaDescription:
      'Browse collection products | sneakers collection, street wear collections, old product collections, new product collections',
  },
  {
    path: '/forgot-password',
    component: <ForgetPassword />,
    title: 'Breackout LA | Forgot your password?',
  },
  {
    path: '/404',
    component: <Page404 isMainPage={true} />,
    title: 'Breackout LA | Page not found',
  },
  {
    path: '/terms',
    component: <Terms />,
    title: 'Breackout LA | Terms and conditions',
    metaDescription:
      'BreakoutLA | Terms and Conditions for buyers and sellers. Terms and conditions provides transparent way to do business with breakout.',
  },
  {
    path: '/privacy',
    component: <PrivacyPolicy />,
    title: 'Breackout LA | Privacy Policy',
    metaDescription:
      'BreakoutLA | Privacy Policy for Users, Buyers and Sellers. Breakout provides end to end data privacy for all users.',
  },
  {
    path: '/contactus',
    component: <ContactUs />,
    title: "Breackout LA | Let's contact with us",
    metaDescription:
      'Send your query to us and we will give you the support to buy and sell products with breakout.',
  },
  {
    path: '/faq',
    component: <Faq />,
    title: 'Breackout LA | Help',
  },
  {
    path: '/tracking/:orderid/:trackingid',
    component: <TrackingOrder />,
    title: 'Track your order',
  },
  {
    path: '/view-order',
    component: <ViewOrder />,
    title: 'View order',
  },
];

function AppRoutes() {
  const location = useLocation();
  const auth = useAuth();
  return (
    // <AnimatePresence>
    <Routes key={location.pathname}>
      <Route element={<WebsiteLayout />} path="/">
        {publicRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                {title && (
                  <DocumentTitle
                    title={title}
                    metaDescription={metaDescription}
                  />
                )}
                <SuspenseWrapper>{component}</SuspenseWrapper>
              </>
            }
          />
        ))}
        {buyerRoutes.public.map(
          ({ path, component, title, metaDescription }) => (
            <Route
              key={path}
              path={path}
              element={
                <PermissionController type="buyer">
                  {(title || metaDescription) && (
                    <DocumentTitle
                      title={title}
                      metaDescription={metaDescription}
                    />
                  )}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </PermissionController>
              }
            />
          )
        )}
        {buyerRoutes.private.map(({ path, component, title }) => (
          <Route
            key={path}
            path={path}
            element={
              <PermissionController type="buyer">
                {title && <DocumentTitle title={title} />}
                <SuspenseWrapper>
                  <RequireBuyerAuth>{component}</RequireBuyerAuth>
                </SuspenseWrapper>
              </PermissionController>
            }
          />
        ))}
        <Route path="" element={<Navigate to="/home" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route
        path="/seller/sell-with-breakout"
        element={
          <>
            <DocumentTitle
              title={'Breackout LA | Sell with breakout'}
              metaDescription="BreakoutLA Seller portal | Be a seller at breakoutLA and start selling with an easy process."
            />
            <SellWithBreakout />
          </>
        }
      />

      <Route element={<PageLayout />} path="/seller">
        {sellerRoutes.public.map(
          ({ path, component, title, metaDescription }) => (
            <Route
              key={path}
              path={path}
              element={
                <PermissionController type="seller">
                  {(title || metaDescription) && (
                    <DocumentTitle
                      title={title}
                      metaDescription={metaDescription}
                    />
                  )}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </PermissionController>
              }
            />
          )
        )}
        <Route
          path="search"
          element={
            <SuspenseWrapper>
              <DocumentTitle
                title={'Breackout LA | Search Products Here'}
                metaDescription="BreakoutLA | Search By Advanced Search Filters And Shop Sneakers and Urban Streetwear. Search products by price, gender, brand, types. You can search your products here easily by using filters and title searching."
              />
              <SellerSearch />
            </SuspenseWrapper>
          }
        />

        {sellerRoutes.private.map(({ path, component, title }) => (
          <Route
            key={path}
            path={path}
            element={
              <PermissionController type="seller">
                {title && <DocumentTitle title={title} />}
                <SuspenseWrapper>
                  <RequireAuth>{component}</RequireAuth>
                </SuspenseWrapper>
              </PermissionController>
            }
          />
        ))}

        <Route
          path="settings"
          element={
            <PermissionController type="seller">
              <SuspenseWrapper>
                <DocumentTitle title="Breackout LA | Settings" />
                <Settings />
              </SuspenseWrapper>
            </PermissionController>
          }
        >
          <Route
            path="profile"
            element={
              <SuspenseWrapper>
                <DocumentTitle title="Breackout LA | Profile | Settings" />
                <Profile />
              </SuspenseWrapper>
            }
          />
          <Route
            path="payment"
            element={
              <SuspenseWrapper>
                <DocumentTitle title="Breackout LA | Settings | Payment" />
                <Payments />
              </SuspenseWrapper>
            }
          />
          <Route path="" element={<Navigate to="profile" />} />
        </Route>

        <Route
          path=""
          element={<Navigate to={auth.user ? 'dashboard' : 'login'} />}
        />
        <Route
          path="*"
          element={
            <SuspenseWrapper>
              <DocumentTitle title="Breackout LA | Page not found" />
              <Page404 />
            </SuspenseWrapper>
          }
        />
      </Route>
    </Routes>
    // </AnimatePresence>
  );
}

export default AppRoutes;
