/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { MyListingType } from 'pages/listing/listing.types';
import useMyListing from 'pages/listing/useMyListing';
interface DraftContextType {
  listingState: {
    page: number;
    total: number;
    data: MyListingType[] | null;
    status: string;
  };
  fetchListing: any;
  editListingModal: any;
  openEditListingModal: any;
  closeEditListingModal: any;
}
const DraftContext = React.createContext<DraftContextType>(null!);

function DraftProvider({ children }: { children: React.ReactNode }) {
  const {
    listingState,
    fetchListing,
    editListingModal,
    openEditListingModal,
    closeEditListingModal,
  } = useMyListing('draft');
  const value = React.useMemo(
    () => ({
      listingState,
      fetchListing,
      editListingModal,
      openEditListingModal,
      closeEditListingModal,
    }),
    [
      listingState,
      fetchListing,
      editListingModal,
      openEditListingModal,
      closeEditListingModal,
    ]
  );
  return (
    <DraftContext.Provider value={value}>{children}</DraftContext.Provider>
  );
}

function useDraft() {
  return React.useContext(DraftContext);
}

export { DraftProvider, useDraft };
