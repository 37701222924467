import { Container, Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StyledButton } from 'components/forms/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { ReactComponent as Email } from 'assets/icons/message.svg';
import { ReactComponent as Call } from 'assets/icons/call.svg';

import { saveContactForm } from '../helpers/http/common';

const phoneRegExp =
  /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .max(255, 'Maximum 255 characters')
      .required('Email is required'),
    first_name: yup.string().max(255).required('First name is required'),
    last_name: yup
      .string()
      .max(255, 'Maximum 255 characters')
      .required('Last name is required'),
    phone_number: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Please enter a valid 10 digit phone number.'),
    message: yup.string().required('Message is required'),

  })
  .required();

export const Wrapper = styled(Form)`
  padding: 3.75rem;
  min-height: 70vh;
  .content {
    max-width: 37.5rem;
    margin: 0px auto;
  }
  .contact-details {
    padding: 28px 36px;
    background: rgba(231, 231, 231, 0.5);
    border-radius: 12px;
    margin-bottom: 2.5rem;
    margin-top: 2.25rem;
  }
  .issue-textarea {
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    width: 100%;
    padding: 10px 20px;
  }
  .submit-button {
    width: fit-content;
    margin: 0px auto;
  }
  .call-detail {
    border-right: 1px solid #d1d1d1;
    flex: 2;
  }
  .email-detail {
    flex: 3;
  }
`;

export const Input = styled(Form.Control)`
  background: white;
  height: 3.1rem;
  border-radius: 4px;
  border: none;
  padding-left: 0.75rem;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  ::-webkit-input-placeholder {
    /* Edge */
    color: #b7b7b7;
  }
  ::placeholder {
    color: #b7b7b7;
  }
`;

export type ContactUsFormType = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  message: string;
};

function ContactUs() {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => {
    const promise = saveContactForm(data);
    toast.promise(promise, {
      loading: 'loading...',
      success: (res) => {
        reset();
        return res.message;
      },
      error: (err) => err?.response?.data?.message || 'error',
    });
  };

  const { errors } = formState;
  return (
    <>
      <SiteHeader />
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <h1 className="text-center mb-3">Contact us</h1>
          <h2 className="fs-1rem fw-400 text-center">
            Please feel free to reach out to us for any questions regarding item
            sizing, availability, price drops, consignment etc.
          </h2>
          <Container>
            <Row className="contact-details">
              <Col md={6} className="d-flex g-1 call-detail align-items-center">
                <Call />
                (562) 676-4100
              </Col>
              <Col
                md={6}
                className="d-flex g-1 align-items-center justify-content-center"
              >
                <Email />
                inquiries.breakout@gmail.com
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-4">
                  <Form.Label>First name</Form.Label>
                  <Input
                    type="text"
                    placeholder="Enter your first name"
                    {...register('first_name')}
                    maxLength={255}
                  />
                  <ErrorMessage>{errors.first_name?.message}</ErrorMessage>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Last name</Form.Label>
                  <Input
                    type="text"
                    placeholder="Enter your last name"
                    {...register('last_name')}
                    maxLength={255}
                  />
                  <ErrorMessage>{errors.last_name?.message}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-4">
                  <Form.Label>Email</Form.Label>
                  <Input
                    type="text"
                    placeholder="Enter your email address"
                    {...register('email')}
                    maxLength={255}
                  />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-4">
                  <Form.Label>Phone number</Form.Label>
                  <Input
                    type="text"
                    placeholder="Enter your phone number"
                    {...register('phone_number')}
                    maxLength={255}
                  />
                  <ErrorMessage>{errors.phone_number?.message}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-4">
                  <Form.Label>Message</Form.Label>
                  <textarea
                    rows={4}
                    placeholder="Write here..."
                    className="issue-textarea"
                    {...register('message')}
                  />
                  <ErrorMessage>{errors.message?.message}</ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            <Row className="submit-button">
              <StyledButton variant="dark" type="submit">
                Submit
              </StyledButton>
            </Row>
          </Container>
        </div>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default ContactUs;
