import styled from 'styled-components';

export const Wrapper = styled.div`
  .accordionHeader {
    margin: 0rem 0.75rem 1.25rem 0.75rem;
    border: 1px solid #d6d6d6;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    &:hover {
      background-color: ${(props) => props.theme.colors.gray2};
    }
  }
  .title {
    line-height: 1.8125rem;
  }
  .detailsView {
    color: ${(props) => props.theme.colors.black};
    font-style: normal;
    font-weight: 400;
    opacity: 0.7;
    line-height: 1.8125rem;
    margin-top: 0.375rem;
  }
`;
