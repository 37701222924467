import { StyledButton } from 'components/forms/Buttons';
import React from 'react';
import { Container, Form, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from 'components/ui/ErrorMessage';
import states from 'helpers/const/states';
import { useAuth } from 'helpers/contexts/auth-context';
import { validateCompleteFormSchema } from 'helpers/validation/common';
import { getYupErrors } from 'helpers/utils/misc';
import InfoPopover from 'components/ui/InfoPopover';

const Wrapper = styled(Container)`
  max-width: 500px;
  min-height: 100vh;
  .content-card {
    padding: 3rem;
    background: #ffffff;
    border-radius: 14px;
    margin-bottom: 2rem;
    h1.title {
      font-size: 1.75rem;
      font-weight: 700;
    }
  }
  .form-content {
    margin-top: 2rem;
    .form-label {
      color: #292d32;
      font-size: 14px;
    }
    .form-control,
    select {
      min-height: 51px;
      padding-left: 1.25rem;
      border: 1px solid #e7e7e7;
      border-radius: 0.5rem;
    }
  }
`;

const defaultFormState = {
  address_1: '',
  city: '',
  country: 'US',
  state: '',
  zipcode: '',
  gender: 'male',
};
function CompleteProfile({ onBack, registrationState }: any) {
  const { isLoading, register } = useAuth();
  const [formState, setFormState] = React.useState<any>(defaultFormState);
  const [errors, setErrors] = React.useState<any>({});
  const setState = (updates: any) => setFormState({ ...formState, ...updates });
  const navigate = useNavigate();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    validateCompleteFormSchema.isValid(formState).then((valid) => {
      if (!valid) {
        validateCompleteFormSchema
          .validate(formState, { abortEarly: false })
          .catch((err) => {
            const errors = getYupErrors(err);
            setErrors({ ...errors });
          });
      } else {
        setErrors({});
        const payload = {
          ...registrationState,
          ...formState,
          address_lat_long: '37.0902, 95.7129',
        };
        register(payload, () => {
          navigate('/seller/dashboard', { replace: true });
        });
      }
    });
  };
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  return (
    <Wrapper>
      <StyledButton
        variant="transparent"
        onClick={onBack}
        className="ps-0 mt-3"
      >
        <Back />
        Back
      </StyledButton>
      <div className="content-card">
        <h1 className="title">Complete your profile</h1>
        <div className="form-content">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter street address"
                name="address_1"
                value={formState.address_1}
                onChange={handleChange}
                maxLength={255}
              />
              <ErrorMessage message={errors.address_1} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="city"
                value={formState.city}
                onChange={handleChange}
                maxLength={255}
              />
              <ErrorMessage message={errors.city} />
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={formState.country}
                onChange={handleChange}
                name="country"
              >
                <option>Select Country</option>
                {countries.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              <ErrorMessage message={errors.country} />
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select
                value={formState.state}
                onChange={handleChange}
                name="state"
              >
                <option>Select State</option>
                {states.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              <ErrorMessage message={errors.state} />
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                name="state"
                value={formState.state}
                onChange={handleChange}
              />
              <ErrorMessage message={errors.state} />
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>Zip code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter zipcode"
                name="zipcode"
                value={formState.zipcode}
                onChange={handleChange}
                maxLength={9}
              />
              <ErrorMessage message={errors.zipcode} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Male"
                  name="gender"
                  defaultChecked={true}
                  onChange={() => setState({ gender: 'male' })}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Female"
                  name="gender"
                  onChange={() => setState({ gender: 'female' })}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <InfoPopover
                  id="check-name-info"
                  body="Please put the official name on your check for payments"
                >
                  <span className="form-label">Name on Check</span>
                </InfoPopover>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your check authorization name"
                name="check_name"
                value={formState.check_name}
                onChange={handleChange}
                maxLength={255}
              />
              <ErrorMessage message={errors.check_name} />
            </Form.Group>

            <div className="d-flex g-1 justify-content-end">
              <StyledButton
                variant="dark"
                className="px-4 py-2"
                type="submit"
                style={{ width: '100%' }}
                height={51}
                disabled={isLoading}
              >
                Submit {isLoading && <Spinner animation="border" size="sm" />}
              </StyledButton>
            </div>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
}

export default CompleteProfile;
