import React from 'react';
import AccordionView from 'components/accordion/AccordionView';
import Checkbox from 'components/forms/Checkbox';
import { type, conditions } from './filterOptions';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';
import useResponsive, { mediaQuery } from 'helpers/hooks/useResponsive';
import { StyledButton } from 'components/forms/Buttons';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

const FilterWrapper = styled.div<{ show: boolean }>`
  @media ${mediaQuery.mobile} {
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

type Props = {
  refetchProducts: any;
  filters: any;
  brands?: any;
  categories: any;
  availableSizes: string[];
};

function FilterView({
  refetchProducts,
  filters,
  brands,
  categories,
  availableSizes,
}: Props) {
  const { isMobile } = useResponsive();
  const [showMobileFilter, setShowMobileFilter] = React.useState(false);
  const toggleMobileFilter = () => {
    setShowMobileFilter(!showMobileFilter);
  };
  return (
    <>
      {isMobile && (
        <StyledButton
          variant="outline-dark"
          className="w-100 mb-4"
          onClick={toggleMobileFilter}
        >
          <FilterIcon stroke="currentColor" />{' '}
          {showMobileFilter ? 'Hide' : 'Show'} filters
        </StyledButton>
      )}

      <FilterWrapper show={showMobileFilter}>
        <div className="shadowBox fs-20 fw-700">Filters</div>
        <div className="shadowBox">
          <AccordionView
            defaultExpand={true}
            title="CATEGORIES"
            details={
              <CategoriesFilter
                categories={categories}
                filters={filters}
                refetchProducts={refetchProducts}
              />
            }
          />
        </div>
        {brands && (
          <div className="shadowBox">
            <AccordionView
              defaultExpand={true}
              title="BRANDS"
              details={
                <BrandsFilter
                  brands={brands}
                  filters={filters}
                  refetchProducts={refetchProducts}
                />
              }
            />
          </div>
        )}
        <div className="shadowBox">
          <AccordionView
            defaultExpand={true}
            title="TYPE"
            details={
              <TypesFilter
                filters={filters}
                refetchProducts={refetchProducts}
              />
            }
          />
        </div>
        <div className="shadowBox">
          <AccordionView
            defaultExpand={true}
            title="SIZES"
            details={
              <SizeFilter
                filters={filters}
                refetchProducts={refetchProducts}
                sizes={availableSizes}
              />
            }
          />
        </div>
        <div className="shadowBox">
          <AccordionView
            defaultExpand={true}
            title="CONDITION"
            details={
              <ConditionsFilter
                filters={filters}
                refetchProducts={refetchProducts}
              />
            }
          />
        </div>
      </FilterWrapper>
    </>
  );
}

function CategoriesFilter({
  categories,
  filters,
  refetchProducts,
}: any): JSX.Element {
  const [showAllCategories, setShowAllCategories] =
    React.useState<boolean>(false);

  const onCategoryChange = (id?: number | string) => {
    const updatedFilters = { ...filters };
    if (id) {
      const index = updatedFilters.categoryId.indexOf(`${id}`);
      if (index !== -1) {
        updatedFilters.categoryId.splice(index, 1);
      } else {
        updatedFilters.categoryId.push(`${id}`);
      }
    } else {
      updatedFilters.categoryId = [];
    }
    refetchProducts(updatedFilters);
  };

  let categoryData = categories;
  if (!showAllCategories) {
    categoryData = categories.slice(0, 3);
  }
  return (
    <>
      <div className="d-flex">
        <Checkbox
          checked={filters.categoryId.length === 0}
          toggle={() => onCategoryChange()}
        />
        <div className="mx-1">All</div>
      </div>
      {categoryData.map((item: any) => (
        <div className="d-flex" key={item.category_id}>
          <Checkbox
            checked={filters.categoryId.includes(`${item.category_id}`)}
            toggle={() => onCategoryChange(item.category_id)}
          />
          <div className="mx-1">{item.category_name}</div>
        </div>
      ))}
      {!showAllCategories ? (
        <div
          className="more-link pointer"
          onClick={() => setShowAllCategories(true)}
        >
          + more
        </div>
      ) : (
        <div
          className="more-link pointer"
          onClick={() => setShowAllCategories(false)}
        >
          - less
        </div>
      )}
    </>
  );
}

function BrandsFilter({ brands, filters, refetchProducts }: any): JSX.Element {
  const [showAllBrands, setShowAllBrands] = React.useState<boolean>(false);

  const onBrandChange = (id?: number | string) => {
    const updatedFilters = { ...filters };
    if (id) {
      const index = updatedFilters.brandId.indexOf(`${id}`);
      if (index !== -1) {
        updatedFilters.brandId.splice(index, 1);
      } else {
        updatedFilters.brandId.push(`${id}`);
      }
    } else {
      updatedFilters.brandId = [];
    }
    refetchProducts(updatedFilters);
  };

  let brandsData = brands;
  if (!showAllBrands) {
    brandsData = brands?.slice(0, 3);
  }
  return (
    <>
      <div className="d-flex">
        <Checkbox
          checked={filters.brandId.length === 0}
          toggle={() => onBrandChange()}
        />
        <div className="mx-1">All</div>
      </div>
      {brandsData.map((item: any) => (
        <div className="d-flex" key={item.brand_id}>
          <Checkbox
            checked={filters.brandId.includes(`${item.brand_id}`)}
            toggle={() => onBrandChange(item.brand_id)}
          />
          <div className="mx-1">{item.brand_name}</div>
        </div>
      ))}
      {!showAllBrands ? (
        <div
          className="more-link pointer"
          onClick={() => setShowAllBrands(true)}
        >
          + more
        </div>
      ) : (
        <div
          className="more-link pointer"
          onClick={() => setShowAllBrands(false)}
        >
          - less
        </div>
      )}
    </>
  );
}

function ConditionsFilter({ filters, refetchProducts }: any): JSX.Element {
  const onConditionChange = (selected: string) => {
    const currentFilters = filters;
    const currentConditions = filters.conditions;
    if (currentConditions.indexOf(selected) !== -1) {
      currentConditions.splice(currentConditions.indexOf(selected), 1);
    } else {
      currentConditions.push(selected);
    }
    currentFilters.conditions = currentConditions;
    refetchProducts(currentFilters);
  };

  return (
    <>
      {conditions.map((item: { label: string; key: string }) => (
        <div className="d-flex" key={item.key}>
          <Checkbox
            checked={filters.conditions.includes(item.key) ? true : false}
            toggle={() => onConditionChange(item.key)}
          />
          <div className="mx-1">{item.label}</div>
        </div>
      ))}
    </>
  );
}

function SizeFilter({ filters, refetchProducts, sizes }: any): JSX.Element {
  const onSizeChange = (selected: number) => {
    const currentFilters = filters;
    const currentSizes = filters.sizes;
    if (currentSizes.length == 0) {
      currentSizes.push(selected);
    } else if (currentSizes.indexOf(selected) != -1) {
      currentSizes.splice(currentSizes.indexOf(selected), 1);
    } else {
      currentSizes.push(selected);
    }
    currentFilters.sizes = currentSizes;
    refetchProducts(currentFilters);
  };

  return (
    <div className="d-flex justify-content-start align-items-center flex-wrap">
      {sizes
        .sort((a: any, b: any) => Number(a) - Number(b))
        .map((item: number) => (
          <div
            className={`${
              filters.sizes.includes(item)
                ? 'size-button-selected'
                : 'size-button'
            } d-flex justify-content-center align-items-center`}
            key={item}
            onClick={() => onSizeChange(item)}
          >
            <div className="fs-sm fw-400">{item}</div>
          </div>
        ))}
    </div>
  );
}

function TypesFilter({ filters, refetchProducts }: any): JSX.Element {
  const onTypeChange = (selected: string) => {
    const currentFilters = filters;
    const currentGenders = filters.gender;
    if (currentGenders.indexOf(selected) !== -1) {
      currentGenders.splice(currentGenders.indexOf(selected), 1);
    } else {
      currentGenders.push(selected);
    }
    currentFilters.gender = currentGenders;
    refetchProducts(currentFilters);
  };

  return (
    <>
      {type.map((item) => (
        <div className="d-flex" key={item.key}>
          <Checkbox
            checked={filters.gender.includes(item.key) ? true : false}
            toggle={() => onTypeChange(item.key)}
          />
          <div className="mx-1">{item.label}</div>
        </div>
      ))}
    </>
  );
}

export default FilterView;
