import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Container } from 'react-bootstrap';

import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import {
  Wrapper,
  ProductPictureView,
  ProductDetailsView,
  ProductName,
  SKUName,
  PriceText,
  BuyButtonText,
  Container as ProductContainer,
  BuyButtonPanel,
} from './styled';

import ProductImageView from './product-image/ProductImageView';
import ProductSizeView from './product-size-view/ProductSizeView';
import AccordionView from './product-accordion/AccordionView';
import Sidebar from './sidebar/SidebarView';

import { Section } from '../home/home.styled';
import ProductBox from 'components/general/ProductBox';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { collectionResponsive } from '../home/slider.responsive';
import BreadCrumbView from 'components/breadcrumb-view/BreadCrumbView';
import { getProductDetails } from '../../helpers/http/product';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCollections } from 'helpers/http/home';
import Loader from 'components/Loader';
import { ProductType } from 'pages/default-products/product.type';
import { BreadCrumbItem } from 'components/breadcrumb-view/breadcrumb.type';
import { getImageUrl } from 'helpers/utils/misc';
import DocumentTitle from 'components/DocumentTitle';
import { motion } from 'framer-motion';
import { StyledButton } from 'components/forms/Buttons';
import NotFound from 'components/ui/NotFoundView';

const ButtonGroup = ({ next, previous }: any) => {
  return (
    <div className="carousel-button-group">
      <button onClick={() => previous()} className="btn-slide btn-prev">
        <ArrowLeft />
      </button>
      <button onClick={() => next()} className="btn-slide btn-next">
        <ArrowRight />
      </button>
    </div>
  );
};

const getLowestBuyUsedPrice = (
  products: ProductType[],
  status: string,
  size?: string | number | undefined
) => {
  const filteredProducts = products.filter((data: any) =>
    size
      ? data.product_option_config?.product_conditions?.toLowerCase() ===
          status && data.size_value === size
      : data.product_option_config?.product_conditions?.toLowerCase() === status
  );

  const priceList = filteredProducts
    .map((product) => Number(product.sell_price))
    .sort((a, b) => a - b);
  return priceList[0];
};

const getProductSizeList = (products: ProductType[]) => {
  const sizeList = products.map((product) => product.size_value);
  const unique = [...new Set(sizeList)];
  return unique.sort((a, b) => Number(a) - Number(b));
};

const getProductSizeUnit = (products: ProductType[]) => {
  if (products[0]) {
    return products[0].size_unit;
  }
};

const breadcrumbList: BreadCrumbItem[] = [
  { title: 'Home' },
  { title: 'Product' },
];

const shippingDesctiption = (
  <div>
    <div className="fw-600 fs-1rem headerText">Shipping</div>
    <div className="fs-1rem detailsView mb-2">
      Shipping turn around speeds vary, it usually take 1-7 business days to get
      the customers item(s) packaged and labeled by our shipping department. Our
      goal is to get them to you as quickly and safe as possible. Once an order
      is shipped, the customer will then receive an e-mail with tracking
      information.
    </div>
    <div className="fw-600 fs-1rem headerText">International Shipping</div>
    <div className="fs-1rem detailsView mb-2">
      International customers are responsible for any additional fees or taxes
      after an item ships.
    </div>
    <div className="fw-600 fs-1rem headerText mb-1">
      NO RETURNS OR EXCHANGES.
    </div>
    <div className="fw-600 fs-1rem headerText mb-1">
      All sales with Breakout LA are final. No returns or exchanges.{' '}
    </div>
    <div className="fs-1rem detailsView mb-1">
      Breakout LA is a marketplace which means, items we carry and offer belong
      to independent owners. Once an item sells in-store or online, the owner
      receives payment for their merchandise.
    </div>
    <div className="fs-1rem detailsView mb-1">
      Refunds are <b>ONLY</b> processed based on fulfillment errors on our end,
      such as items being out of stock or incorrectly shipped.
    </div>
    <div className="fs-1rem detailsView mb-1">
      Feel free to contact us regarding questions & sizing before committing to
      purchase.
    </div>
    <div className="fs-1rem detailsView mb-2">- Breakout LA</div>
  </div>
);

function ProductDetailsPage() {
  const { id: productCatalogId } = useParams();
  const [productCatalog, setProductCatalog] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<{
    size?: number | string;
  }>({});
  const [sidebar, setSidebar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCondition, setSelectedCondition] = useState<any>([]);

  const { data: collectionsData } = useQuery('collections', getCollections);
  const products = collectionsData?.data?.[0]?.collection_products || [];

  const onSizeSelect = useCallback((size) => {
    setSelectedProduct((product) => {
      return { ...product, size };
    });
  }, []);

  const onSidebarClose = useCallback(() => {
    document.body.style.overflowY = 'auto';
    setSidebar(false);
  }, []);

  const onSidebarOpen = useCallback((status: string) => {
    document.body.style.overflow = 'hidden';
    setSidebar(true);
    setSelectedCondition(status);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (productCatalogId) {
      getProductDetails(productCatalogId).then((response) => {
        setLoading(false);
        if (response && response.data) {
          setProductCatalog({ ...response.data });
        }
      });
    }
  }, [productCatalogId]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    return () => {
      document.body.style.overflowY = 'auto';
      clearTimeout(timeout);
    };
  }, []);
  const lowestNewPrice = getLowestBuyUsedPrice(
    productCatalog?.user_products || [],
    'new',
    selectedProduct?.size
  );
  const lowestUsedPrice = getLowestBuyUsedPrice(
    productCatalog?.user_products || [],
    'pre',
    selectedProduct?.size
  );
  return (
    <>
      {!!productCatalog?.catalog_name && (
        <DocumentTitle
          title={`Product | ${productCatalog.catalog_name}`}
          metaDescription={productCatalog?.description}
        />
      )}
      <SiteHeader />
      {loading ? (
        <Loader height="700" />
      ) : (
        <ProductContainer>
          <Container>
            <BreadCrumbView
              list={[
                ...breadcrumbList,
                { title: productCatalog?.catalog_name },
              ]}
            />
            {!productCatalog ? (
              <NotFound title="No product found!" />
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <Wrapper>
                  <ProductPictureView>
                    <ProductImageView
                      imageList={
                        getImageUrl(
                          productCatalog?.catalog_images || '[]',
                          'multi'
                        ) as string[]
                      }
                      altName={productCatalog?.catalog_name}
                    />
                  </ProductPictureView>
                  <ProductDetailsView>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 1,
                        delay: 0.5,
                        type: 'spring',
                        stiffness: 100,
                      }}
                    >
                      <div className="brand-name fs-1rem fw-400">
                        <span>{productCatalog?.brand_name}</span>
                      </div>
                      <ProductName className="fs-32 fw-700">
                        {productCatalog?.catalog_name}
                      </ProductName>
                      <SKUName className="fs-1rem fw-400">
                        SKU#: {productCatalog?.sku}
                      </SKUName>

                      <ProductSizeView
                        productSizeList={productCatalog?.available_sizes || []}
                        onSizeSelect={onSizeSelect}
                        size={selectedProduct?.size}
                        sizeUnit={getProductSizeUnit(
                          productCatalog?.user_products || []
                        )}
                      />
                      <BuyButtonPanel className="d-flex mb-2r">
                        {/* TO DO: need to get sell price for new product, 
                currently we are taking from user_products lowest price */}
                        <StyledButton
                          variant="dark"
                          disabled={!lowestNewPrice}
                          onClick={() => lowestNewPrice && onSidebarOpen('new')}
                          className={`flex-1 buy-button`}
                          padding={`1rem 1.25rem`}
                        >
                          <BuyButtonText>Buy Brand New</BuyButtonText>

                          <PriceText
                            className={`fw-600`}
                            size={lowestNewPrice ? '1.25rem' : '1.75rem'}
                            lineHeight={'1.75rem'}
                          >
                            {lowestNewPrice
                              ? `$ ${lowestNewPrice} +`
                              : 'Out of stock'}
                          </PriceText>
                        </StyledButton>

                        <StyledButton
                          variant="dark"
                          disabled={!lowestUsedPrice}
                          onClick={() =>
                            lowestUsedPrice && onSidebarOpen('pre')
                          }
                          className={`flex-1 buy-button`}
                          padding={`1rem 1.25rem`}
                        >
                          <BuyButtonText
                          // color="#4F4F4F"
                          >
                            Buy Pre-owned
                          </BuyButtonText>

                          <PriceText
                            className={`fw-600`}
                            // color="#232F3E"
                            size={lowestUsedPrice ? '1.25rem' : '1.75rem'}
                            lineHeight={'2.5rem'}
                          >
                            {lowestUsedPrice
                              ? `$ ${lowestUsedPrice} +`
                              : 'Out of stock'}
                          </PriceText>
                        </StyledButton>
                      </BuyButtonPanel>
                      <div className="divider mb-2r" />
                      <AccordionView
                        defaultExpand={true}
                        title="About the product"
                        description={productCatalog?.description}
                      />
                      <AccordionView
                        title="Shipping & Returns"
                        description={shippingDesctiption}
                      />
                    </motion.div>
                  </ProductDetailsView>
                  <Sidebar
                    open={Boolean(sidebar)}
                    onClose={onSidebarClose}
                    productCatalog={productCatalog}
                    selectedProduct={selectedProduct}
                    selectedCondition={selectedCondition}
                  />
                </Wrapper>
              </motion.div>
            )}
          </Container>
          <div className="divider" />
          <Container>
            {products?.length > 0 && (
              <Section>
                <h3>You Might Also Like</h3>
                <Carousel
                  infinite={true}
                  responsive={collectionResponsive}
                  arrows={false}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                >
                  {products?.map((item: ProductType) => (
                    <ProductBox
                      key={item.product_catalog_id}
                      productItem={item}
                      containerClassName="m-1rem"
                    />
                  ))}
                </Carousel>
              </Section>
            )}
          </Container>
        </ProductContainer>
      )}
      <SiteFooter />
    </>
  );
}

export default ProductDetailsPage;
