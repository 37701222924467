import { apiClient } from './index';

export const getDefaultProducts = () => {
  return apiClient
    .get(`/admin/products?page=1&limit=100`)
    .then((res) => res.data);
};
export const addDefaultProduct = (data: any) => {
  return apiClient.post('/admin/product/add', data).then((res) => {
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  });
};
export const updateDefaultProduct = (data: any) => {
  return apiClient
    .post(`/admin/product/${data.product_catalog_id}/update`, data)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    });
};

export const getTypes = () => {
  return apiClient
    .post(`/admin/product-types`, {
      action: 'list_type',
    })
    .then((res) => res.data);
};
export const addType = (name: string) => {
  return apiClient
    .post(`/admin/product-types`, {
      action: 'add_type',
      category_name: name,
    })
    .then((res) => res.data);
};

export const deleteProduct = (id: string) => {
  return apiClient
    .delete(`/admin/delete/product/${id}`)
    .then((res) => res.data);
};

export const getDefaultListing = (keyword?: string, filters?: any) => {
  return apiClient
    .post(`/products/default/listings`, {
      page: 1,
      limit: 100,
      keyword: keyword || '',
      ...filters,
    })
    .then((res) => res.data);
};

export const searchProducts = (
  keyword?: string,
  body?: Record<string, unknown>,
  page?: number,
  limit?: number
) => {
  return apiClient
    .post(
      `/product/search?page=${page}&limit=${limit}&keyword=${keyword}`,
      body
    )
    .then((res) => {
      return res.data;
    });
};
export const getProductDetails = (productCatalogId: string) => {
  return apiClient
    .get(`product/${productCatalogId}/details`)
    .then((res) => res.data);
};

export const getProductTypes = () => {
  return apiClient.get(`/product/types`).then((res) => res.data);
};

export const getCollectionProducts = (
  collectionId: string,
  body?: Record<string, unknown>
) => {
  return apiClient
    .post(`/general/collection/${collectionId}`, body)
    .then((res) => res.data);
};

export const getProductConfig = (category_id: number) => {
  return apiClient
    .get(`/general/category/${category_id}/config`)
    .then((res) => res.data);
};
