import styled from 'styled-components';
export type StatusColor =
  | 'green'
  | 'yellow'
  | 'gray'
  | 'red'
  | 'pink'
  | 'blue'
  | 'naviblue'
  | 'violet'
  | 'purple';

type Props = {
  color: StatusColor;
};

export const StatusBadge = styled.span<Props>`
  background-color: ${({ color, theme }) => theme.statusColors[color].bg};
  color: ${({ color, theme }) => theme.statusColors[color].color};
  padding: 6px 12px;
  font-size: 0.875rem;
  border-radius: 6px;
  text-transform: capitalize;
`;
